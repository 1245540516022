import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import uuid from 'uuid/v1';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  Tooltip,
  Link,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardOutlined';
import PaymentIcon from '@material-ui/icons/PaymentOutlined';
import MailIcon from '@material-ui/icons/MailOutlineOutlined';

const useStyles = makeStyles((theme) => ({
  root: {},
  value: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  type: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const Notifications = (props) => {
  const { className, home } = props;
  const statistics = home;
  const classes = useStyles();
  const notifications = [
    {
      id: uuid(),
      value: statistics.current_Offers,
      type: 'invite',
      message: 'current offers',
    },
    {
      id: uuid(),
      value: parseInt(statistics.received_orders, 10) - parseInt(statistics.pickedup_orders, 10),
      type: 'message',
      message: 'pending orders',
    },
  ];

  const icons = {
    invite: <SendIcon />,
    message: <MailIcon />,
    payout: <PaymentIcon />,
  };

  const links = ['offers?filter=active', 'orders/pending'];

  return (
    <Card className={clsx(classes.root, className)}>
      <List>
        {notifications.map((notification, i) => (
          <ListItem divider={i < notifications.length - 1} key={notification.id}>
            <ListItemIcon>{icons[notification.type]}</ListItemIcon>
            <ListItemText>
              <Typography variant="body1">
                <span className={classes.value}>{notification.value}</span> {notification.message}
              </Typography>
            </ListItemText>
            <ListItemSecondaryAction>
              <Link
                color="textPrimary"
                component={RouterLink}
                to={`/operations/${links[i]}`}
                variant="h6">
                <Tooltip title="View">
                  <IconButton edge="end" size="small">
                    <ArrowForwardIcon />
                  </IconButton>
                </Tooltip>
              </Link>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Card>
  );
};

Notifications.propTypes = {
  className: PropTypes.string,
  home: PropTypes.shape().isRequired,
};

Notifications.defaultProps = {
  className: '',
};

export default Notifications;
