import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Popover, CardHeader, Divider, CardContent } from '@material-ui/core';
import { Pause } from './components';
import { onPausedStore, onStatusStore } from 'actions';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbarV2, useSession } from 'hooks';
import { getStoreStatus } from 'selectors/store';

const useStyles = makeStyles(() => ({
  root: {
    width: 460,
    maxWidth: '100%',
  },
}));

const StorePaused = (props) => {
  const { anchorEl, ...rest } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const storeStatus = useSelector(getStoreStatus);
  const { token } = useSession();
  const { showMessage } = useSnackbarV2();
  const onPaused = (data) => {
    dispatch(onPausedStore(data, token))
      .then(() => {
        showMessage('Store was paused', 'success');
      })
      .catch(() => {
        showMessage('Error on paused store');
      });
  };

  useEffect(() => {
    const refetchStatusOrder = () => {
      if (token) {
        dispatch(onStatusStore(token));
      }
    };
    refetchStatusOrder();
    /* setInterval(() => {
      refetchStatusOrder();
    }, 300000); */
  }, [token]);

  return (
    <Popover
      {...rest}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <div className={classes.root}>
        <CardHeader title="Status Store" />
        <Divider />
        <CardContent>
          <Pause
            onPause={onPaused}
            paused={storeStatus && storeStatus.pause}
            status={storeStatus}
          />
        </CardContent>
      </div>
    </Popover>
  );
};

StorePaused.propTypes = {
  anchorEl: PropTypes.any,
  className: PropTypes.string,
  notifications: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default StorePaused;
