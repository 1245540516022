import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  IconButton,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { AddCircle, Delete } from '@material-ui/icons';
import useStyle from './style';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';

const Time = (props) => {
  const classes = useStyle();
  const {
    day,
    labelAdd,
    onAddHour,
    showClosed,
    dayClosed,
    isManualAdd,
    onSelect,
    index,
    onRemove,
    showRemove,
  } = props;
  const [range, setRange] = useState({ from: null, to: null });
  const [closed, setClosed] = useState(dayClosed);
  const [isValid, setIsValid] = useState(true);
  const [isBlur, setIsBlur] = useState(false);
  const handleTimeChange = (event, name) => {
    setIsBlur(true);
    setRange((prev) => ({
      ...prev,
      [name]: event,
    }));
    onSelect && onSelect(event, name, index);
  };

  const onAddDay = () => {
    if (range.from && range.to) {
      onAddHour && onAddHour(range, day);
    }
  };

  const handleClosedChange = (event) => {
    setClosed(event.target.checked);
    if (event.target.checked) {
      onAddHour && onAddHour(null, day);
    }
  };

  useEffect(() => {
    setIsValid(true);
    if (!closed && range.from > range.to) {
      setIsValid(false);
    }
  }, [range]);

  const renderHour = (label) => (
    <KeyboardTimePicker
      KeyboardButtonProps={{
        'aria-label': 'change time',
      }}
      error={isBlur && !isValid}
      id={`${label}-picker`}
      inputValue=""
      inputVariant="outlined"
      label={label}
      minutesStep={5}
      onChange={(event) => {
        handleTimeChange(event, label.toLowerCase());
        if (label === 'From') {
          handleTimeChange(event, 'to');
        }
      }}
      value={range[label.toLowerCase()]}
    />
  );

  const renderAdd = () => {
    if (!isManualAdd) {
      return null;
    }

    if (labelAdd) {
      return (
        <Button
          color={!closed ? 'primary' : 'inherit'}
          disabled={closed || (isBlur && !isValid)}
          onClick={onAddDay}
          variant="outlined"
        >
          {labelAdd}
        </Button>
      );
    }
    return (
      <IconButton
        color={!closed ? 'primary' : 'inherit'}
        disabled={closed || (isBlur && !isValid)}
        onClick={onAddDay}
      >
        <AddCircle />
      </IconButton>
    );
  };
  const renderRemove = () => {
    if (!showRemove) {
      return null;
    }

    return (
      <IconButton
        color={!closed ? 'primary' : 'inherit'}
        disabled={closed || (isBlur && !isValid)}
        onClick={() => onRemove(range, index)}
      >
        <Delete />
      </IconButton>
    );
  };

  return (
    <div className={classes.root}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid
          container
          spacing={3}
        >
          {day && (
            <Grid
              item
              lg={2}
              md={3}
              sm={6}
              xs={12}
            >
              <Typography className={classes.day}>{day}</Typography>
            </Grid>
          )}
          <Grid
            item
            lg={3}
            md={3}
            sm={6}
            xs={12}
          >
            {renderHour('From')}
          </Grid>
          <Grid
            item
            lg={3}
            md={3}
            sm={6}
            xs={12}
          >
            {renderHour('To')}
          </Grid>
          <Grid
            item
            lg={3}
            md={3}
            sm={6}
            xs={12}
          >
            {renderAdd()}
            {showClosed && (
              <FormControlLabel
                control={<Checkbox checked={closed} name="closed" onChange={handleClosedChange} />} // eslint-disable-line
                label="Closed"
              />
            )}
            {renderRemove()}
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </div>
  );
};

Time.propTypes = {
  day: PropTypes.string,
  labelAdd: PropTypes.string,
  onAddHour: PropTypes.func,
  onSelect: PropTypes.func,
  showClosed: PropTypes.bool,
  dayClosed: PropTypes.bool,
  isManualAdd: PropTypes.bool,
  index: PropTypes.number,
  showRemove: PropTypes.bool,
  onRemove: PropTypes.func,
};

Time.defaultProps = {
  day: null,
  labelAdd: null,
  onAddHour: null,
  onSelect: null,
  showClosed: false,
  dayClosed: false,
  isManualAdd: true,
  index: 0,
  showRemove: false,
  onRemove: null,
};

export default Time;
