import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getItemNote } from 'actions';

export default function useNote (itemId, token) {
  const note = useSelector(state => state.items.note);
  const dispatch = useDispatch();

  useEffect(() => {
    let mounted = true;
    const fetchItemNote = () => {
        if (mounted) {
          dispatch(getItemNote(itemId, token));
        }
    };

    fetchItemNote();
    return () => {
      mounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return note;
};