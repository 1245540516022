import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getNotifications } from 'actions';

export default function useNotifications(account, token) {
  const notifications = useSelector((state) => state.store.notifications_from_store);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchNotifications = () => {
      if (token) {
        dispatch(getNotifications(account, token));
      }
    };

    fetchNotifications();
  }, []);

  return notifications;
}
