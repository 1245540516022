import * as types from 'constants/ActionTypes';
import parse from 'utils/parseData';
import fileSaver from 'utils/fileSaver';
import OrdersService from '../services/orders';
import { showSnackbar } from './utils';
import { isSessionDeprecated, getNewToken } from './sessions';

export const cancelOrders = (id, body, session) => async (dispatch) => {
  const isTokenDeprecated = isSessionDeprecated();
  let token = session;
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }
  return OrdersService.cancelOrders(id, body, token);
};

export const getOrders = (id, query) => async (dispatch, getState) => {
  const isTokenDeprecated = isSessionDeprecated();
  const { session } = getState();
  let { token } = session;
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  OrdersService.getOrders(id, query, token).then((response) => {
    const dataParsed = [];
    const { data, limit, offset, total } = response.data;
    if (data.length > 0) {
      data.forEach((item) => {
        let newData = item;
        newData = {
          ...newData,
          audit_creation_date: parse.lessMiliseconds(newData.audit_creation_date),
          audit_update_date: parse.lessMiliseconds(newData.audit_update_date),
        };
        dataParsed.push(newData);
      });
    }
    return dispatch({
      type: types.GET_ORDERS,
      data: {
        limit,
        offset,
        total,
        data: dataParsed,
      },
    });
  });
};

export const getPayments = (session) => async (dispatch) => {
  const isTokenDeprecated = isSessionDeprecated();
  let token = session;

  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  OrdersService.getPayments(token).then((response) => {
    const data = [];
    if (response.data.length > 0) {
      response.data.forEach((item) => {
        let newData = item;
        newData = {
          ...newData,
          audit_creation_date: parse.lessMiliseconds(newData.audit_creation_date),
          audit_update_date: parse.lessMiliseconds(newData.audit_update_date),
        };
        data.push(newData);
      });
    }
    return dispatch({
      type: types.GET_PAYMENTS,
      data,
    });
  });
};

export const getPastOrders = (params, filter) => async (dispatch, getState) => {
  const isTokenDeprecated = isSessionDeprecated();
  const { session } = getState();
  let { token } = session;

  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  OrdersService.getPastOrders(params, filter, token).then((response) => {
    const dataParsed = [];
    const { data, limit, offset, total } = response.data;
    if (data.length > 0) {
      data.forEach((item) => {
        let newData = item;
        newData = {
          ...newData,
          audit_creation_date: parse.lessMiliseconds(newData.audit_creation_date),
          audit_update_date: parse.lessMiliseconds(newData.audit_update_date),
        };
        dataParsed.push(newData);
      });
    }
    return dispatch({
      type: types.GET_PAST_ORDERS,
      data: {
        limit,
        offset,
        total,
        data: dataParsed,
      },
    });
  });
};

export const getOrder = (id, session) => async (dispatch) => {
  const isTokenDeprecated = isSessionDeprecated();
  let token = session;

  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  OrdersService.getOrder(id, token).then((response) =>
    dispatch({
      type: types.GET_ORDER,
      data: response.data,
    })
  );
};

export const getOrderExternal = (itoken) => async (dispatch) => {
  OrdersService.getOrderExternal(itoken)
    .then((response) => {
      dispatch({
        type: types.GET_ORDER,
        data: response.data,
      });
    })
    .catch(() => {
      dispatch({
        type: types.GET_ORDER_ERROR,
      });
    });
};

export const changeStatusOrder = (id, status, session) => async (dispatch) => {
  const isTokenDeprecated = isSessionDeprecated();
  let token = session;

  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  OrdersService.changeStatus(id, status, token)
    .then(() =>
      dispatch({
        type: types.UPDATE_STATUS_ORDERS,
        id,
        status,
      })
    )
    .catch(() => {
      showSnackbar(dispatch, 'Error on change status');
    });
};

export const executeOrder = (id, action, session) => async (dispatch) => {
  const isTokenDeprecated = isSessionDeprecated();
  let token = session;

  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  OrdersService.changeStatusOrder(id, action, token)
    .then(() => {
      showSnackbar(dispatch, 'Order change successfully', 'success');
    })
    .catch(() => {
      showSnackbar(dispatch, 'Error on change status');
    });
};

export const getSummaries = (session) => async (dispatch) => {
  const isTokenDeprecated = isSessionDeprecated();
  let token = session;

  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  OrdersService.getSummaries(token).then((response) => {
    const data = [];
    if (response.data.length > 0) {
      response.data.forEach((item) => {
        let newData = item;
        newData = {
          ...newData,
          audit_creation_date: parse.lessMiliseconds(newData.audit_creation_date),
          audit_update_date: parse.lessMiliseconds(newData.audit_update_date),
        };
        data.push(newData);
      });
    }
    return dispatch({
      type: types.GET_SUMMARIES,
      data,
    });
  });
};

export const getFile = (id, dateName, session) => async (dispatch) => {
  try {
    const isTokenDeprecated = isSessionDeprecated();
    let token = session;
    if (isTokenDeprecated) {
      token = await getNewToken(dispatch);
    }

    OrdersService.getFile(id, token)
      .then((response) => {
        const fileName = `summary_${dateName}.csv`;
        fileSaver(response.data, fileName);
      })
      .catch((err) => {
        console.log('error', err); // eslint-disable-line
      });
  } catch (error) {
    console.log('error', error); // eslint-disable-line
  }
};

export const cleanOrder = () => async (dispatch) =>
  dispatch({
    type: types.GET_ORDER_ERROR,
  });

export const cleanPastOrders = () => async (dispatch) =>
  dispatch({
    type: types.GET_PAST_ORDERS,
    data: null,
  });
