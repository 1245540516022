import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardHeader, CardContent, Divider, Typography } from '@material-ui/core';
import { useManagementCustomers, useSession } from 'hooks';
import { Chart } from './components';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  chartContainer: {
    padding: theme.spacing(3),
  },
  chart: {
    height: 281,
  },
  statsContainer: {
    display: 'flex',
  },
  statsItem: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(3, 2),
    '&:not(:last-of-type)': {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  },
}));

const EarningsSegmentation = (props) => {
  const { className } = props;
  const classes = useStyles();
  const session = useSession();
  const { token } = session;
  const earnings = useManagementCustomers(token);

  return (
    <Card className={clsx(classes.root, className)}>
      <CardHeader title="Earnings Segmentation" />
      <Divider />
      <CardContent className={classes.content}>
        <div className={classes.chartContainer}>
          {earnings.length > 0 && <Chart className={classes.chart} data={earnings} />}
          {earnings.length === 0 && (
            <Typography align="center" component="h6" gutterBottom variant="overline">
              You do not have enough information to show on this chart
            </Typography>
          )}
        </div>
        <Divider />
        <div className={classes.statsContainer}>
          {earnings.length > 0 &&
            earnings.map((earning) => (
              <div className={classes.statsItem} key={`ea${earning.id}`}>
                <Typography align="center" component="h6" gutterBottom variant="overline">
                  {earning.label}
                </Typography>
                <Typography align="center" variant="h4">
                  {earning.value}%
                </Typography>
              </div>
            ))}
        </div>
      </CardContent>
    </Card>
  );
};

EarningsSegmentation.propTypes = {
  className: PropTypes.string,
};
EarningsSegmentation.defaultProps = {
  className: '',
};

export default EarningsSegmentation;
