import BaseService from './baseService';

class OrdersService extends BaseService {
  changeStatus(id, status, token) {
    const headers = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = `/v2/stores/orders/${id}/status/${status}`;
    return this.post(url, {}, headers);
  }

  cancelOrders(id, body, token) {
    const headers = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = `/v2/stores/orders/${id}/cancel`;
    return this.post(url, body, headers);
  }

  getOrders(id, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
      // params here
    };
    const url = `/v2/stores/${id}/orders`;
    return this.get(url, params);
  }

  getPayments(token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
      // params here
    };
    const url = `/v2/stores/orders/payments`;
    return this.get(url, params);
  }

  getSummaries(token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
      // params here
    };
    return this.get(`/v2/stores/orders/summary`, params);
  }

  getFile(id, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
      responseType: 'blob',
    };
    return this.get(`/v2/stores/orders/summary_pdf/${id}`, params);
  }

  getPastOrders(body, query, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
      params: {
        ...query,
      },
      // params here
    };

    const url = `/v2/stores/orders/pagination/filters`;
    return this.post(url, body, params);
  }

  getOrder(id, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
      // params here
    };
    const url = `/v2/stores/orders/${id}`;
    return this.get(url, params);
  }

  getOrderExternal(itoken) {
    const params = {
      t: itoken,
    };
    const url = `/v2/hubspot/app/orders`;
    return this.get(url, { params });
  }

  changeStatusOrder(id, action, token) {
    const headers = {
      headers: {
        'X-Access-Token': token,
      },
    };
    let url = `/v2/stores/tablet/order/${id}/${action}`;
    if (action === 'inthekitchen') {
      url = `/v2/stores/tablet/order/${id}/${action}/-1`;
    }
    if (action === 'delivery') {
      url = `/v2/stores/tablet/order/${id}/delivered`;
    }
    return this.post(url, {}, headers);
  }

  getPlansSaves(query, tab) {
    const params = {
      params: {
        ...query,
      },
    };
    const url = `/v2/stores/orders/ps/${tab}`;
    return this.get(url, params);
  }

  postPlanDelivered(orderId, token) {
    const headers = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = `/v2/stores/tablet/order/${orderId}/delivered`;
    return this.post(url, {}, headers);
  }
  getDownloadPSToday(date, token) {
    const headers = {
      headers: {
        'X-Access-Token': token,
      },
      responseType: 'blob',
    };
    const url = `/v2/stores/orders/summary_ps/${date}`;
    return this.get(url, headers);
  }
}

export default new OrdersService();
