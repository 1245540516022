/* eslint class-methods-use-this: "off" */

import restClient from './restClient';

class BaseService {
  request(url, method, options, headers = {}) {
    /* if (process.env.NODE_ENV === 'development') {
      console.info('Service Request to API: ', {
        url: `${url}`,
        method,
        options: JSON.stringify(options),
      });
    } */
    return restClient[method](`${url}`, options, headers);
  }

  get(url, options = {}, headers) {
    return this.request(url, 'get', options, headers);
  }

  post(url, options, headers) {
    return this.request(url, 'post', options, headers);
  }

  postLogin(url, options) {
    return this.requestLogin(url, 'post', options);
  }

  put(url, options, headers) {
    return this.request(url, 'put', options, headers);
  }

  del(url, headers) {
    return this.request(url, 'delete', headers);
  }
}
export default BaseService;
