import * as types from 'constants/ActionTypes';

const INITIAL_STATE = {
  management_info: null,
  management_sales: null,
  management_customers: [],
  ratings: null,
};
const stadistic = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SESSION_LOGOUT: {
      return {
        ...state,
        ratings: null
      };
    }
    case types.GET_MANAGEMENT_INFO: {
      const { data } = action;
      return {
        ...state,
        management_info: data
      };
    }
    case types.GET_MANAGEMENT_SALES: {
      const { data } = action;
      return {
        ...state,
        management_sales: data
      };
    }
    case types.GET_MANAGEMENT_CUSTOMERS: {
      const { data } = action;
      return {
        ...state,
        management_customers: data.earnings
      };
    }

    case types.GET_RATINGS: {
      const { data } = action;
      return {
        ...state,
        ratings: data,
      };
    }

    default: {
      return state;
    }
  }
}

export default stadistic;