import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getTemplate, removeTemplate } from 'actions';
export default function useTemplate (id, token) {
  id = typeof parseInt(id) === "number" ? id : null;
  const template = useSelector(state => state.templates.template);
  const dispatch = useDispatch();
  useEffect(() => {
    let mounted = true;
    const fetchTemplate = () => {
        if (mounted && !template && id && token) {
          dispatch(getTemplate(id, token));
        }
    };

    fetchTemplate();
    return () => {
      mounted = false;
      dispatch(removeTemplate());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return template;
};