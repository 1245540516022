import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Paper, Input } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import useStyles from './style';

const SimpleSearch = (props) => {
  const { onSearch, className, placeholder } = props;

  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <Paper
        className={classes.search}
        elevation={1}
      >
        <SearchIcon className={classes.searchIcon} />
        <Input
          className={classes.searchInput}
          disableUnderline
          onChange={onSearch}
          placeholder={placeholder}
        />
      </Paper>
    </div>
  );
};

SimpleSearch.propTypes = {
  className: PropTypes.string,
  onSearch: PropTypes.func,
  placeholder: PropTypes.string,
};

SimpleSearch.defaultProps = {
  className: '',
  placeholder: '',
};

export default SimpleSearch;
