import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { TableRow, TableCell, Typography } from '@material-ui/core';
import { printStatus } from 'utils/tools';
import { PopoverCanceled } from '.';

const InternalInfo = (props) => {
  const { order } = props;

  return (
    <>
      <TableRow>
        <TableCell>Consumer</TableCell>
        <TableCell>
          <div>{order.consumer.name}</div>
          <div>{order.consumer.phone}</div>
        </TableCell>
      </TableRow>
      <TableRow selected>
        <TableCell>Fulfillment</TableCell>
        <TableCell>
          <div>{order.fulfillment}</div>
          {order.fulfillment === 'DELIVERY' && order.driverInfo && (
            <Typography variant="body2">
              Driver: {order.driverInfo.name} - {order.driverInfo.phone}
            </Typography>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Date</TableCell>
        <TableCell>{moment(order.date).format('YYYY/MM/DD HH:mm')}</TableCell>
      </TableRow>
      <TableRow selected>
        <TableCell>Order special instructions</TableCell>
        <TableCell>{order.options.additional_instructions}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Total Amount</TableCell>
        <TableCell>${order.total_price_bt}</TableCell>
      </TableRow>
      <TableRow selected>
        <TableCell>Tip</TableCell>
        <TableCell>{order.tip}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Status</TableCell>
        <TableCell>
          {printStatus(order.status)}
          {order.cancellationInfo && (order.status === 7 || order.status === 6) && (
            <PopoverCanceled orderId={order.id} orderCancelationInfo={order.cancellationInfo} />
          )}
          {order.status === 10 &&
            order.scheduled_date &&
            ` FOR ${moment(order.scheduled_date).format('YYYY/MM/DD | HH:mm')}`}
        </TableCell>
      </TableRow>
    </>
  );
};

InternalInfo.propTypes = {
  order: PropTypes.shape().isRequired,
};

export default InternalInfo;
