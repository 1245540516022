import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import { Page } from 'components';
import { useManagementInfo, useSession } from 'hooks';
import {
  Header,
  NewProjects,
  RoiPerCustomer,
  TodaysMoney,
  SystemHealth,
  FinancialStats,
  EarningsSegmentation,
} from './components';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  container: {
    marginTop: theme.spacing(3),
  },
}));

const DashboardDefault = () => {
  const classes = useStyles();

  const session = useSession();
  const { token } = session;
  const managementInfo = useManagementInfo(token);

  return (
    <Page className={classes.root} title="Default Dashboard">
      <Header />
      <Grid className={classes.container} container spacing={3}>
        <Grid item lg={3} sm={6} xs={12}>
          {managementInfo && <TodaysMoney data={managementInfo} />}
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          {managementInfo && <NewProjects data={managementInfo} />}
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          {managementInfo && <SystemHealth data={managementInfo} />}
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          {managementInfo && <RoiPerCustomer data={managementInfo} />}
        </Grid>
        <Grid item lg={8} xl={9} xs={12}>
          <FinancialStats />
        </Grid>
        <Grid item lg={4} xl={3} xs={12}>
          <EarningsSegmentation />
        </Grid>
      </Grid>
    </Page>
  );
};

export default DashboardDefault;
