import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { OverallReviews, ReviewCard } from './components';
import { useSelector, useDispatch } from 'react-redux';
import { getOrder, cleanOrder } from 'actions';
import Detail from '../../../OrderManagementDetails/Detail';

const useStyles = makeStyles(theme => ({
  root: {},
  review: {
    marginTop: theme.spacing(2)
  },
  latesreview: {
    paddingTop: '20px'
  },
  seeall: {
    marginTop: 10,
  },
}));

const Reviews = props => {
  const { className, rating, rating_qty, ...rest } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const reviews = props.reviews.filter((review, i) => i < 3);
  const classes = useStyles();
  const order = useSelector(state => state.orders.order);
  const session = useSelector(state => state.session);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [mounted, setMounted] = useState(false);

  const getOrderModal = id => {
    dispatch(cleanOrder())
    setMounted(true);
    if (id) {
      setShowModal(true)
      setOrderId(id)
    }
  }

  useEffect(() => {
    const fetchOrder = () => {
        if (mounted && orderId) {
          setMounted(false);
          dispatch(getOrder(orderId, session.token));
        }
    };

    fetchOrder();

    return () => {
      setMounted(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const handleClose = () => {
    setShowModal(false)
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <OverallReviews rating={rating} rating_qty={rating_qty} ratings={reviews.map(review => review.rating)} />
      <div className={classes.latesreview}>
        <Typography
          variant="h5"
        >
          Last Reviews
        </Typography>
      </div>
      {reviews.map((review, i) => (
        <ReviewCard
          className={classes.review}
          key={`rc${i}`}
          review={review}
          onClickShow={getOrderModal}
        />
      ))}
      <Button
        component={RouterLink}
        to="/management/customers"
        className={classes.seeall}
      >
        See all
        <KeyboardArrowRightIcon className={classes.arrowIcon} />
      </Button>
      <Dialog
        open={showModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle id="alert-dialog-title">{"Order Detail"}</DialogTitle>
        <DialogContent>
          {!order && 'Loading..!'}
          {order && <Detail order={order} showTitle={false} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

Reviews.propTypes = {
  className: PropTypes.string
};

export default Reviews;
