import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getLovvettOffer } from 'actions';

export default function useLovvettOffer(itemId, token) {
  const lovvettOffer = useSelector((state) => state.items.lovvettOffer);
  const [refetchIndex, setRefetchIndex] = useState(0);
  const dispatch = useDispatch();

  const refetchOffer = () => setRefetchIndex((prev) => prev + 1);

  useEffect(() => {
    if (token && itemId) {
      dispatch(getLovvettOffer(itemId, token));
    }
  }, [refetchIndex]);

  return { lovvettOffer, refetchOffer };
}
