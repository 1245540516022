
import BaseService from './baseService';

class TemplatesService extends BaseService {
  save(data, token) {
    const headers = {
      headers: {
        'X-Access-Token': token
      }
    }
    const url = `/v2/stores/templates`;
    return this.put(url, data, headers);
  }

  edit(data, token) {
    const headers = {
      headers: {
        'X-Access-Token': token
      }
    }
    const url = `/v2/stores/templates`;
    return this.post(url, data, headers);
  }

  deleteTemplate(id, token) {
    const headers = {
      headers: {
        'X-Access-Token': token
      }
    }
    const url = `/v2/stores/templates/${id}`;
    return this.del(url, headers);
  }

  changeStatus(id, status, token) {
    const headers = {
      headers: {
        'X-Access-Token': token
      }
    }
    const url = `/v2/stores/templates/${id}/status/${status}`;
    return this.post(url, {}, headers);
  }

  getTemplates2(storeId, token) {
    const params = {
      headers: {
        'X-Access-Token': token
      }
      //params here
    }
    const url = `/v2/stores/templates/activeTemplates/${storeId}`;
    return this.get(url, params);
  }
  getTemplates(storeId, filter, query, token) {
    const params = {
      headers: {
        'X-Access-Token': token
      },
      params: {
        ...query
      }
      //params here
    }
    const url = `/v2/stores/templates/activeTemplates/pagination/filters`;
    return this.post(url, filter, params);
  }
  getTemplate(id, token) {
    const params = {
      headers: {
        'X-Access-Token': token
      }
    }
    const url = `/v2/stores/templates/${id}`;
    return this.get(url, params);
  }

  postMedia(id, data, config, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
        'Content-Type': 'multipart/form-data'
      },
      ...config
    }

    const url = `/v2/stores/template/${id}/media`;
    return this.post(url, data, params);
  }
}

export default new TemplatesService();
