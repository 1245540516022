/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import useRouter from 'utils/useRouter';

const NODE_ENV = process.env.NODE_ENV;
const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;

const Page = props => {
  const { title, children, image, fullImage, isIframe, ...rest } = props;

  const router = useRouter();

  useEffect(() => {
    if (NODE_ENV !== 'production') {
      return;
    }

    if (window.gtag) {
      window.gtag('config', GA_MEASUREMENT_ID, {
        page_path: router.location.pathname,
        page_name: title
      });
    }
  }, [title, router]);
  let styles = null;

  if (image !== '') {
    styles = {
      backgroundImage: `url(${image})`,
      backgroundColor: '#3C959E',
    }
  }
  if (fullImage) {
    styles = {
      ...styles,
      backgroundPosition: 'center center',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    }
  } else {
    styles = {
      ...styles,
      backgroundRepeat: 'no-repeat'
    }
  }

  if (isIframe) {
    styles = {
      ...styles,
      padding: 0,
      width: '100%',
      height: '100%',
    }
  }

  return (
    <div style={styles} {...rest}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  );
};

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  isIframe: PropTypes.bool,
};
Page.defaultProps = {
  image: '',
  fullImage: true,
  isIframe: false,
}

export default Page;
