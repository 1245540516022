import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, Typography, IconButton } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

import CloseIcon from '@material-ui/icons/Close';
import Portal from '../Portal';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const PortalModal = (props) => {
  const { isOpen, onClose, title, closable, children, ...rest } = props;
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, closable, ...other } = props;
    return (
      <MuiDialogTitle
        className={classes.root}
        disableTypography
        {...other}
      >
        <Typography variant="h4">{children}</Typography>
        {onClose && closable ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  if (!isOpen) {
    return null;
  }

  return (
    <Portal>
      <Dialog
        open={isOpen}
        {...rest}
        aria-labelledby="form-dialog-title"
        onClose={onClose}
      >
        <DialogTitle
          closable={closable}
          id="form-dialog-title"
          onClose={onClose}
        >
          {title}
        </DialogTitle>
        <DialogContent dividers>{children}</DialogContent>
      </Dialog>
    </Portal>
  );
};

PortalModal.propTypes = {
  closable: PropTypes.bool,
};

PortalModal.defaultProps = {
  closable: true,
};

export default PortalModal;
