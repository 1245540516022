import { useState, useEffect } from 'react';

export default function useSnackbarPortal() {
    const [isActive, setIsActive] = useState(false);
    const [message, setMessage] = useState();
    const [severity, setSeverity] = useState('error');

    useEffect(() => {
        if (isActive === true) {
            setTimeout(() => {
                setIsActive(false);
            }, 4000);
        }
    }, [isActive]);

    const openSnackBar = (msg = 'Something went wrong...', type = 'error') => {
        setMessage(msg)
        setSeverity(type);
        setIsActive(true);
    }

    return { isActive, message, severity, openSnackBar }
}