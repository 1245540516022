import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import useStyles from './style';

const OverallReviews = props => {
  const { ratings, rating, rating_qty, className, ...rest } = props;
  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <Grid
          alignItems="center"
          container
          spacing={3}
        >
          <Grid item>
            <Typography variant="h5">Overall ranking</Typography>
          </Grid>

          <Grid
            className={classes.stars}
            item
          >
            <Typography
              className={classes.rating}
              variant="h6"
            >
              {rating}
            </Typography>
            <StarIcon
                className={clsx(classes.starIcon, classes.starFilledIcon)}
              />
          </Grid>
          <Grid item>
            <Typography
              className={classes.total}
              color="textSecondary"
              variant="body2"
            >
              {rating_qty} reviews in total
            </Typography>
          </Grid>
          <Grid item className={classes.wording}>
            <Typography
              className={classes.total}
              color="textSecondary"
              variant="body2"
            >
              We calculate our partners’ ratings weekly based on their feedback, reviews, and reported issues (if any) during the last month.
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

OverallReviews.propTypes = {
  className: PropTypes.string,
  ratings: PropTypes.array.isRequired
};

export default OverallReviews;
