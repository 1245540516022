import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import useStyles from './style';

const Message = (props) => {
  const { time, value } = props;
  const classes = useStyles();

  return (
    <Grid item xs={6}>
      {value !== 'indefinitely' ? (
        <Typography className={classes.until} display="block" gutterBottom variant="button">
          Until {moment(time).format('dddd MMM HH:mm A')}
        </Typography>
      ) : (
        <Typography className={classes.until} display="block" gutterBottom variant="button">
          Indefinitely
        </Typography>
      )}
      <ul>
        <li>
          <Typography gutterBottom variant="body2">
            An email will be sent to the store manager notifying them that the store is
            desactivated.
          </Typography>
        </li>
        <li>
          <Typography gutterBottom variant="body2">
            All existing orders must be fulfilled.
          </Typography>
        </li>
      </ul>
    </Grid>
  );
};

Message.propTypes = {
  time: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default Message;
