import StadisticsService from '../services/stadistics';
import * as types from 'constants/ActionTypes';
import { showSnackbar } from './utils';
import {
  isSessionDeprecated,
  getNewToken,
} from './sessions';

export const getManagement = (token) => async dispatch => {
  const isTokenDeprecated = isSessionDeprecated();

  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }
  const management = await StadisticsService.getManagementInfo(token);
  if (management.status === 200) {
    dispatch({
      type: types.GET_MANAGEMENT_INFO,
      data: management.data
    })
  } else {
    showSnackbar(dispatch, "Error on get management info");
  }
}

export const getManagementSales = (token) => async dispatch => {
  const isTokenDeprecated = isSessionDeprecated();

  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }
  const managementSales = await StadisticsService.getManagementSales(token);

  if (managementSales.status === 200) {
    dispatch({
      type: types.GET_MANAGEMENT_SALES,
      data: managementSales.data
    })
  } else {
    showSnackbar(dispatch, "Error on get management sales");
  }
}

export const getManagementCustomers = (token) => async dispatch => {
  const isTokenDeprecated = isSessionDeprecated();

  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }
  const managementCustomers = await StadisticsService.getManagementCustomers(token);

  if (managementCustomers.status === 200) {
    dispatch({
      type: types.GET_MANAGEMENT_CUSTOMERS,
      data: managementCustomers.data
    })
  } else {
    showSnackbar(dispatch, "Error on get management Customers");
  }
}

export const getRatings = (query, token) => async dispatch => {
  const isTokenDeprecated = isSessionDeprecated();

  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }
  const response = await StadisticsService.getRatings(query, token);

  if (response.status === 200) {
    const { data, limit, offset, total } = response.data;
    dispatch({
      type: types.GET_RATINGS,
      data: {
        offset,
        limit,
        total,
        data,
      }
    })
  } else {
    showSnackbar(dispatch, "Error on get ratings");
  }
}