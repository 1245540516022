import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getOrder } from 'actions';

export default function useOrder(orderId, token) {
  const order = useSelector((state) => state.orders.order);
  const [refetchIndex, setRefetchIndex] = useState(0);
  const dispatch = useDispatch();

  const refetchOrder = () => setRefetchIndex((prev) => prev + 1);

  useEffect(() => {
    if (token && orderId) {
      dispatch(getOrder(orderId, token));
    }
  }, [refetchIndex]);

  return { order, refetchOrder };
}
