const FIELDS_DATE = ['order_date_from','order_date_to','pickup_date_from','pickup_date_to','audit_creation_date','audit_update_date'];

const getHumanDays = (days, repeat_days) => {
  let humanDays = 'Non-Recurring';
  const selectedDays = days.filter(item => repeat_days.includes(item.value));
  if (selectedDays.length > 0) {
    humanDays = selectedDays.map((v) => {
      return v.label.slice(0, 3);
    });
    humanDays = humanDays.join(", ");
  }
  return humanDays;
}

const addMilisecond = (date) => {
  return date * 1000;
}

const lessMiliseconds = (date) => {
  return date / 1000;
}

const addMilisecondsFields = (data) => {
  if (Object.keys(data).length > 0) {
    Object.keys(data).forEach(key => {
      if (FIELDS_DATE.includes(key)) {
        data[key] = data[key] * 1000;
      }
    });
  }
  return data;
}

const lessMilisecondsFields = (data) => {
  if (Object.keys(data).length > 0) {
    Object.keys(data).forEach(key => {
      if (FIELDS_DATE.includes(key)) {
        data[key] = data[key] / 1000;
      }
    });
  }
  return data;
}

export default {
  getHumanDays,
  addMilisecond,
  lessMiliseconds,
  addMilisecondsFields,
  lessMilisecondsFields
};
