import * as types from 'constants/ActionTypes';

const INITIAL_STATE = {
  modifiers: null,
  modifiersItem: null,
  modifierSelected: null,
  modifier: null,
};
const modifiers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SESSION_LOGOUT: {
      return {
        ...INITIAL_STATE,
      };
    }
    case types.GET_MODIFIERS_ITEM: {
      const { data } = action;
      return {
        ...state,
        modifiersItem: data,
      };
    }

    case types.SELECT_MODIFIER: {
      const { data } = action;
      return {
        ...state,
        modifierSelected: data,
      };
    }

    case types.GET_MODIFIERS: {
      const { data } = action;
      return {
        ...state,
        modifiers: data,
      };
    }

    case types.GET_MODIFIER: {
      const { data } = action;
      return {
        ...state,
        modifier: data,
      };
    }

    case types.DELETE_MODIFIER_ITEM: {
      const { modifierId } = action;

      return {
        ...state,
        modifiersItem: state.modifiersItem.filter((item) => item.id !== modifierId),
      };
    }

    case types.MODIFIERS.SET_ORDER_MODIFIER: {
      const { payload } = action;
      const newModifiers = {
        ...state.modifiers,
        data: payload,
      };

      return {
        ...state,
        modifiers: newModifiers,
      };
    }

    default: {
      return state;
    }
  }
};

export default modifiers;
