import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '10px 0',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  day: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
}));

export default useStyles;
