export const VERIFY_ENABLED_CITY = 'VERIFY_ENABLED_CITY';
export const POST_REGISTER = 'POST_REGISTER';
export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const NOTIFY_CITY = 'NOTIFY_CITY';
export const GET_RESTO_TYPES = 'GET_RESTO_TYPES';
export const SESSION_SET_TOKEN = 'APP/SESSION_SET_TOKEN';
export const SESSION_LOGIN = 'SESSION_LOGIN';
export const SESSION_LOGOUT = 'SESSION_LOGOUT';
export const ERROR_LOGIN = 'ERROR_LOGIN';
export const GET_OFFERS = 'APP/GET_OFFERS';
export const DELETE_OFFER = 'APP/DELETE_OFFER';
export const SET_ERROR = 'APP/SET_ERROR';
export const SET_ERROR_CLEAN = 'APP/SET_ERROR_CLEAN';
export const UPDATE_STATUS_OFFER = 'APP/UPDATE_STATUS_OFFER';
export const GET_TEMPLATES = 'APP/GET_TEMPLATES';
export const DELETE_TEMPLATE = 'APP/DELETE_TEMPLATE';
export const UPDATE_STATUS_TEMPLATE = 'APP/UPDATE_STATUS_TEMPLATE';
export const GET_ORDERS = 'APP/GET_ORDERS';
export const GET_ORDER = 'APP/GET_ORDER';
export const UPDATE_STATUS_ORDERS = 'APP/UPDATE_STATUS_ORDERS';
export const DELETE_ORDERS = 'APP/DELETE_ORDERS';
export const GET_STORE = 'APP/GET_STORE';
export const UPDATE_STORE = 'APP/UPDATE_STORE';
export const GET_HOME = 'APP/GET_HOME';
export const UPDATE_LOGO = 'APP/UPDATE_LOGO';
export const SET_SNACKBAR = 'APP/SET_SNACKBAR';
export const HIDE_SNACKBAR = 'APP/HIDE_SNACKBAR';
export const POST_BILLING = 'APP/POST_BILLING';
export const GET_BILLING = 'APP/GET_BILLING';
export const POST_INTEGRATIONS_INFO = 'STORE/POST_INTEGRATIONS_INFO';
export const GET_INTEGRATIONS_INFO = 'STORE/GET_INTEGRATIONS_INFO';
export const GET_OPERATIONAL_INFO = 'APP/GET_OPERATIONAL_INFO';
export const POST_OPERATIONAL_INFO = 'APP/POST_OPERATIONAL_INFO';
export const GET_TEMPLATE = 'APP/GET_TEMPLATE';
export const CLEAN_TEMPLATE = 'APP/CLEAN_TEMPLATE';
export const SET_MEDIA_TEMPLATE = 'APP/SET_MEDIA_TEMPLATE';
export const SET_MEDIA_OFFER = 'APP/SET_MEDIA_OFFER';
export const GET_OFFER = 'APP/GET_OFFER';
export const CLEAN_OFFER = 'APP/CLEAN_OFFER';
export const GET_COMPANY = 'APP/GET_COMPANY';
export const CLEAN_COMPANY = 'APP/CLEAN_COMPANY';
export const POST_ADMIN = 'APP/POST_ADMIN';
export const ERROR_GET_COMPANY = 'APP/ERROR_GET_COMPANY';
export const GET_PAST_ORDERS = 'APP/GET_PAST_ORDERS';
export const GET_PAYMENTS = 'APP/GET_PAYMENTS';
export const GET_SUMMARIES = 'APP/GET_SUMMARIES';
export const GET_MANAGEMENT_INFO = 'APP/GET_MANAGEMENT_INFO';
export const GET_MANAGEMENT_SALES = 'APP/GET_MANAGEMENT_SALES';
export const GET_MANAGEMENT_CUSTOMERS = 'APP/GET_MANAGEMENT_CUSTOMERS';
export const GET_RATINGS = 'APP/GET_RATINGS';
export const SET_AS_TEMPLATE_ID = 'APP/SET_AS_TEMPLATE_ID';
export const SET_TAB_TEMPLATE = 'APP/SET_TAB_TEMPLATE';
export const GET_ORDER_ERROR = 'APP/GET_ORDER_ERROR';
export const CLEAN_HOME = 'APP/CLEAN_HOME';
export const IS_OPENING_HOME = 'APP/IS_OPENING_HOME';
export const GET_NOTIFICATIONS = 'APP/GET_NOTIFICATIONS';
export const ADD_NOTIFICATION = 'APP/ADD_NOTIFICATION';
export const UPDATE_TERMS = 'APP/UPDATE_TERMS';
export const SHOW_LOADING = 'APP/SHOW_LOADING';
export const HIDE_LOADING = 'APP/HIDE_LOADING';
export const CHANGE_QTY = 'APP/CHANGE_QTY';
export const SET_OFFER_ID_TEMP = 'APP/SET_OFFER_ID_TEMP';
export const GET_NOTIFICATIONS_EDIT = 'APP/GET_NOTIFICATIONS_EDIT';
export const POST_NOTIFICATION = 'APP/POST_NOTIFICATION';
export const MESSAGE_OK = 'Successfully saved';

// V2
export const GET_MENUS = 'APP/GET_MENUS';
export const GET_MENU = 'APP/GET_MENU';
export const GET_MENU_CATEGORIES = 'APP/GET_MENU_CATEGORIES';
export const UPDATE_CATEGORIES = 'APP/UPDATE_CATEGORIES';
export const SELECT_CATEGORY = 'APP/SELECT_CATEGORY';
export const GET_MENU_CATEGORY_ITEMS = 'APP/GET_MENU_CATEGORY_ITEMS';
export const GET_MENU_CATEGORY_ITEM = 'APP/GET_MENU_CATEGORY_ITEM';
export const UPDATE_ITEMS = 'APP/UPDATE_ITEMS';
export const GET_MODIFIERS = 'APP/GET_MODIFIERS';
export const GET_MODIFIER = 'APP/GET_MODIFIER';
export const GET_MODIFIERS_ITEM = 'APP/GET_MODIFIERS_ITEM';
export const SELECT_MODIFIER = 'APP/SELECT_MODIFIER';
export const SELECT_ITEM = 'APP/SELECT_ITEM';
export const GET_ITEM_PRICES = 'APP/GET_ITEM_PRICES';
export const GET_ITEM_FULLFILLMENT = 'APP/GET_ITEM_FULLFILLMENT';
export const GET_ITEM_FULLFILLMENT_ERROR = 'APP/GET_ITEM_FULLFILLMENT_ERROR';
export const GET_ITEM_LOVVETT = 'APP/GET_ITEM_LOVVETT';
export const GET_ITEM_LOVVETT_ERROR = 'APP/GET_ITEM_LOVVETT_ERROR';
export const GET_ITEM_NOTE = 'APP/GET_ITEM_NOTE';
export const GET_ITEM_NOTE_ERROR = 'APP/GET_ITEM_NOTE_ERROR';
export const DELETE_ITEM_MEDIA = 'APP/DELETE_ITEM_MEDIA';
export const DELETE_MODIFIER_ITEM = 'APP/DELETE_MODIFIER_ITEM';
export const DELETE_ITEM = 'APP/DELETE_ITEM';
export const ARCHIVE_MENU = 'APP/ARCHIVE_MENU';
export const GET_TIMETABLE = 'APP/STORE/GET_TIMETABLE';
export const POST_ONLINE_ORDERING = 'STORE/POST_ONLINE_ORDERING';
export const GET_ONLINE_ORDERING = 'APP/STORE/GET_ONLINE_ORDERING';
export const SET_STATUS_STORE = 'STORE/SET_STATUS_STORE';
export const GET_MENU_TIMETABLE = 'MENU/GET_MENU_TIMETABLE';
export const GET_ALL_ITEMS = 'ITEM/GET_ALL_ITEMS';

export const MENU = {
  SET_ORDER_CATEGORY: 'CATEGORY/SET_ORDER_CATEGORY',
};

export const MODIFIERS = {
  SET_ORDER_MODIFIER: 'MODIFIER/SET_ORDER_MODIFIER',
};

export const OFFER = {
  GET_LOVVETT_OFFER: 'CATEGORY_ITEMS/GET_LOVVETT_OFFER',
};

export const PROMOTION = {
  GET_PROMOTIONS: 'PROMOTIONS/GET_PROMOTIONS',
};

export const APP = {
  GET_PLAN_SAVE: 'APP/GET_PLAN_SAVE',
  GET_PLANS_SAVES: 'APP/GET_PLANS_SAVES',
  UPDATE_PLANS_SAVES: 'APP/UPDATE_PLANS_SAVES',
};
