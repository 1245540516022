import BaseService from './baseService';

class OfferService extends BaseService {
  save(data, token, params = {}) {
    const headers = {
      headers: {
        'X-Access-Token': token,
      },
      params: {
        ...params,
      },
    };
    const url = `/v2/stores/offers`;
    return this.put(url, data, headers);
  }

  edit(data, token) {
    const headers = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = `/v2/stores/offers`;
    return this.post(url, data, headers);
  }

  deleteOffer(offerId, token) {
    const headers = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = `/v2/stores/offers/${offerId}`;
    return this.del(url, headers);
  }

  changeStatus(offerId, status, token) {
    const headers = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = `/v2/stores/offers/${offerId}/status/${status}`;
    return this.post(url, {}, headers);
  }

  changeQty(offerId, qty, token) {
    const headers = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = `/v2/stores/offers/update_quantity/${offerId}/${qty}`;
    return this.post(url, {}, headers);
  }

  getOffers(storeId, token, query) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
      params: {
        ...query,
      },
    };
    // this.setToken(token);
    const url = `/v2/stores/offers/CurrentOffersByStore`;
    return this.get(url, params);
  }

  getOffer(id, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
      // ams herepar
    };
    const url = `/v2/stores/offers/${id}`;
    return this.get(url, params);
  }

  getLovvettOffer(id, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
      // ams herepar
    };
    const url = `/v2/menueditor/menu/items/${id}/lovvett`;
    return this.get(url, params);
  }

  postMedia(data, config, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
        'Content-Type': 'multipart/form-data',
      },
      ...config,
    };

    const url = `/v2/stores/offers/media`;
    return this.post(url, data, params);
  }
}

export default new OfferService();
