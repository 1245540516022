import SiteService from '../services/site';
import * as types from 'constants/ActionTypes';

export const getSelectInfo = () => (dispatch) => {
  SiteService.getRestoTypes()
    .then((response) => {
      let result = {};
      const data = response.data;
      Object.keys(data).forEach((item) => {
        result[item] = [];
        data[item].forEach((type) => {
          result[item].push({
            value: type.code,
            label: type.descr,
          });
        });
      });
      return dispatch({
        type: types.GET_RESTO_TYPES,
        data: result,
      });
    })
    .catch(() => {});
};
export const hideSiteError = () => (dispatch) => {
  return dispatch({
    type: types.SET_ERROR_CLEAN,
  });
};

export const showSnackbar =
  (msg, typesError = 'error') =>
  (dispatch) => {
    return dispatch({
      type: types.SET_SNACKBAR,
      message: msg,
      typesError,
    });
  };

export const hideSnackbar = () => (dispatch) => {
  return dispatch({
    type: types.HIDE_SNACKBAR,
  });
};

export const showLoading = () => (dispatch) => {
  return dispatch({
    type: types.SHOW_LOADING,
  });
};

export const hideLoading = () => (dispatch) => {
  return dispatch({
    type: types.HIDE_LOADING,
  });
};
