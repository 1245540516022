import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Card,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
  CardActions,
  Typography,
  LinearProgress,
} from '@material-ui/core';
import useStyles from './style';

const ListChecked = (props) => {
  const { items, onSelectItems } = props;
  const classes = useStyles();
  const [selectedItems, setSelectedItems] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [page, setPage] = useState(0);

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedItems.indexOf(id);
    let newSelectedItems = [];

    if (selectedIndex === -1) {
      newSelectedItems = newSelectedItems.concat(selectedItems, id);
    } else if (selectedIndex === 0) {
      newSelectedItems = newSelectedItems.concat(selectedItems.slice(1));
    } else if (selectedIndex === selectedItems.length - 1) {
      newSelectedItems = newSelectedItems.concat(selectedItems.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedItems = newSelectedItems.concat(
        selectedItems.slice(0, selectedIndex),
        selectedItems.slice(selectedIndex + 1)
      );
    }

    setSelectedItems(newSelectedItems);
  };

  useEffect(() => {
    onSelectItems && onSelectItems(selectedItems);
  }, [selectedItems]);

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  };

  const range = page * rowsPerPage;

  if (!items) {
    return <LinearProgress />;
  }

  return (
    <div>
      <Typography
        color="textSecondary"
        gutterBottom
        variant="body2"
      >
        {items.length} Records found. Page {page + 1} of {Math.ceil(items.length / rowsPerPage)}
      </Typography>
      <Card>
        <CardContent className={classes.content}>
          <PerfectScrollbar className={classes.scroll}>
            <div className={classes.inner}>
              <Table>
                <TableBody>
                  {items &&
                    items.length > 0 &&
                    items.slice(range, range + rowsPerPage).map((item) => (
                      <TableRow
                        hover
                        key={`${item.name}${item.id}`}
                        selected={selectedItems.indexOf(item.id) !== -1}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedItems.indexOf(item.id) !== -1}
                            color="primary"
                            onChange={(event) => handleSelectOne(event, item.id)}
                            value={selectedItems.indexOf(item.id) !== -1}
                          />
                        </TableCell>
                        <TableCell>
                          <div className={classes.nameCell}>{item.name}</div>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions>
          <TablePagination
            component="div"
            count={items.length}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[]}
          />
        </CardActions>
      </Card>
    </div>
  );
};

ListChecked.propTypes = {
  items: PropTypes.arrayOf(),
  onSelectItems: PropTypes.func.isRequired,
};

ListChecked.defaultProps = {
  items: null,
};

export default ListChecked;
