import React, { useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Drawer,
  Hidden,
  Divider,
  Paper,
  Avatar,
  Typography,
  Button,
  Container,
} from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import useRouter from 'utils/useRouter';
import { Navigation, SettingPopover, Popover } from 'components';
import navigationConfig from './navigationConfig';
import useStyles from './style';

const NavBar = (props) => {
  const {
    openMobile,
    onMobileClose,
    user,
    avatar,
    className,
    handleLogout,
    roles,
    currentAccount,
  } = props;
  const settingRef = useRef(null);
  const classes = useStyles();
  const router = useRouter();
  const [openSetting, setOpenSetting] = useState(false);
  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose(); // eslint-disable-line
    }
  }, [router.location.pathname]);

  const handleSettingOpen = () => {
    setOpenSetting(true);
  };

  const handleSettingClose = () => {
    setOpenSetting(false);
  };
  const navbarContent = (
    <div className={classes.content}>
      <div className={classes.profile}>
        {user && (
          <>
            <Avatar
              alt="Store"
              className={classes.avatar}
              component={RouterLink}
              src={avatar}
              to="/settings/general"
            />
            <Typography className={classes.name} variant="h4">
              {user.store.name}
            </Typography>
            <Typography variant="body2">{user.operator.email}</Typography>
            {user.operator && user.operator.dynamic_link && (
              <Container>
                <Typography variant="body2">{user.operator.dynamic_link}</Typography>
                <Popover
                  iconAction="help"
                  message="<div><p>You can use this link to send customers straight into your store inside the Lovvett app.</p>
                  <p>Use it in your social media profiles (Facebook, Instagram), in your Google My Business Page or in your website.</p>
                  <p>Reach out to us if you need any help on partners@lovvett.com.</p></div>"
                />
              </Container>
            )}
          </>
        )}
      </div>
      <Divider className={classes.divider} />
      <nav className={classes.navigation}>
        {navigationConfig.map((list) => (
          <Navigation component="div" key={list.title} pages={list.pages} title={list.title} />
        ))}

        <Hidden lgUp>
          {roles && roles.length > 1 && (
            <Button
              className={classes.logoutButton}
              color="inherit"
              onClick={handleSettingOpen}
              ref={settingRef}>
              <SettingsOutlinedIcon className={classes.logoutIcon} />
              Settings
            </Button>
          )}
          {currentAccount && roles && roles.length > 1 && (
            <SettingPopover
              anchorEl={settingRef.current}
              roles={roles}
              onClose={handleSettingClose}
              open={openSetting}
              currentAccount={currentAccount}
            />
          )}
        </Hidden>
        <Hidden lgUp>
          <Button color="inherit" className={classes.logoutButton} onClick={handleLogout}>
            <InputIcon className={classes.logoutIcon} />
            Sign out
          </Button>
        </Hidden>
        <Typography variant="body2" className={classes.version}>
          v {process.env.REACT_APP_VERSION}
        </Typography>
      </nav>
    </div>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer anchor="left" onClose={onMobileClose} open={openMobile} variant="temporary">
          <div className={clsx(classes.root, className)}>{navbarContent}</div>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Paper className={clsx(classes.root, className)} elevation={1} square>
          {navbarContent}
        </Paper>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  className: PropTypes.string.isRequired,
  onMobileClose: PropTypes.func.isRequired,
  openMobile: PropTypes.bool.isRequired,
  currentAccount: PropTypes.string.isRequired,
  user: PropTypes.string.isRequired,
  roles: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  handleLogout: PropTypes.func.isRequired,
};

NavBar.defaultProps = {};
export default NavBar;
