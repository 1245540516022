import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getOffer, removeOffer } from 'actions';
export default function useOffer (id, token) {
  id = typeof parseInt(id) === "number" ? id : null;
  const offer = useSelector(state => state.offers.offer);
  const dispatch = useDispatch();
  useEffect(() => {
    let mounted = true;
    const fetchOffer = () => {
        if (mounted && !offer && id && token) {
          dispatch(getOffer(id, token));
        }
    };

    fetchOffer();
    return () => {
      mounted = false;
      dispatch(removeOffer());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return offer;
};