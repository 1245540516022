import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography, colors } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlined';
import ErrorIcon from '@material-ui/icons/ErrorOutlined';
import WarningIcon from '@material-ui/icons/WarningOutlined';
import HelpIcon from '@material-ui/icons/Help';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Popover from '@material-ui/core/Popover';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '6px 16px',
    borderRadius: theme.shape.borderRadius
  },
  default: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  success: {
    backgroundColor: colors.green[600],
    color: theme.palette.white
  },
  info: {
    backgroundColor: colors.lightBlue[600],
    color: theme.palette.white
  },
  warning: {
    backgroundColor: colors.orange[900],
    color: theme.palette.white
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  },
  message: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0',
    flexDirection: 'row',
  },
  icon: {
    fontSize: 20,
    marginRight: theme.spacing(1)
  },
  iconAction: {
    fontSize: 20,
    marginRight: theme.spacing(1),
    cursor: 'pointer'
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    paddingLeft: 16,
    marginRight: -8
  },
  hover: {
    padding: '10px'
  },
  typography: {
    padding: theme.spacing(2),
    width: '500px',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  }
}));

const icons = {
  default: <InfoIcon />,
  success: <CheckCircleIcon />,
  info: <InfoIcon />,
  warning: <WarningIcon />,
  error: <ErrorIcon />,
  help: <HelpIcon />
};

const PopoverMessage = (props) => {
  const { className, icon, variant, iconAction, message, onClose } = props;
  const classes = useStyles();
  const createMarkup = (msg) => {
    return {__html: msg};
  }
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return <div>
    <span aria-describedby={id} className={classes.iconAction} onClick={handleClick}>{icons[iconAction]}</span>
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      className={classes.hover}
    >
      <Typography className={classes.typography}><div className={classes.message} dangerouslySetInnerHTML={createMarkup(message)} /></Typography>
    </Popover>
  </div>
};

PopoverMessage.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['default', 'info', 'success', 'warning', 'error'])
};

PopoverMessage.defaultProps = {
  variant: 'default'
};

export default PopoverMessage;
