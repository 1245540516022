import BaseService from './baseService';

class MenusService extends BaseService {
  getMenus(query, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
      params: {
        ...query,
      },
    };
    const url = '/v2/menueditor/menu/store';

    return this.get(url, params);
  }

  getMenu(menuId, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = `/v2/menueditor/menu/${menuId}`;

    return this.get(url, params);
  }

  createMenu(data, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = '/v2/menueditor/menu';

    return this.put(url, data, params);
  }

  updateMenu(data, id, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = `/v2/menueditor/menu/${id}`;

    return this.post(url, data, params);
  }

  archiveMenu(menuId, archive, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
    };

    let method = 'archive';
    if (archive) {
      method = 'unarchive';
    }

    const url = `/v2/menueditor/menu/${method}/${menuId}`;
    return this.put(url, {}, params);
  }

  getTimeTable(menuId) {
    const url = `/v2/menueditor/menu/${menuId}/timetables`;
    return this.get(url);
  }

  postTimeTable(body, menuId) {
    const url = `/v2/menueditor/menu/${menuId}/timetables`;
    return this.post(url, body);
  }

  menuValidation(key) {
    const url = `/v2/integrations/chowly/menu/validate/${key}`;
    return this.get(url);
  }

  chowlyMenuSync() {
    const url = `/v2/integrations/chowly/menu/sync`;
    return this.post(url, {});
  }

  chowlyMenuSyncStatus(taskId) {
    const url = `/v2/integrations/chowly/menu/sync/status/${taskId}`;
    return this.get(url);
  }
}

export default new MenusService();
