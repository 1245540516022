/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  colors,
 } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const DesactiveButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(colors.grey[300]),
    backgroundColor: colors.grey[300],
    '&:hover': {
      backgroundColor: colors.grey[500],
    },
  },
}))(Button);

const DeleteButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(colors.red[500]),
    backgroundColor: colors.red[500],
    '&:hover': {
      backgroundColor: colors.red[700],
    },
  },
}))(Button);

const components = {
  desactive: DesactiveButton,
  delete: DeleteButton,
}

const CustomButton = props => {
  const { mode, ...rest } = props;
  let Component = null;
  if (components[mode]) {
    Component = components[mode];
  }

  if (!Component) {
    return null;
  }
  return <Component {...rest} />;
};

CustomButton.propTypes = {
  mode: PropTypes.oneOf(['desactive', 'delete']).isRequired,
};
CustomButton.defaultProps = {
  mode: 'desactive'
}

export default CustomButton;
