import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getHome, cleanHome } from 'actions';

export default function useHome(session) {
  const home = useSelector(state => state.store.home);
  const is_opening_home = useSelector(state => state.store.is_opening_home);
  const dispatch = useDispatch();

  useEffect(() => {
    let mounted = true;
    const fetchHome = () => {
      if (mounted && !home && !is_opening_home && session && session.token) {
        dispatch(getHome(session.token));
      }
    };

    fetchHome();
    return () => {
      mounted = false;
      if (home && !is_opening_home) {
        dispatch(cleanHome());
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  return home;
};