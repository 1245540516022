import { useSelector } from 'react-redux';

export default function useSession () {
  const session = useSelector(state => state.session);

  if (session.user) {
    const accountId = session.user && session.user.operator ? session.user.operator.currentAccount : null;
    const token = session.token;

    return {
      loggedIn: session.loggedIn,
      accountId,
      token,
      user: session.user,
    };
  } else {
    return {
      loggedIn: false,
      token: null,
      accountId: null,
      user: null,
    }
  }
};