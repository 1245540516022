import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getStore } from 'actions';
export default function useProfileStore(token, accountId) {
  const profile = useSelector((state) => state.store.store);
  const dispatch = useDispatch();

  useEffect(() => {
    let mounted = true;
    const fetchProfile = () => {
      if (mounted && !profile && token && accountId) {
        dispatch(getStore(accountId, token));
      }
    };

    fetchProfile();
    return () => {
      mounted = false;
    };
  }, [token, accountId]);

  return profile;
}
