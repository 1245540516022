import React, { Suspense, useState, useEffect } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { LinearProgress, Backdrop, CircularProgress } from '@material-ui/core';
import { NavBar, TopBar } from './components';
import useRouter from 'utils/useRouter';
import { useDispatch, useSelector } from 'react-redux';
import { signin, setTokenFirebase, setUser, setSessionInitTimeDispatch } from 'actions';
import { useSnackbar, useProfileStore, useSession } from 'hooks';
import app from 'actions/user';
import Snackbar from 'components/Snackbar';
import { logout, signout, subscriberToTopic, addNotification, unSubscriberToTopic } from 'actions';
import { messaging } from 'init-fcm';
import useStyles from './style';

const ERROR_MESSAGE = 'Invalid e-mail or password';

const Dashboard = (props) => {
  const { route } = props;
  const router = useRouter();
  const classes = useStyles();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  const [token, setToken] = useState(null);
  const [snackbar, showError] = useSnackbar();
  const dispatch = useDispatch();
  const session = useSession();
  const { token: tokenSession, accountId, user } = session;
  const store = useProfileStore(tokenSession, accountId);
  const id = store ? store.id : null;
  const notifications = useSelector((state) => state.store.notifications);
  const showLoading = useSelector((state) => state.site.showLoading);
  const roles = user && user.operator && user.operator.rolesList.roles;

  useEffect(() => {
    if (id && messaging) {
      messaging
        .requestPermission()
        .then(async function() {
          const deviceToken = await messaging.getToken();
          if (id && session && session.token) {
            dispatch(subscriberToTopic(session.token, id, deviceToken));
          }
        })
        .catch(function(err) {
          console.log('Unable to get permission to notify.', err);
        });

      navigator.serviceWorker.addEventListener('message', ({ data }) => {
        let info = data;
        if (data.firebaseMessaging) {
          info = data.firebaseMessaging.payload.data;
        } else {
          info = data.data;
        }
        const now = Date.now();
        dispatch(
          addNotification({
            id: now,
            created_at: now / 1000,
            read: false,
            ...info,
          })
        );
      });
    }
    // eslint-disable-next-line
  }, [id]);
  useEffect(() => {
    if (token) {
      dispatch(setTokenFirebase(token));
    }

    // eslint-disable-next-line
  }, [token]);

  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true);
  };

  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false);
  };

  const [state, setState] = useState(() => {
    const user = app.auth().currentUser;
    return {
      initializing: !user,
      user,
    };
  });

  function onChange(user) {
    //cambia y setea en el login
    if (user) {
      user.getIdTokenResult().then(function(idToken) {
        setToken(idToken.token);
        if (!session.loggedIn) {
          signin(idToken.token)
            .then((resp) => {
              dispatch(setUser(resp.data));
              setState({ initializing: false, user });
            })
            .catch(() => {
              showError(ERROR_MESSAGE, 'error', 7000);
              onLogoutApp();
            });
        } else {
          setState({ initializing: false, user });
        }
      });
    } else {
      setState({ initializing: false, user });
    }
  }

  useEffect(() => {
    const unsubscribe = app.auth().onAuthStateChanged(onChange);
    return () => unsubscribe();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setSessionInitTimeDispatch());
  });

  if (!state.initializing && !session.user && !session.token) {
    router.history.push('/auth/login');
  }

  if (token && session.user && !session.user.store) {
    logout()
      .then(() => {
        dispatch(signout());
        router.history.push('/auth/login');
        showError('Invalid e-mail or password');
      })
      .catch(() => {
        console.log('err');
      });
    return null;
  }

  const closeSession = () => {
    if (id && messaging) {
      messaging
        .requestPermission()
        .then(async function() {
          const deviceToken = await messaging.getToken();
          if (id && session && session.token) {
            const unsubscribePush = await dispatch(
              unSubscriberToTopic(session.token, id, deviceToken)
            );
            if (unsubscribePush) {
              console.log('unSubscribe from topic');
            }
          }
        })
        .catch((err) => {
          console.log('Unable to get permission to notify.', err);
        });
    }
    onLogoutApp();
  };

  const onLogoutApp = () => {
    logout()
      .then(() => {
        dispatch(signout());
      })
      .catch(() => {
        console.log('err logout comonent');
      });
  };

  if (!token) {
    return <LinearProgress />;
  }

  return (
    <div className={classes.root}>
      <TopBar
        className={classes.topBar}
        currentAccount={accountId}
        handleLogout={closeSession}
        notifications={notifications}
        onOpenNavBarMobile={handleNavBarMobileOpen}
        roles={roles}
      />
      <div
        className={`${classes.container} main-container`}
        id="main-container"
      >
        {store && (
          <NavBar
            avatar={store ? store.avatar : null}
            className={classes.navBar}
            currentAccount={accountId}
            handleLogout={closeSession}
            onMobileClose={handleNavBarMobileClose}
            openMobile={openNavBarMobile}
            roles={roles}
            user={session.user}
          />
        )}
        <main className={classes.content}>
          {state.initializing && <LinearProgress />}
          {!state.initializing && (
            <Suspense fallback={<LinearProgress />}>{renderRoutes(route.routes)}</Suspense>
          )}
          <Snackbar
            floating
            message={snackbar.message}
            open={snackbar.show}
            variant={snackbar.type}
          />
        </main>
      </div>
      <Backdrop
        className={classes.backdrop}
        open={showLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

Dashboard.propTypes = {
  route: PropTypes.object,
};

export default Dashboard;
