import countries from 'data/countries';
import days from 'data/days';
const INITIAL_STATE = {
  countries,
  days,
};
const statics = (state = INITIAL_STATE) => {
  return state;
}

export default statics;