import { makeStyles } from '@material-ui/styles';

const style = (theme) => (
  {
    root: {},
    content: {
      padding: 0
    },
    actions: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& > * + *': {
        marginLeft: 0
      }
    },
    buttonIcon: {
      marginRight: theme.spacing(1)
    }
  }
)
const useStyles = makeStyles(theme => style(theme));

export default useStyles;