import { makeStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';

const style = (theme) => (
  {
    root: {},
    toolbar: {
      '& > * + *': {
        marginLeft: theme.spacing(1)
      }
    },
    deleteButton: {
      color: theme.palette.white,
      backgroundColor: colors.red[600],
      '&:hover': {
        backgroundColor: colors.red[900]
      }
    },
    external: {
      display: 'flex',
      paddingTop: 20,
      paddingBottom: 20,
    }
  }
)
const useStyles = makeStyles(theme => style(theme));

export default useStyles;