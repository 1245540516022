import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getOperational } from 'actions';
export default function useOperational(token) {
  const operational = useSelector((state) => state.store.operational);
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchOperational = () => {
      if (token) {
        dispatch(getOperational(token));
      }
    };

    fetchOperational();

    // eslint-disable-next-line
  }, []);

  return operational;
}
