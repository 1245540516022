import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getSummaries } from 'actions';
export default function useSummaries (token) {
  const summaries = useSelector(state => state.orders.summaries);
  const dispatch = useDispatch();
  useEffect(() => {
    let mounted = true;
    const fetchSummaries = () => {
        if (mounted && token) {
          dispatch(getSummaries(token));
        }
    };

    fetchSummaries();
    return () => {
      mounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return summaries;
};