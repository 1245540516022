import * as types from 'constants/ActionTypes';
import StoreService from '../services/store';
import OrderService from '../services/orders';
import fileSaver from 'utils/fileSaver';
import { isSessionDeprecated, getNewToken } from './sessions';
import { showSnackbar } from './utils';

export const onPlanAndSave = () => async (dispatch) => {
  StoreService.onPlanAndSave().then(({ data }) => {
    dispatch({
      type: types.APP.GET_PLAN_SAVE,
      payload: data,
    });
  });
};

export const postPlanAndSave = (dta) => async (dispatch) => {
  StoreService.postPlanAndSave(dta)
    .then(({ data }) => {
      showSnackbar(dispatch, types.MESSAGE_OK, 'success');
      dispatch({
        type: types.APP.GET_PLAN_SAVE,
        payload: data,
      });
    })
    .catch(() => {
      showSnackbar(dispatch, 'Error to post billing');
    });
};

export const getPlanSaves = (query, tab) => async (dispatch) => {
  try {
    const { data } = await OrderService.getPlansSaves(query, tab);
    dispatch({
      type: types.APP.GET_PLANS_SAVES,
      payload: data,
    });
  } catch (error) {
    showSnackbar(dispatch, 'Error on get plans and saves');
  }
};

export const postPlanDelivered = (orderId, tkn) => async (dispatch) => {
  let token = tkn;
  const isTokenDeprecated = isSessionDeprecated();

  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }
  try {
    await OrderService.postPlanDelivered(orderId, token);
    showSnackbar(dispatch, 'Order was upadted successfully', 'success');
    dispatch({
      type: types.APP.UPDATE_PLANS_SAVES,
      payload: orderId,
    });
  } catch (error) {
    showSnackbar(dispatch, 'Error on get plans and saves');
  }
};
export const getDownload = (date, token) => async (dispatch) => {
  try {
    OrderService.getDownloadPSToday(date, token)
      .then((response) => {
        const fileName = `summary_ps_${date}.csv`;
        fileSaver(response.data, fileName);
      })
      .catch((err) => {
        console.log('error', err);
      });
  } catch (error) {
    showSnackbar(dispatch, 'Error on get download plans and saves');
  }
};
