import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, Typography, Avatar } from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.white,
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  avatar: {
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.main,
    height: 48,
    width: 48,
  },
}));

const RoiPerCustomer = (props) => {
  const { className, data } = props;
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)}>
      <div>
        <Typography color="inherit" component="h3" gutterBottom variant="overline">
          TOTAL ITEMS RESCUED
        </Typography>
        <div className={classes.details}>
          <Typography color="inherit" variant="h3">
            {data.items_rescued}
          </Typography>
        </div>
      </div>
      <Avatar className={classes.avatar} color="inherit">
        <AttachMoneyIcon />
      </Avatar>
    </Card>
  );
};

RoiPerCustomer.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape().isRequired,
};
RoiPerCustomer.defaultProps = {
  className: '',
};

export default RoiPerCustomer;
