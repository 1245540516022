import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SnackBarPortal = props => {
  const { isActive, severity, message } = props;

  return (
    <Snackbar open={isActive}>
      <Alert severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

SnackBarPortal.propTypes = {
  isActive: PropTypes.bool,
  severity: PropTypes.string,
  message: PropTypes.string,
};

SnackBarPortal.defaultProps = {
  isActive: false,
  severity: 'error',
  message: '',
};

export default SnackBarPortal;
