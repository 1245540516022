import { makeStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  counter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  time: {
    display: 'flex',
    flexDirection: 'row',
  },
  hr: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  showhr: {
    width: '100%',
  },
  date: {
    fontSize: 70,
    padding: '0 10px',
    fontWeight: 'bold',
    cursor: 'pointer',
    '& > span': {
      margin: '0 6px',
    },
  },
  selected: {
    backgroundColor: '#ebe8e8',
    borderRadius: 8,
  },
  until: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  listHeader: {
    fontWeight: 'bold',
    color: '#000',
  },
  error: {
    color: colors.red[600],
    fontWeight: 'bold',
    textAlign: 'center',
  },
  actions: {
    textAlign: 'center',
  },
  legend: {
    display: 'flex',
    justifyContent: 'center',
    '& > *': {
      margin: '0 30px',
    },
  },
}));

export default useStyles;
