/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';

const Frame = props => {
  const { url } = props;
  const style = {
    height: '100%',
  }
  return (
    <div style={style}>
      <iframe
        src={url}
        title="frame content"
        width="100%"
        height="100%"
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
      />
    </div>
  );
};

Frame.propTypes = {
  children: PropTypes.node
};
Frame.defaultProps = {
  url: '',
}

export default Frame;
