import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getCategoryItem, resetCategoryItem } from 'actions';

export default function useCategoryItem(menuId, categoryId, itemId, token) {
  const categoryItem = useSelector((state) => state.items.item);
  const dispatch = useDispatch();

  const resetItem = () => dispatch(resetCategoryItem());

  useEffect(() => {
    let mounted = true;
    const fetchCategoryItem = () => {
      if (mounted && menuId && categoryId && itemId) {
        dispatch(getCategoryItem(menuId, categoryId, itemId, token));
      }
    };

    fetchCategoryItem();
    return () => {
      mounted = false;
    };
  }, [menuId, categoryId, itemId]);

  return {
    itemInfo: categoryItem,
    resetItem,
  };
}
