import BaseService from './baseService';

class ModifierService extends BaseService {
  getModifiersItem(itemId, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = `/v2/menueditor/menu/item/${itemId}/modifiers`;

    return this.get(url, params);
  }

  getModifier(modifierId, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = `/v2/menueditor/menu/modifier/${modifierId}`;

    return this.get(url, params);
  }

  getDefaultModifiers(token, query) {
    let param = {};
    if (query) {
      param = query;
    }
    const params = {
      headers: {
        'X-Access-Token': token,
      },
      params: param,
    };
    const url = `/v2/menueditor/menu/modifiers`;

    return this.get(url, params);
  }

  setModifierStockStatus(modifierId, optionId, until, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = `/v2/menueditor/modifier/${modifierId}/option/${optionId}/stock_status/${until}`;
    return this.post(url, {}, params);
  }

  createModifier(data, itemId, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = `/v2/menueditor/menu/modifier/${itemId}`;

    return this.put(url, data, params);
  }

  addModifiersToItem(itemId, modifierId, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = `/v2/menueditor/menu/modifier/item/${itemId}/add/${modifierId}`;

    return this.put(url, {}, params);
  }

  deleteModifier(itemId, modifierId, global, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
      params: {
        global,
      },
    };
    const url = `/v2/menueditor/menu/items/${itemId}/modifier/${modifierId}`;

    return this.del(url, params);
  }

  sortModifiers(body) {
    const url = `/v2/menueditor/menu/modifiers/order`;

    return this.post(url, body);
  }
}

export default new ModifierService();
