
import BaseService from './baseService';

class StadisticService extends BaseService {
  getManagementInfo(token) {
    const params = {
      headers: {
        'X-Access-Token': token
      }
    }
    const url = `/v2/stores/management_info`;
    return this.get(url, params);
  }

  getManagementSales(token) {
    const params = {
      headers: {
        'X-Access-Token': token
      }
    }
    const url = `/v2/stores/management_info_sales`;
    return this.get(url, params);
  }

  getManagementCustomers(token) {
    const params = {
      headers: {
        'X-Access-Token': token
      }
    }
    const url = `/v2/stores/management_info_customers`;
    return this.get(url, params);
  }

  getRatings(query, token) {
    const params = {
      headers: {
        'X-Access-Token': token
      },
      params: {
        ...query
      }
    }
    const url = `/v2/stores/ratings/pagination`;
    return this.get(url, params);
  }

}

export default new StadisticService();
