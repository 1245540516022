import validate from 'validate.js';
import moment from 'moment';
import { isToday } from 'utils/tools';

const RECURRING = 'recurring';
const TEMPLATE = 'template';
const PERCENT_DISCOUNT = 40;

validate.validators.datetime = function (value, options) {
  const isValid = moment(value).isValid();
  if (Number.isNaN(value) && !options.allowEmpty && !isValid) {
    return 'is required';
  }
  return null;
};

validate.validators.repeat = function (value, options, key, attributes) {
  if (value === '' && attributes.repeat_on_days.length > 0) {
    return {
      presence: {
        message: '^Quantity is required',
      },
    };
  }
  return null;
};

validate.validators.discount = function (value, options, key, attributes) {
  const unitPrice = parseFloat(value);
  const originPrice = parseFloat(attributes.origin_price);
  const disc = parseInt((((originPrice - unitPrice) * 100) / originPrice).toFixed()); // eslint-disable-line

  if ((!disc || disc < PERCENT_DISCOUNT) && originPrice >= 0) {
    return `^Offer price should be at least ${PERCENT_DISCOUNT}% of its ordinary listing price`;
  }
  return null;
};

validate.validators.greaterThanDate = (value, options, key, attributes) => {
  const { field, message } = options;

  if (field) {
    const upperTo = attributes[field];

    if (upperTo && value < upperTo) {
      return message;
    }
  }
  return null;
};

validate.validators.repeatdays = function (value, options, key, attributes) {
  const { origin } = attributes;
  const values = value && Object.values(value);
  const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  const today = new Date();
  const todayDayName = days[today.getDay()];
  if (origin === RECURRING && values && values.length === 0) {
    return '^If you want to create a recurring offer you should enter al least one day of the week';
  }
  if (origin === RECURRING && values && values.length > 0 && !values.includes(todayDayName)) {
    return "^Oops! It seems the Offer you're trying to create won´t be made available today. In these cases, please create a Template instead.  By creating a Template, Offers will be automatically made available for the dates you choose.";
  }
  return null;
};

validate.validators.isInFuture = function (value, options, key, attributes) {
  const now = moment().unix();
  const { origin } = attributes;
  if (origin === TEMPLATE && isToday(value, now) && now > value) {
    return '^Time to order and pick-up needs to be later than now.';
  }
  return null;
};

validate.validators.presenceDepend = (value, options, key, attributes) => {
  const { field, message } = options;
  if (attributes[field] && (value === '' || value === null || value === 'NaN')) {
    return message;
  }
  return null;
};

export default validate;
