import { useState, useEffect } from 'react';

const usePagination = (data, param) => {
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState(param);
  const [pagination, setPagination] = useState({
    pageCount: 0,
    pagePerView: query.limit,
  });

  const handlePageClick = (event) => {
    const { selected } = event;
    const offset = Math.ceil(selected * query.limit);
    setQuery((pag) => ({
      ...pag,
      offset,
    }));
    setPage(selected);
  };

  useEffect(() => {
    if (data) {
      setPagination((pag) => ({
        ...pag,
        pageCount: Math.ceil(data.total / query.limit),
      }));
    }
  }, [data]);

  /* useEffect(() => {
    return () => {
      setQuery({
        ...param,
      });
    };
  }, []); */

  return { pagination, query, page, handlePageClick };
};

export default usePagination;
