import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getTemplates } from 'actions';
export default function useTemplates (accountId, body, query, token, tab) {
  accountId = typeof parseInt(accountId) === "number" ? accountId : null;
  const templates = useSelector(state => state.templates.templates);
  const dispatch = useDispatch();
  useEffect(() => {
    let mounted = true;
    const fetchTemplates = () => {
        if (mounted && accountId ) {
          dispatch(getTemplates(accountId, body, query, token));
        }
    };

    fetchTemplates();
    return () => {
      mounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, query]);

  return templates;
};