import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Doughnut } from 'react-chartjs-2';
import { makeStyles, useTheme } from '@material-ui/styles';
import { colors } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
}));

const Chart = (props) => {
  const { data: dataProp, className } = props;
  const classes = useStyles();
  const theme = useTheme();

  const progressColor = [colors.orange[500], colors.blue[800], colors.green[600], colors.red[500]];

  const data = {
    datasets: [
      {
        data: [],
        backgroundColor: [],
        borderWidth: 8,
        borderColor: theme.palette.white,
        hoverBorderColor: theme.palette.white,
      },
    ],
    labels: [],
  };

  // eslint-disable-next-line
  for (const [key, item] of dataProp.entries()) {
    // TODO: refactor to keys
    data.datasets[0].data.push(item.value);
    data.datasets[0].backgroundColor.push(progressColor[key]);
    data.labels.push(item.label);
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cutoutPercentage: 80,
    legend: {
      display: false,
    },
    layout: {
      padding: 0,
    },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      caretSize: 10,
      yPadding: 20,
      xPadding: 20,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary,
      callbacks: {
        label(tooltipItem, datas) {
          const label = datas.labels[tooltipItem.index];
          const value = datas.datasets[0].data[tooltipItem.index];

          return `${label}: ${value}%`;
        },
      },
    },
  };

  return (
    <div className={clsx(classes.root, className)}>
      <Doughnut data={data} options={options} />
    </div>
  );
};

Chart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf().isRequired,
};
Chart.defaultProps = {
  className: '',
};

export default Chart;
