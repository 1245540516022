import * as types from 'constants/ActionTypes';
const INITIAL_STATE = {
  snackbar: {
    show: false,
    message: '',
    type: 'error'
  },
  showLoading: false,
};
const offers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SESSION_LOGOUT: {
      return {
        ...state,
        snackbar: {
          show: false,
          message: '',
          types: 'error'
        }
      };
    }
    case types.SET_ERROR: {
      return {
        ...state,
        error: {
          show: true,
          message: action.message
        }
      };
    }
    case types.SET_SNACKBAR: {
      return {
        ...state,
        snackbar: {
          show: true,
          message: action.message,
          type: action.typesError
        }
      };
    }
    case types.HIDE_SNACKBAR: {
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          show: false
        }
      };
    }
    case types.SET_ERROR_CLEAN: {
      return {
        ...state,
        error: {
          show: false,
          message: ''
        }
      };
    }
    case types.SHOW_LOADING: {
      return {
        ...state,
        showLoading: true,
      };
    }
    case types.HIDE_LOADING: {
      return {
        ...state,
        showLoading: false,
      };
    }

    default: {
      return state;
    }
  }
}

export default offers;