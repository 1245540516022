import { useState } from 'react'

export default function useError () {
  const [error, setError] = useState({
    show: false,
    message: ''
  });
  let showError = (msg, type = 'error') => setError({
    show: true,
    message: msg,
    type
  });
  let hideError = (msg) => setError({
    show: false,
    message: ''
  });
  return [error, showError, hideError];
};