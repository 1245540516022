import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';

const MultiSelect = (props) => {
  const { name, handleMultiple, options, value, label } = props;

  return (
    <Autocomplete
      SelectProps={{ native: true }}
      getOptionLabel={(item) => item.label}
      multiple
      name={name}
      onChange={(event, values) => handleMultiple(event, name, values)}
      options={options} // eslint-disable-line
      renderInput={(params) => <TextField
        {...params}
        label={label}
        variant="outlined"
      />}
      value={value}
      variant="outlined"
    />
  );
};

export default MultiSelect;
