import { makeStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';

const style = (theme) => ({
  root: {
    display: 'flex',
  },
  data: {
    marginLeft: 20,
  },
  formControl: {
    margin: theme.spacing(3),
  },
  reason: {
    marginTop: 30,
  },
  info: {
    backgroundColor: colors.lightBlue[600],
    color: theme.palette.white,
  },
  iconAction: {
    fontSize: 20,
    marginRight: theme.spacing(1),
    cursor: 'pointer',
  },
  hover: {
    padding: 10,
  },
  typography: {
    fontWeight: 'bold',
    fontStyle: 'italic',
    padding: theme.spacing(1),
    width: 350,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  alert: {
    marginTop: 20,
  },
});
const useStyles = makeStyles((theme) => style(theme));

export default useStyles;
