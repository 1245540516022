import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAllItems } from 'actions';
import { getAllItemsData } from 'selectors/items';

/**
 * Return: All items in app
 */
export default function useItems(query) {
  const items = useSelector(getAllItemsData);
  const dispatch = useDispatch();
  const [refreshIndex, setRefreshIndex] = useState(0);

  const refetchItems = () => setRefreshIndex((prev) => prev + 1);

  useEffect(() => {
    const fetchItems = () => {
      dispatch(getAllItems(query));
    };

    fetchItems();
  }, [refreshIndex, query]);

  return { items, refetchItems };
}
