import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getCategoryItems } from 'actions';

export default function useCategoryItems(query, menuId, categoryId, token) {
  const categoryItems = useSelector((state) => state.items.items);
  const dispatch = useDispatch();
  const [refreshIndex, setRefreshIndex] = useState(0);

  const refetchItems = () => setRefreshIndex((prev) => prev + 1);

  useEffect(() => {
    let mounted = true;
    const fetchCategoryItems = () => {
      if (mounted && categoryId) {
        dispatch(getCategoryItems(query, menuId, categoryId, token));
      }
    };

    fetchCategoryItems();
    return () => {
      mounted = false;
    };
  }, [query, categoryId, refreshIndex]);

  return { categoryItems, refetchItems };
}
