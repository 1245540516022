import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
}
from '@material-ui/core';

const LModal = props => {
  const {
    className,
    title,
    visible,
    onClose,
    content,
    actions
  } = props;

  return (
    <Dialog
      open={visible}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={className}
    >
      {title && <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>}
      <DialogContent dividers>
        {content}
      </DialogContent>
      {actions && <DialogActions>
        {actions}
      </DialogActions>}
    </Dialog>
  );
};

LModal.propTypes = {
  content: PropTypes.node,
  actions: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.string,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

LModal.defaultProps = {
  content: null,
  actions: null,
  className: '',
  title: null,
  visible: false,
};

export default LModal;
