import { useSelector, useDispatch } from 'react-redux';
import { showSnackbar, hideSnackbar } from 'actions';
const HIDE_TIME_SB = 4000;
export default function useSnackbar () {
  const snackbar = useSelector(state => state.site.snackbar);
  const dispatch = useDispatch();

  let showError = (msg, type = 'error', timeout = HIDE_TIME_SB) => {
    dispatch(showSnackbar(msg, type));
    setTimeout(() => {
      dispatch(hideSnackbar());
    }, timeout);
  };
  let hideError = () => {
    dispatch(hideSnackbar());
  };
  return [snackbar, showError, hideError];
};