import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getDefaultModifiers } from 'actions';

export default function useModifiers(token, query) {
  const modifiers = useSelector((state) => state.modifiers.modifiers);
  const dispatch = useDispatch();
  const [refreshIndex, setRefreshIndex] = useState(0);
  const refetchModifiers = () => setRefreshIndex((prev) => prev + 1);

  useEffect(() => {
    const fetchModifiers = () => {
      dispatch(getDefaultModifiers(token, query));
    };

    fetchModifiers();
  }, [refreshIndex, query]);

  return {
    modifiers,
    refetchModifiers,
  };
}
