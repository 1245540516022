/* jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Typography } from '@material-ui/core';
import { AddCircle, RemoveCircle } from '@material-ui/icons';
import moment from 'moment';
import clsx from 'clsx';
import useStyle from './style';

const Timer = (props) => {
  const { preText, onChangeText, onChangeTime } = props;
  const classes = useStyle();
  const [selected, setSelected] = useState('hour');
  const [times, setTimes] = useState({ hour: 0, minute: 10 });
  const [untilTime, setUntilTime] = useState(moment().add(10, 'minutes'));

  const addTime = (isAdd = true) => {
    const counter = isAdd ? 1 : -1;
    if (times[selected] === 0 && !isAdd) {
      return;
    }
    setTimes((prev) => ({
      ...prev,
      [selected]: prev[selected] + counter,
    }));
  };

  useEffect(() => {
    setUntilTime(moment().add(times.hour, 'hours').add(times.minute, 'minutes'));
  }, [times]);

  useEffect(() => {
    const message = `${preText} ${moment(untilTime).format('HH:mm A')}`;
    onChangeText && onChangeText(message); // eslint-disable-line
    onChangeTime && onChangeTime(untilTime); // eslint-disable-line
  }, [untilTime]);

  return (
    <div className={classes.counter}>
      <IconButton
        aria-label="upload picture"
        color="primary"
        component="span"
        onClick={() => addTime(false)}>
        <RemoveCircle />
      </IconButton>
      <div className={classes.time}>
        <div className={classes.hr}>
          <div
            className={clsx(classes.date, [selected === 'hour' ? classes.selected : ''])}
            onClick={() => setSelected('hour')}
            role="button"
            aria-hidden>
            {times.hour}
          </div>
          <Typography variant="body2">HOURS</Typography>
        </div>
        <div className={classes.date}>:</div>
        <div className={classes.hr}>
          <div
            className={clsx(classes.date, [selected === 'minute' ? classes.selected : ''])}
            onClick={() => setSelected('minute')}
            role="button"
            aria-hidden>
            {times.minute}
          </div>
          <Typography variant="body2">MINUTES</Typography>
        </div>
      </div>
      <IconButton
        aria-label="upload picture"
        color="primary"
        component="span"
        onClick={() => addTime()}>
        <AddCircle />
      </IconButton>
    </div>
  );
};

Timer.propTypes = {
  preText: PropTypes.string,
  onChangeText: PropTypes.func.isRequired,
  onChangeTime: PropTypes.func.isRequired,
};

Timer.defaultProps = {
  preText: 'Until',
};

export default Timer;
