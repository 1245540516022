import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getItemPrices, cleanItemPrices } from 'actions';

export default function usePrices(itemId, token) {
  const prices = useSelector((state) => state.items.itemPrices);
  const dispatch = useDispatch();

  useEffect(() => {
    let mounted = true;
    const fetchItemPrices = () => {
      if (mounted) {
        dispatch(getItemPrices(itemId, token));
      }
    };

    fetchItemPrices();
    return () => {
      mounted = false;
      dispatch(cleanItemPrices());
    };

    // eslint-disable-next-line
  }, []);

  return prices;
}
