import * as types from 'constants/ActionTypes';
const INITIAL_STATE = {
  templates: null,
  template: null,
  tab_selected: 'menu',
  offer_id_temp: null,
};
const templates = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SESSION_LOGOUT: {
      return {
        ...state,
        ...INITIAL_STATE,
      };
    }
    case types.GET_TEMPLATES: {
      return {
        ...state,
        templates: action.data
      };
    }
    case types.GET_TEMPLATE: {
      return {
        ...state,
        template: action.data
      };
    }
    case types.DELETE_TEMPLATE: {
      return {
        ...state,
        templates: {
          ...state.templates,
          data: state.templates.data.filter(template => template.id !== action.id)
        }
      };
    }
    case types.CLEAN_TEMPLATE: {
      return {
        ...state,
        template: null
      };
    }
    case types.SET_MEDIA_TEMPLATE: {
      const { data } = action;
      return {
        ...state,
        template: {
          ...state.template,
          offer: {
            ...state.template.offer,
            media: data
          }
        }
      };
    }
    case types.UPDATE_STATUS_TEMPLATE: {
      return {
        ...state,
        templates: {
          ...state.template,
          data: state.templates.data.map(template => {
            if (template.id === action.id) {
              return {
                ...template,
                status: action.status
              }
            } else {
              return template;
            }
          })
        }
      };
    }
    case types.SET_TAB_TEMPLATE: {
      const { tab } = action;

      return {
        ...state,
        tab_selected: tab
      };
    }
    case types.SET_OFFER_ID_TEMP: {
      const { offer_id } = action;

      return {
        ...state,
        offer_id_temp: offer_id,
      };
    }

    default: {
      return state;
    }
  }
}

export default templates;