import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getIntegrations } from 'actions';
export default function useIntegrations(token) {
  const integrations = useSelector((state) => state.store.integrations);
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchIntegrations = () => {
      if (token) {
        dispatch(getIntegrations(token));
      }
    };

    fetchIntegrations();
    // eslint-disable-next-line
  }, []);

  return integrations;
}
