import BaseService from './baseService';

class MenuCategories extends BaseService {
  getCategories(query, id, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
      params: {
        ...query,
      },
    };
    const url = `/v2/menueditor/menu/${id}/category`;

    return this.get(url, params);
  }

  createCategory(data, id, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = `/v2/menueditor/menu/${id}/category`;

    return this.put(url, data, params);
  }

  updateCategory(data, id, idCategory, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = `/v2/menueditor/menu/${id}/category/${idCategory}`;

    return this.post(url, data, params);
  }

  getCategoryItems(query, menuId, categoryId, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
      params: {
        ...query,
      },
    };
    const url = `/v2/menueditor/menu/${menuId}/category/${categoryId}/items`;

    return this.get(url, params);
  }

  getCategoryItem(menuId, categoryId, itemId, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = `/v2/menueditor/menu/${menuId}/category/${categoryId}/item/${itemId}`;

    return this.get(url, params);
  }

  createItem(data, menuId, categoryId, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = `/v2/menueditor/menu/${menuId}/category/${categoryId}/item`;

    return this.put(url, data, params);
  }

  updateItem(data, menuId, categoryId, itemId, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = `/v2/menueditor/menu/${menuId}/category/${categoryId}/item/${itemId}`;

    return this.post(url, data, params);
  }

  addMediaItem(data, config, itemId, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
      ...config,
    };
    const url = `/v2/menueditor/menu/items/${itemId}/media`;
    return this.put(url, data, params);
  }

  getItemPrices(itemId, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = `/v2/menueditor/menu/items/${itemId}/prices`;

    return this.get(url, params);
  }

  getItemFullfillment(itemId, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = `/v2/menueditor/menu/items/${itemId}/fulfillment`;

    return this.get(url, params);
  }

  getItemLovvett(itemId, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = `/v2/menueditor/menu/items/${itemId}/lovvett`;

    return this.get(url, params);
  }

  getItemNote(itemId, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = `/v2/menueditor/menu/items/${itemId}/note`;

    return this.get(url, params);
  }

  updateLovvet(body, itemId, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = `/v2/menueditor/menu/items/${itemId}/lovvett`;

    return this.post(url, body, params);
  }

  updatePrice(body, itemId, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = `/v2/menueditor/menu/items/${itemId}/prices`;

    return this.post(url, body, params);
  }

  updateFulfillment(body, itemId, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = `/v2/menueditor/menu/items/${itemId}/fulfillment`;

    return this.post(url, body, params);
  }

  updateItemNote(body, itemId, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = `/v2/menueditor/menu/items/${itemId}/note`;

    return this.post(url, body, params);
  }

  deleteMediaItem(itemId, urlFile, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = `/v2/menueditor/menu/items/${itemId}/media/${urlFile}`;

    return this.del(url, params);
  }

  deleteItem(menuId, categoryId, itemId, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = `/v2/menueditor/menu/${menuId}/category/${categoryId}/item/${itemId}`;

    return this.del(url, params);
  }

  getAllItems(query) {
    const url = '/v2/menueditor/menu/items';
    let params = {};
    if (query) {
      params = {
        params: {
          ...query,
        },
      };
    }
    return this.get(url, params);
  }

  addItemsMenu(menuId, categoryId, itemId, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = `/v2/menueditor/menu/${menuId}/category/${categoryId}/item/${itemId}`;

    return this.put(url, {}, params);
  }

  orderCategory(menuId, body) {
    const url = `/v2/menueditor/menu/${menuId}/category/order`;

    return this.post(url, body);
  }

  orderCategoryItems(menuId, categoryId, body) {
    const url = `/v2/menueditor/menu/${menuId}/category/${categoryId}/item/order`;

    return this.post(url, body);
  }

  setItemStockStatus(itemId, until, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = `/v2/menueditor/item/${itemId}/stock_status/${until}`;

    return this.post(url, {}, params);
  }
}

export default new MenuCategories();
