import { useSelector, useDispatch } from 'react-redux';
import { showSnackbar, hideSnackbar } from 'actions';
const HIDE_TIME_SB = 4000;

export default function useSnackbarV2 () {
  const snackbar = useSelector(state => state.site.snackbar);
  const dispatch = useDispatch();

  const showError = (msg, type = 'error', timeout = HIDE_TIME_SB) => {
    dispatch(showSnackbar(msg, type));
    setTimeout(() => {
      dispatch(hideSnackbar());
    }, timeout);
  };

  const hideError = () => {
    dispatch(hideSnackbar());
  };

  return {
    snackbar,
    showMessage: showError,
    hideMessage: hideError,
  }
};