import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getManagementSales } from 'actions';
export default function useManagementSales(token) {
  const management_sales = useSelector(state => state.stadistic.management_sales);
  const dispatch = useDispatch();
  useEffect(() => {
    let mounted = true;
    const fetchManagementSales = () => {
        if (mounted && token) {
          dispatch(getManagementSales(token));
        }
    };

    fetchManagementSales();
    return () => {
      mounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return management_sales;
};