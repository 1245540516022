import * as types from 'constants/ActionTypes';
import StoreService from '../services/store';
import { showSnackbar } from './utils';
import { isSessionDeprecated, getNewToken } from './sessions';

export const updateStore = (dta, tkn) => async (dispatch) => {
  const data = dta;
  let token = tkn;
  data.lat = data.address_lat ? data.address_lat : data.lat;
  data.lon = data.address_lon ? data.address_lon : data.lon;
  delete data.address_lat;
  delete data.address_lon;

  const isTokenDeprecated = isSessionDeprecated();

  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  StoreService.update(data, token)
    .then(() => {
      showSnackbar(dispatch, types.MESSAGE_OK, 'success');
      return dispatch({
        type: types.UPDATE_STORE,
        data,
      });
    })
    .catch(() => {
      showSnackbar(dispatch, 'Error on store data save');
    });
};

export const getStore = (id, tkn) => async (dispatch) => {
  let token = tkn;
  const isTokenDeprecated = isSessionDeprecated();

  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  StoreService.getStore(id, token)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: types.GET_STORE,
        data,
      });
    })
    .catch((err) => {
      console.log(err);
      console.log('get store');
    });
};

export const getOlo = (tkn) => async (dispatch) => {
  let token = tkn;
  const isTokenDeprecated = isSessionDeprecated();

  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  StoreService.getOlo(token)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: types.GET_ONLINE_ORDERING,
        data,
      });
    })
    .catch((err) => {
      console.log(err);
      console.log('get olo');
    });
};

export const getHome = (tkn) => async (dispatch) => {
  let token = tkn;
  const isTokenDeprecated = isSessionDeprecated();

  dispatch({
    type: types.IS_OPENING_HOME,
  });

  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  StoreService.getHome(token)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: types.GET_HOME,
        data,
      });
    })
    .catch(() => {
      console.log('get store');
    });
};

export const cleanHome = () => async (dispatch) => {
  dispatch({
    type: types.CLEAN_HOME,
  });
};

export const postLogo = (id, data, config, tkn) => async (dispatch) => {
  let token = tkn;
  const isTokenDeprecated = isSessionDeprecated();

  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  return StoreService.postLogo(data, config, token).catch((err) => {
    console.log('error');
    console.log(err);
  });
};

export const postCover = (id, data, config, tkn) => async (dispatch) => {
  let token = tkn;
  const isTokenDeprecated = isSessionDeprecated();

  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  return StoreService.postCover(data, config, token).catch((err) => {
    console.log('error');
    console.log(err);
  });
};

export const updateLogo = (urlImage) => (dispatch) =>
  dispatch({
    type: types.UPDATE_LOGO,
    urlImage,
  });

export const getBilling = (id, tkn) => async (dispatch) => {
  let token = tkn;
  const isTokenDeprecated = isSessionDeprecated();

  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  StoreService.getBilling(id, token)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: types.GET_BILLING,
        data,
      });
    })
    .catch(() => {
      console.log('get billing');
    });
};

export const getIntegrations = (tkn) => async (dispatch) => {
  let token = tkn;
  const isTokenDeprecated = isSessionDeprecated();

  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  StoreService.getIntegrations(token)
    .then((response) => {
      const { data } = response;
      dispatch({
        type: types.GET_INTEGRATIONS_INFO,
        data,
      });
    })
    .catch(() => {
      console.log('get integrations info');
    });
};

export const getOperational = (tkn) => async (dispatch) => {
  let token = tkn;
  const isTokenDeprecated = isSessionDeprecated();

  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  StoreService.getOperational(token)
    .then(({ data }) => {
      dispatch({
        type: types.GET_OPERATIONAL_INFO,
        data,
      });
    })
    .catch(() => {
      console.log('get operational info');
    });
};

export const getNotifications = (id, tkn) => async (dispatch) => {
  let token = tkn;
  const isTokenDeprecated = isSessionDeprecated();

  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  StoreService.getNotifications(id, token)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: types.GET_NOTIFICATIONS_EDIT,
        data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const postNotification = (data, tkn) => async (dispatch) => {
  let token = tkn;
  const isTokenDeprecated = isSessionDeprecated();

  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  StoreService.postNotification(data, token)
    .then((response) => {
      const responseData = response.data;

      showSnackbar(dispatch, types.MESSAGE_OK, 'success');
      return dispatch({
        type: types.POST_NOTIFICATION,
        data: responseData,
      });
    })
    .catch(() => {
      showSnackbar(dispatch, 'Error to post notification');
    });
};

export const postBilling = (id, dta, tkn) => async (dispatch) => {
  let token = tkn;
  const data = dta;
  const isTokenDeprecated = isSessionDeprecated();

  if (data.app_fee) {
    delete data.app_fee;
  }
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  StoreService.postBilling(id, data, token)
    .then(() => {
      showSnackbar(dispatch, types.MESSAGE_OK, 'success');
      return dispatch({
        type: types.POST_BILLING,
        data,
      });
    })
    .catch(() => {
      showSnackbar(dispatch, 'Error to post billing');
    });
};

export const postIntegrations = (data, tkn) => async (dispatch) => {
  let token = tkn;
  const isTokenDeprecated = isSessionDeprecated();

  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  StoreService.postIntegrations(data, token)
    .then(() => {
      showSnackbar(dispatch, types.MESSAGE_OK, 'success');
      dispatch({
        type: types.POST_INTEGRATIONS_INFO,
        data,
      });
    })
    .catch((error) => {
      let message = 'Error to post integrations';
      if (error.response) {
        const { status } = error.response;
        if (status === 406) {
          message = 'ApiKey invalid';
        }
      }
      showSnackbar(dispatch, message);
    });
};

export const postOperational = (dta, tkn) => async (dispatch) => {
  let token = tkn;
  const data = dta;
  const isTokenDeprecated = isSessionDeprecated();

  if (data.app_fee) {
    delete data.app_fee;
  }
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  StoreService.postOperational(data, token)
    .then(() => {
      showSnackbar(dispatch, types.MESSAGE_OK, 'success');
      return dispatch({
        type: types.POST_OPERATIONAL_INFO,
        data,
      });
    })
    .catch(() => {
      showSnackbar(dispatch, 'Error to post operational info');
    });
};

export const postAdmin = (dta, tkn) => async (dispatch) => {
  const data = dta;
  let token = tkn;
  const isTokenDeprecated = isSessionDeprecated();

  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  StoreService.postAdmin(data, token)
    .then(() => {
      showSnackbar(dispatch, types.MESSAGE_OK, 'success');
      return dispatch({
        type: types.POST_ADMIN,
        data,
      });
    })
    .catch(() => {
      showSnackbar(dispatch, 'Error to post admin info');
    });
};

export const postOlo = (data, tkn) => async (dispatch) => {
  let token = tkn;
  const isTokenDeprecated = isSessionDeprecated();

  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  StoreService.postOlo(data, token)
    .then(() => {
      showSnackbar(dispatch, types.MESSAGE_OK, 'success');
      return dispatch({
        type: types.POST_ONLINE_ORDERING,
        data,
      });
    })
    .catch(() => {
      showSnackbar(dispatch, 'Error to post olo');
    });
};

export const updateTerms = (date, tkn) => async (dispatch) => {
  let token = tkn;
  const isTokenDeprecated = isSessionDeprecated();

  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  StoreService.updateTerms(date, token)
    .then(() => {
      showSnackbar(dispatch, types.MESSAGE_OK, 'success');
      return dispatch({
        type: types.UPDATE_TERMS,
        data: date,
      });
    })
    .catch(() => {
      showSnackbar(dispatch, 'Error in update terms');
    });
};

export const updateAdminStore = (dta, tkn) => async (dispatch) => {
  let token = tkn;
  const data = dta;
  const isTokenDeprecated = isSessionDeprecated();

  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  StoreService.updateAdminStore(data, token)
    .then(() => {
      showSnackbar(dispatch, types.MESSAGE_OK, 'success');
      window.location.href = '/overview';
    })
    .catch(() => {
      showSnackbar(dispatch, 'Error in update Admin store');
    });
};

export const setNotifications = (data) => async (dispatch) =>
  dispatch({
    type: types.GET_NOTIFICATIONS,
    data,
  });

export const addNotification = (data) => async (dispatch) =>
  dispatch({
    type: types.ADD_NOTIFICATION,
    data,
  });

export const subscriberToTopic =
  (session, storeId, deviceToken, topic = 'topic_storeadmin') =>
  async (dispatch) => {
    let token = session;
    const storeTopic = `${topic}_${storeId}`;

    const isTokenDeprecated = isSessionDeprecated();

    if (isTokenDeprecated) {
      token = await getNewToken(dispatch);
    }

    StoreService.subscriberToTopic(token, deviceToken, storeTopic)
      .then(() => {
        showSnackbar(dispatch, 'Subscribed to notifications', 'success');
      })
      .catch(() => {
        showSnackbar(dispatch, 'Error in subscribed to notifications');
      });
  };

export const unSubscriberToTopic =
  (tkn, storeId, deviceToken, topic = 'topic_storeadmin') =>
  async (dispatch) => {
    const storeTopic = `${topic}_${storeId}`;

    const isTokenDeprecated = isSessionDeprecated();
    let token = tkn;

    if (isTokenDeprecated) {
      token = await getNewToken(dispatch);
    }

    return StoreService.unSubscriberToTopic(token, deviceToken, storeTopic).then(() => {
      console.info('Unsubscribed to notifications');
    });
  };

export const getTimeTable = (tkn) => async (dispatch) => {
  let token = tkn;
  const isTokenDeprecated = isSessionDeprecated();

  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  StoreService.getTimeTable(token)
    .then(({ data }) => {
      dispatch({
        type: types.GET_TIMETABLE,
        data,
      });
    })
    .catch(() => {
      showSnackbar(dispatch, 'Error on recover timetable.');
    });
};

export const getSpecialTimeTable = (tkn) => async (dispatch) => {
  let token = tkn;
  const isTokenDeprecated = isSessionDeprecated();

  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  StoreService.getSpecialTimeTable(token)
    .then(({ data }) => {
      dispatch({
        type: types.GET_TIMETABLE,
        data,
        special: true,
      });
    })
    .catch(() => {
      showSnackbar(dispatch, 'Error on recover timetable.');
    });
};

export const updateTimetable =
  (data, tkn, special = false) =>
  async (dispatch) => {
    const isTokenDeprecated = isSessionDeprecated();
    let token = tkn;

    if (isTokenDeprecated) {
      token = await getNewToken(dispatch);
    }

    return StoreService.updateTimetable(data, token, special);
  };

export const onPausedStore = (body, tkn) => async (dispatch) => {
  let token = tkn;
  const isTokenDeprecated = isSessionDeprecated();

  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  return StoreService.onPausedStore(body, token).then(() => {
    dispatch({
      type: types.SET_STATUS_STORE,
      data: body,
    });
  });
};

export const onStatusStore = (tkn) => async (dispatch) => {
  let token = tkn;
  const isTokenDeprecated = isSessionDeprecated();
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  StoreService.onStatusStore(token).then(({ data }) => {
    dispatch({
      type: types.SET_STATUS_STORE,
      data,
    });
  });
};
