import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getRatings } from 'actions';
export default function useRatings(filter, token) {
  const ratings = useSelector(state => state.stadistic.ratings);
  const dispatch = useDispatch();
  useEffect(() => {
    let mounted = true;
    const fetchRatings = () => {
        if (mounted && token) {
          dispatch(getRatings(filter, token));
        }
    };

    fetchRatings();
    return () => {
      mounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return ratings;
};