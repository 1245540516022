import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getManagement } from 'actions';
export default function useManagementInfo(token) {
  const management_info = useSelector(state => state.stadistic.management_info);
  const dispatch = useDispatch();
  useEffect(() => {
    let mounted = true;
    const fetchManagementInfo = () => {
        if (mounted && token) {
          dispatch(getManagement(token));
        }
    };

    fetchManagementInfo();
    return () => {
      mounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return management_info;
};