import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getModifiersItem } from 'actions';

export default function useModifiersItem (itemId, token, watcher) {
  const modifiers = useSelector(state => state.modifiers.modifiersItem);
  const dispatch = useDispatch();

  useEffect(() => {
    let mounted = true;
    const fetchModifiers = () => {
        if (mounted && itemId) {
          dispatch(getModifiersItem(itemId, token));
        }
    };

    fetchModifiers();
    return () => {
      mounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId, watcher]);

  return modifiers;
};