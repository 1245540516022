import moment from 'moment';

export const printStatus = (status) => {
  switch (status) {
    case 0:
      return 'Needs Action';
    case 1:
      return 'At kitchen';
    case 2:
      return 'Ready';
    case 3:
      return 'Picked up';
    case 4:
      return 'Delivered';
    case 5:
      return 'Rejected';
    case 6:
      return 'Cancelled';
    case 7:
      return 'Cancelled by consumer';
    case 8:
      return 'Out for delivery';
    case 10:
      return 'SCHEDULED';
    default:
      return 'CANCELLED';
  }
};

export const isToday = (from, momentDate) => {
  const REFERENCE = moment.unix(from);
  const TODAY = REFERENCE.clone().startOf('day');
  return moment.unix(momentDate).isSame(TODAY, 'd');
};

export const downloadFile = ({ data, fileName, fileType = 'text/json' }) => {
  const blob = new Blob([data], {
    type: fileType,
  });
  // to trigger a download
  const a = document.createElement('a');
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};

export default {
  printStatus,
  isToday,
  downloadFile,
};
