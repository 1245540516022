import * as types from 'constants/ActionTypes';

const INITIAL_STATE = {
  orders: [],
  pastorders: null,
  payments: [],
  summaries: [],
  order: null,
};
const orders = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SESSION_LOGOUT: {
      return {
        ...state,
        orders: null,
        order: null,
      };
    }
    case types.GET_ORDERS: {
      return {
        ...state,
        orders: action.data,
      };
    }
    case types.GET_ORDER: {
      return {
        ...state,
        order: action.data,
      };
    }
    case types.GET_PAST_ORDERS: {
      return {
        ...state,
        pastorders: action.data,
      };
    }
    case types.DELETE_ORDERS: {
      return {
        ...state,
        orders: {
          ...state.orders,
          data: state.orders.data.filter((order) => order.id !== action.id),
        },
      };
    }
    case types.UPDATE_STATUS_ORDERS: {
      return {
        ...state,
        pastorders: {
          ...state.pastorders,
          data: state.pastorders.data.map((order) => {
            if (order.id === action.id) {
              return {
                ...order,
                status: action.status,
              };
            }
            return order;
          }),
        },
      };
    }
    case types.GET_PAYMENTS: {
      return {
        ...state,
        payments: action.data,
      };
    }
    case types.GET_SUMMARIES: {
      return {
        ...state,
        summaries: action.data,
      };
    }
    case types.GET_ORDER_ERROR: {
      return {
        ...state,
        order: null,
      };
    }

    default: {
      return state;
    }
  }
};

export default orders;
