import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Typography, Popover, Button } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import useStyles from './style';

const icons = {
  info: <InfoIcon />,
};

const PopoverCanceled = (props) => {
  const { orderId, orderCancelationInfo } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const hastCategory = orderCancelationInfo.category && orderCancelationInfo.category.length > 0;

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <>
      <Button
        role="button"
        aria-describedby={id}
        className={classes.iconAction}
        onClick={handleClick}>
        {icons.info}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        className={classes.hover}>
        <Typography variant="h5" className={classes.typography}>
          Order #{' '}
          <Typography className={classes.data} variant="body1">
            {orderId}
          </Typography>
        </Typography>
        <Typography variant="h5" className={classes.typography}>
          Cancelled on{' '}
          <Typography className={classes.data} variant="body1">
            {moment(orderCancelationInfo.date).format('YYYY/MM/DD | HH:mm')}
          </Typography>
        </Typography>
        {hastCategory && (
          <Typography variant="h5" className={classes.typography}>
            Reason:{' '}
            {hastCategory &&
              orderCancelationInfo.category.map((item) => (
                <Typography key={item} className={classes.data} variant="body1">
                  {item}
                </Typography>
              ))}
          </Typography>
        )}
        {orderCancelationInfo.reason && (
          <>
            <Typography variant="h5" className={classes.typography}>
              Comment:{' '}
              <Typography className={classes.data} variant="body1">
                {orderCancelationInfo.reason}
              </Typography>
            </Typography>
          </>
        )}
      </Popover>
    </>
  );
};

PopoverCanceled.propTypes = {
  orderId: PropTypes.string.isRequired,
  orderCancelationInfo: PropTypes.string.isRequired,
};

export default PopoverCanceled;
