import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getMenus } from 'actions';

export default function useMenus (query, token) {
  const menus = useSelector(state => state.menus.menus);
  const dispatch = useDispatch();
  const [fetchIndex, setFetchIndex] = useState(0);

  const refetch = () => setFetchIndex((prev) => prev + 1);

  useEffect(() => {
    let mounted = true;
    const fetchMenus = () => {
        if (mounted) {
          dispatch(getMenus(query, token));
        }
    };

    fetchMenus();
    return () => {
      mounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, fetchIndex]);

  return {
    menus,
    refetch,
  };
};