import * as actionTypes from 'constants/ActionTypes';

//const currentUser = null; //UserService.currentUserValue();
let loggedIn = false;
let currentUser = null;
/* if (currentUser) {
  loggedIn = true;
  user = currentUser;
} */

const initialState = {
  loggedIn,
  user: currentUser,
  token: null
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SESSION_LOGIN: {
      return {
        ...state,
        loggedIn: true,
        user: action.user
      };
    }
    case actionTypes.SESSION_SET_TOKEN: {
      return {
        ...state,
        token: action.token
      };
    }

    case actionTypes.SESSION_LOGOUT: {
      return {
        ...state,
        loggedIn: false,
        user: null,
        token: null
      };
    }
    case actionTypes.ERROR_LOGIN: {
      return {
        ...state,
        loggedIn: false,
        user: {
          role: 'GUEST'
        },
        errorLogin: true
      };
    }

    case actionTypes.POST_ADMIN: {
      const { name_op, phone } = action.data;
      return {
        ...state,
        user: {
          ...state.user,
          operator: {
            ...state.user.operator,
            name_op,
            phone,
          }
        }
      };
    }

    default: {
      return state;
    }
  }
};

export default sessionReducer;
