import React from 'react'
import moment from 'moment';
import {
  TableRow,
  TableCell,
} from '@material-ui/core';
import { printStatus } from 'utils/tools';

const ExternalInfo = props => {
  const { order } = props;
  const status = printStatus(order.status)
  const showPickedUp = status === "PICKED UP" ? true : false;
  const parseDate = date => moment(date).format('YYYY/MM/DD HH:MM');
  const showRating = order.rating > 0 ? true : false;
  return (
    <>
      <TableRow>
        <TableCell>Store Info</TableCell>
        <TableCell>{order.store.address.address_print}</TableCell>
      </TableRow>
      <TableRow selected>
        <TableCell>Order Date</TableCell>
        <TableCell>{parseDate(order.date)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Total Amount</TableCell>
        <TableCell>${order.total_price}</TableCell>
      </TableRow>
      <TableRow selected>
        <TableCell>Amount Before Taxes</TableCell>
        <TableCell>${order.total_price_bt}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Total Quantity</TableCell>
        <TableCell>{order.total_quantity}</TableCell>
      </TableRow>
      <TableRow selected>
        <TableCell>Status</TableCell>
        <TableCell>
          <div>{status}</div>
          {showPickedUp && <div>{parseDate(order.pickup_date)}</div>}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Rate & Review</TableCell>
        <TableCell>
          {!showRating && <div>Not rated yet</div>}
          {showRating && <>
            <div>{order.rating}</div>
            <div>{order.review}</div>
            <div>{parseDate(order.review_date)}</div>
          </>}
        </TableCell>
      </TableRow>
    </>
  )
}

export default ExternalInfo;