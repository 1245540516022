import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getMenu } from 'actions';

export default function useMenu (menuId, token) {
  const menu = useSelector(state => state.menus.menu);
  const [refetchIndex, setRefetchIndex] = useState(0);
  const dispatch = useDispatch();

  const refetch = () => setRefetchIndex((prev) => prev + 1);

  useEffect(() => {
    let mounted = true;
    const fetchMenu = () => {
        if (mounted) {
          dispatch(getMenu(menuId, token));
        }
    };

    fetchMenu();
    return () => {
      mounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuId, refetchIndex]);

  return { menu, refetch };
};