import * as types from 'constants/ActionTypes';
const INITIAL_STATE = {
  launched_city: false,
  verified: false,
  wasRegistered: false,
  emailVerified: false,
  okVerified: false,
  notifyCity: null,
  selectInfo: null,
  company: null,
  errorGetCompany: false,
  verifyCompany: false,
  hasHadRegistered: false,
};
const register = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SESSION_LOGOUT: {
      return {
        ...state,
        launched_city: false,
        verified: false,
        wasRegistered: false,
        emailVerified: false,
        okVerified: false,
        notifyCity: null,
        selectInfo: null,
        hasHadRegistered: false,
      };
    }
    case types.POST_REGISTER: {
      return {
        ...state,
        wasRegistered: true
      };
    }
    case types.VERIFY_ENABLED_CITY: {
      return {
        ...state,
        launched_city: action.launched_city,
        verified: true
      };
    }
    case types.VERIFY_EMAIL: {
      return {
        ...state,
        emailVerified: true,
        okVerified: action.verified
      };
    }
    case types.NOTIFY_CITY: {
      return {
        ...state,
        notifyCity: action.notify
      };
    }
    case types.GET_RESTO_TYPES: {
      return {
        ...state,
        selectInfo: action.data
      };
    }
    case types.GET_COMPANY: {
      const { data } = action;
      return {
        ...state,
        company: data,
        verifyCompany: true,
      };
    }
    case types.CLEAN_COMPANY: {
      return {
        ...state,
        company: null,
        errorGetCompany: false,
        verifyCompany: false,
        hasHadRegistered: false,
      };
    }
    case types.ERROR_GET_COMPANY: {
      const { error, hasHadRegistered } = action.data;
      return {
        ...state,
        errorGetCompany: error,
        verifyCompany: true,
        hasHadRegistered,
      };
    }

    default: {
      return state;
    }
  }
}

export default register;