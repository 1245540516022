import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getCompany, removeCompany } from 'actions';
export default function useCompany (companyid, params) {
  const company = useSelector(state => state.register.company);
  const dispatch = useDispatch();
  useEffect(() => {
    let mounted = true;
    const fetchCompany = () => {
        if (mounted && !company && params) {
          dispatch(getCompany(companyid, params));
        }
    };

    fetchCompany();
    return () => {
      mounted = false;
      dispatch(removeCompany());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return company;
};