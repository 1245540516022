/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import IframeLayout from './layouts/Iframe';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';
import DashboardDefaultView from './views/DashboardDefault';
import OverviewView from './views/Overview';

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/overview" />,
  },
  {
    path: '/external',
    component: IframeLayout,
    routes: [
      {
        path: '/external/hubspot',
        exact: true,
        component: lazy(() => import('views/OrderDetails')),
      },
      {
        path: '/external/user/:tab',
        exact: true,
        component: lazy(() => import('views/ExternalUser')),
      },
    ],
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('views/Login')),
      },
      {
        path: '/auth/register',
        exact: true,
        component: lazy(() => import('views/Register')),
      },
      {
        path: '/auth/registerh',
        exact: true,
        component: lazy(() => import('views/RegisterV2')),
      },
      {
        path: '/auth/register/success',
        exact: true,
        component: lazy(() => import('views/RegisterSuccess')),
      },
      {
        path: '/auth/validate/:email/:token',
        exact: true,
        component: lazy(() => import('views/ValidateRegister')),
      },
      {
        path: '/auth/recovery',
        exact: true,
        component: lazy(() => import('views/PasswordRecovery')),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Error401')),
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error404')),
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error500')),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      {
        path: '/overview',
        exact: true,
        component: OverviewView,
      },
      {
        path: '/management/customers',
        exact: true,
        component: lazy(() => import('views/CustomerManagementList')),
      },
      {
        path: '/operations/offers',
        exact: true,
        component: lazy(() => import('views/OfferManagementList')),
      },
      {
        path: '/operations/offers/create',
        exact: true,
        component: lazy(() => import('views/OfferCreate')),
      },
      {
        path: '/operations/offers/:tab/:id',
        exact: true,
        component: lazy(() => import('views/OfferCreate')),
      },
      {
        path: '/operations/templates',
        exact: true,
        component: lazy(() => import('views/TemplatesManagementList')),
      },
      {
        path: '/operations/templates/filter/:tab',
        exact: true,
        component: lazy(() => import('views/TemplatesManagementList')),
      },
      {
        path: '/operations/templates/create',
        exact: true,
        component: lazy(() => import('views/TemplateCreate')),
      },
      {
        path: '/operations/templates/:tab/:id',
        exact: true,
        component: lazy(() => import('views/TemplateCreate')),
      },
      {
        path: '/operations/orders/:tab',
        exact: true,
        component: lazy(() => import('views/OrderManagementList')),
      },
      {
        path: '/operations/order/:id([0-9]+)',
        exact: true,
        component: lazy(() => import('views/OrderManagementDetails')),
      },
      {
        path: '/operations/orders/filter/:tab',
        exact: true,
        component: lazy(() => import('views/OrderFilters')),
      },
      {
        path: '/operations/ps/:tab([a-z]+)',
        exact: true,
        component: lazy(() => import('views/PS')),
      },
      {
        path: '/social',
        exact: true,
        component: lazy(() => import('views/Social')),
      },
      {
        path: '/faqs',
        exact: true,
        component: lazy(() => import('views/Faqs')),
      },
      {
        path: '/contact-us',
        exact: true,
        component: lazy(() => import('views/ReportProblem')),
      },
      {
        path: '/partners-guide',
        exact: true,
        component: lazy(() => import('views/PartnersGuide')),
      },
      {
        path: '/samples',
        exact: true,
        component: lazy(() => import('views/Samples')),
      },
      {
        path: '/settings',
        exact: true,
        component: lazy(() => import('views/Settings')),
      },
      {
        path: '/settings/:tab',
        exact: true,
        component: lazy(() => import('views/Settings')),
      },
      {
        path: '/summary/:tab',
        exact: true,
        component: lazy(() => import('views/Summary')),
      },
      {
        path: '/menus',
        exact: true,
        component: lazy(() => import('views/Menus')),
      },
      {
        path: '/menus/:id/categories',
        exact: true,
        component: lazy(() => import('views/MenuCategories')),
      },
      {
        path: '/menu/items',
        exact: true,
        component: lazy(() => import('views/MenusItems')),
      },
      {
        path: '/menu/items/modifiers',
        exact: true,
        component: lazy(() => import('views/Modifiers')),
      },
      {
        path: '/menus/:menuId/categories/:categoryId/item/:itemId/modifiers',
        exact: true,
        component: lazy(() => import('views/ItemModifiers')),
      },
      {
        path: '/menus/:menuId/categories/:categoryId/item/:itemId/cross-selling',
        exact: true,
        component: lazy(() => import('views/ItemCrossSelling')),
      },
      {
        path: '/operations/lovvet-offer/:id',
        exact: true,
        component: lazy(() => import('views/LovvettOffer')),
      },
      {
        path: '/promotions',
        exact: true,
        component: lazy(() => import('views/Promotions')),
      },
      {
        path: '/dashboards/default',
        exact: true,
        component: DashboardDefaultView,
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
];

export default routes;
