import { makeStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  stars: {
    display: 'flex',
    alignItems: 'center'
  },
  rating: {
    marginRight: theme.spacing(2),
    fontWeight: theme.typography.fontWeightBold
  },
  wording: {
    display: 'flex',
    flex: 1,
  },
  starIcon: {
    fontSize: 18,
    height: 18,
    width: 18
  },
  starFilledIcon: {
    color: colors.amber[400]
  },
}));

export default useStyles;