import * as types from 'constants/ActionTypes';
import ModifierService from '../services/menu-modifiers';
import { showSnackbar } from './utils';

import { isSessionDeprecated, getNewToken } from './sessions';

// eslint-disable-next-line
export const getModifiersItem = (menuId, session) => async (dispatch) => {
  const isTokenDeprecated = isSessionDeprecated();
  let token = session;
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  try {
    const { data } = await ModifierService.getModifiersItem(menuId, token);
    return dispatch({
      type: types.GET_MODIFIERS_ITEM,
      data,
    });
  } catch (error) {
    showSnackbar(dispatch, 'Error on get modifiers to item');
  }
};

// eslint-disable-next-line
export const getModifier = (modifierId, session) => async (dispatch) => {
  const isTokenDeprecated = isSessionDeprecated();
  let token = session;
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  dispatch({
    type: types.GET_MODIFIER,
    data: null,
  });

  try {
    const { data } = await ModifierService.getModifier(modifierId, token);
    return dispatch({
      type: types.GET_MODIFIER,
      data,
    });
  } catch (error) {
    dispatch({
      type: types.GET_MODIFIER,
      data: null,
    });
    showSnackbar(dispatch, 'Error on get modifiers to item');
  }
};

// eslint-disable-next-line
export const getDefaultModifiers =
  (session, query = null) =>
  async (dispatch) => {
    const isTokenDeprecated = isSessionDeprecated();
    let token = session;
    if (isTokenDeprecated) {
      token = await getNewToken(dispatch);
    }
    const { data } = await ModifierService.getDefaultModifiers(token, query);
    return dispatch({
      type: types.GET_MODIFIERS,
      data,
    });
  };

// eslint-disable-next-line
export const updateModifierStock = (modifierId, optionId, until, session) => async (dispatch) => {
  const isTokenDeprecated = isSessionDeprecated();
  let token = session;
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }
  return ModifierService.setModifierStockStatus(modifierId, optionId, until, token);
};

export const selectModifier =
  (data = null) =>
  (dispatch) => {
    dispatch({
      type: types.SELECT_MODIFIER,
      data,
    });
  };

export const resetModifiersItem = () => (dispatch) => {
  dispatch({
    type: types.GET_MODIFIERS_ITEM,
    data: null,
  });
};

export const deleteModifier =
  (itemId, modifierId, global = false, session) =>
  async (dispatch) => {
    const isTokenDeprecated = isSessionDeprecated();
    let token = session;
    if (isTokenDeprecated) {
      token = await getNewToken(dispatch);
    }

    return ModifierService.deleteModifier(itemId, modifierId, global, token).then(() => {
      dispatch(selectModifier());
      dispatch({
        type: types.DELETE_MODIFIER_ITEM,
        modifierId,
      });
    });
  };

export const createModifier = (body, itemId, session) => async (dispatch) => {
  const isTokenDeprecated = isSessionDeprecated();
  let token = session;
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  return ModifierService.createModifier(body, itemId, token);
};

export const updateModifier = (body, modifierId, session) => async (dispatch) => {
  const isTokenDeprecated = isSessionDeprecated();
  let token = session;
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  return ModifierService.createModifier(body, modifierId, token);
};

export const addModifiersToItem = (itemId, modifierId, session) => async (dispatch) => {
  const isTokenDeprecated = isSessionDeprecated();
  let token = session;
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  return ModifierService.addModifiersToItem(itemId, modifierId, token);
};

export const sortModifiers = (body, entityId) => (dispatch) => {
  const orderList = body.map((item) => item.id);

  const data = {
    entityId,
    orderList,
  };

  dispatch({
    type: types.MODIFIERS.SET_ORDER_MODIFIER,
    payload: body,
  });

  return ModifierService.sortModifiers(data);
};
