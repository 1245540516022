import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import useStyle from './style';
import moment from 'moment';

const getHourMin = (date) => {
  return date.split(':');
};

const Hours = (props) => {
  const classes = useStyle();
  const { flag, time, removeHour, day, index } = props;
  const [hourFrom, minuteFrom] = getHourMin(time.from);
  const [hourTo, minuteTo] = getHourMin(time.to);
  const timeFrom = moment(new Date())
    .set('hour', hourFrom)
    .set('minute', minuteFrom);
  const timeTo = moment(new Date())
    .set('hour', hourTo)
    .set('minute', minuteTo);

  return (
    <Grid
      className={[flag, classes.hours]}
      container
      spacing={3}
    >
      <Grid
        item
        lg={3}
        md={3}
        sm={6}
        xs={12}
      />
      <Grid
        className={classes.hour}
        item
        lg={3}
        md={3}
        sm={6}
        xs={12}
      >
        <Typography className={classes.hourmin}>{moment(timeFrom).format('hh:mm A')}</Typography>
      </Grid>
      <Grid
        className={classes.hour}
        item
        lg={2}
        md={2}
        sm={6}
        xs={12}
      >
        <Typography className={classes.hourmin}>{moment(timeTo).format('hh:mm A')}</Typography>
      </Grid>
      <Grid
        item
        lg={2}
        md={2}
        sm={6}
        xs={12}
      >
        <IconButton
          color="default"
          onClick={() => removeHour(day, index)}
        >
          <Delete />
        </IconButton>
      </Grid>
    </Grid>
  );
};

Hours.propTypes = {
  flag: PropTypes.bool,
  day: PropTypes.string.isRequired,
  removeHour: PropTypes.func.isRequired,
  time: PropTypes.string.isRequired,
  index: PropTypes.any,
};

Hours.defaultProps = {
  flag: true,
  index: 0,
};

export default Hours;
