import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Button, Divider } from '@material-ui/core';
import useStyles from './style';

const DeleteConfirm = props => {
  const { item, onConfirm, onClose, description } = props;
  const classes = useStyles();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography
          component="h3"
          variant="overline"
        >
          {description}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} className={classes.actions}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => onConfirm(item)}
        >
          Yes
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={onClose}
        >
          NO
        </Button>

      </Grid>
    </Grid>
  );
};

DeleteConfirm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  description: PropTypes.string,
};

DeleteConfirm.defaultProps = {
  description: 'Are you sure to delete?',
};

export default DeleteConfirm;
