import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  stats: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexBasis: '50%',
    },
  },
  header: {
    maxWidth: '100%',
    width: 150,
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
      flexBasis: '100%',
    },
  },
  contentTitle: {
    maxWidth: '100%',
  },
  titleItem: {
    display: '-webkit-box',
    maxWidth: '100%',
    maxHeight: '35px',
    minHeight: '16px',
    margin: '0 auto',
    lineHeight: '1',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export default useStyles;
