/* eslint-disable camelcase, no-param-reassign, jsx-a11y/label-has-associated-control */
import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button, LinearProgress } from '@material-ui/core';
import AddPhotoIcon from '@material-ui/icons/AddPhotoAlternate';
import { useSnackbarV2 } from 'hooks';
import ReactCrop from 'react-image-crop';
import { useDispatch } from 'react-redux';
import useStyles from './style';
import 'react-image-crop/dist/ReactCrop.css';

const CropImage = (props) => {
  const {
    className,
    token,
    id,
    itemId,
    itemImage,
    previewCropped,
    minHeight,
    minWidth,
    onResult,
    refreshAfterComplete,
    postFile,
    template_id,
    offer_id,
    customBody,
    keyPreview,
  } = props;
  const classes = useStyles();

  const [percent, setPercent] = useState(0);
  const dispatch = useDispatch();
  const [showProgress, setShowProgress] = useState(false);
  const { showMessage } = useSnackbarV2(); // eslint-disable-line no-unused-vars
  const [upImg, setUpImg] = useState();
  const [crop, setCrop] = useState({
    unit: 'px',
    width: minWidth,
    aspect: 16 / 9,
  });
  const [previewUrl, setPreviewUrl] = useState();
  const [media, setMedia] = useState(null);

  const imgRef = useRef(null);
  const params = {};

  const uploadImage = () => {
    const formData = new FormData();
    setShowProgress(true);
    formData.append(itemId, id);

    formData.append(itemImage, media, 'lovvet_imagen.jpg');
    if (template_id) {
      formData.append('template_id', template_id);
      params.template_id = template_id;
    }

    if (offer_id) {
      formData.append('offer_id', offer_id);
      params.offer_id = offer_id;
    }

    if (customBody && customBody.length > 0) {
      customBody.forEach((body) => {
        formData.append(body.key, body.value);
      });
    }

    const config = {
      onUploadProgress: (progressEvent) => {
        const percentServer = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setPercent(percentServer);

        if (percentServer >= 100) {
          setTimeout(() => {
            setShowProgress(false);
          }, 500);
        }
      },
    };
    dispatch(postFile(id, formData, config, token, params))
      .then((response) => {
        showMessage('Uploaded successfully', 'success');
        if (onResult) {
          onResult(response.data);
        }
        if (refreshAfterComplete) {
          window.location.reload();
        }
      })
      .catch(() => {
        showMessage('Error upload file');
      });
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setMedia(e.target.files[0]);
    }
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const createCropPreview = async (image, cropPreview, fileName) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = cropPreview.width;
    canvas.height = cropPreview.height;

    /* canvas.width = Math.ceil(crop.width*scaleX);
    canvas.height = Math.ceil(crop.height*scaleY); */
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      cropPreview.x * scaleX,
      cropPreview.y * scaleY,
      cropPreview.width * scaleX,
      cropPreview.height * scaleY,
      0,
      0,
      cropPreview.width,
      cropPreview.height
    );

    /* crop.width*scaleX,
    crop.height*scaleY, */

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(previewUrl);
        setMedia(blob);
        setPreviewUrl(window.URL.createObjectURL(blob));
      }, 'image/jpeg');
    });
  };

  const makeClientCrop = async (cropImage) => {
    if (imgRef.current && cropImage.width && cropImage.height) {
      createCropPreview(imgRef.current, cropImage, 'newFile.jpeg');
    }
  };

  return (
    <div className={clsx(className, 'crop-image')}>
      <div className={classes.actions}>
        <input
          accept="image/*"
          className={classes.input}
          id={`contained-button-file-${keyPreview}`}
          onChange={onSelectFile}
          type="file"
        />
        <label htmlFor={`contained-button-file-${keyPreview}`}>
          <Button color="primary" component="span" variant="contained">
            <AddPhotoIcon className={classes.addPhotoIcon} />
            Select File
          </Button>
        </label>
        <Button color="secondary" disabled={!media} onClick={uploadImage} variant="contained">
          UPLOAD FILE
        </Button>
      </div>
      <div className={classes.previewContainer}>
        {showProgress && (
          <div className={classes.progress}>
            <LinearProgress value={percent} variant="determinate" />
          </div>
        )}
        <ReactCrop
          crop={crop}
          minHeight={minHeight}
          minWidth={minWidth}
          onChange={(c) => setCrop(c)}
          onComplete={makeClientCrop}
          onImageLoaded={onLoad}
          ruleOfThirds
          src={upImg}
        />
      </div>
      {previewCropped && previewUrl && (
        <div className={classes.previewCrop}>
          <img alt="Crop preview" src={previewUrl} />
        </div>
      )}
    </div>
  );
};

CropImage.propTypes = {
  className: PropTypes.string,
  previewCropped: PropTypes.bool,
  minHeight: PropTypes.number,
  minWidth: PropTypes.number,
  id: PropTypes.number.isRequired,
  itemId: PropTypes.string,
  itemImage: PropTypes.string,
  refreshAfterComplete: PropTypes.bool,
  onResult: PropTypes.func,
  postFile: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  template_id: PropTypes.string,
  offer_id: PropTypes.string,
  customBody: PropTypes.arrayOf(),
  keyPreview: PropTypes.string,
};

CropImage.defaultProps = {
  className: '',
  previewCropped: false,
  itemId: 'store_id',
  itemImage: 'cover_image',
  minHeight: 350,
  minWidth: 600,
  refreshAfterComplete: true,
  onResult: null,
  template_id: null,
  offer_id: null,
  customBody: null,
  keyPreview: '',
};

export default CropImage;
