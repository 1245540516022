import React from 'react';
import useStyles from './style';

const Action = (props) => {
  const classes = useStyles();

  const { active } = props;
  return (
    <button tabIndex={0} data-cypress="draggable-handle" className={classes.root}>
      <svg viewBox="0 0 20 20" width="12" className={classes.icon}>
        <path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"></path>
      </svg>
    </button>
  );
};

export default Action;
