import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Popover,
  CardHeader,
  CardActions,
  Divider,
  Button,
  colors,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CardContent,
} from '@material-ui/core';
import { updateAdminStore } from 'actions';
import { useSession } from 'hooks';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(() => ({
  root: {
    width: 350,
    maxWidth: '100%'
  },
  actions: {
    backgroundColor: colors.grey[50],
    justifyContent: 'center'
  }
}));

const SettingPopover = props => {
  const { roles, currentAccount, anchorEl, ...rest } = props;
  const current = currentAccount;
  const [account, setAccount] = useState(currentAccount)
  const classes = useStyles();
  const dispatch = useDispatch();
  const session = useSession();
  const { token } = session;

  const selectStoreAdmin = (event) => {
    setAccount(event.target.value);
  }

  const applyNewAdmin = () => {
    const data = {
      "currentAccount": current,
      "newAccount": account,
    }
    dispatch(updateAdminStore(data, token))
  }

  return (
    <Popover
      {...rest}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
    >
      <div className={classes.root}>
        <CardHeader title="Settings" />
        <Divider />
        <CardContent>
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <InputLabel id="select-setting-store">Store</InputLabel>
            <Select
              labelId="select-setting-store"
              id="select-setting-store-label"
              value={account}
              onChange={selectStoreAdmin}
              label="Store"
            >
              {roles && roles.length > 0 && roles.map(role => {
                return <MenuItem key={role.store} value={role.store}>{role.store_name}</MenuItem>
              })}
            </Select>
          </FormControl>
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>
          <Button onClick={applyNewAdmin} fullWidth variant="contained" color="primary">
            Save
          </Button>
        </CardActions>
      </div>
    </Popover>
  );
};

SettingPopover.propTypes = {
  anchorEl: PropTypes.any,
  className: PropTypes.string,
  roles: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

SettingPopover.defaultProps = {
  roles: [],
}

export default SettingPopover;
