import * as types from 'constants/ActionTypes';
import MenuCategoriesService from '../services/menu-categories';
import { showSnackbar } from './utils';

import { isSessionDeprecated, getNewToken } from './sessions';

// eslint-disable-next-line
export const getCategories = (query, id, session) => async (dispatch) => {
  const isTokenDeprecated = isSessionDeprecated();
  let token = session;
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  try {
    const { data } = await MenuCategoriesService.getCategories(query, id, token);
    const { limit, offset, total, category_list: categoryList } = data;
    return dispatch({
      type: types.GET_MENU_CATEGORIES,
      data: {
        limit,
        offset,
        total,
        data: categoryList,
      },
    });
  } catch (err) {
    // eslint-disable-line
    // example to destructure data error
    /* if (error.response) {
      const { data, status } = error.response;
    } */
    showSnackbar(dispatch, 'Error on get menu categories');
  }
};

export const resetCategories = () => (dispatch) => {
  dispatch({
    type: types.GET_MENU_CATEGORIES,
    data: null,
  });
};

export const createCategory = (body, id, session) => async (dispatch) => {
  const isTokenDeprecated = isSessionDeprecated();
  let token = session;
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  return MenuCategoriesService.createCategory(body, id, token);
};

export const updateCategory = (body, id, idCategory, session) => async (dispatch) => {
  const isTokenDeprecated = isSessionDeprecated();
  let token = session;
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  return MenuCategoriesService.updateCategory(body, id, idCategory, token);
};

export const updateCategories =
  (data, editing = false) =>
  (dispatch) => {
    dispatch({
      type: types.UPDATE_CATEGORIES,
      data,
      editing,
    });
    if (editing) {
      dispatch({
        type: types.SELECT_CATEGORY,
        data,
      });
    }
  };

export const selectCategory =
  (data = null) =>
  (dispatch) => {
    dispatch({
      type: types.SELECT_CATEGORY,
      data,
    });
  };

// eslint-disable-next-line
export const getItemPrices = (itemId, session) => async (dispatch) => {
  const isTokenDeprecated = isSessionDeprecated();
  let token = session;
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  try {
    const { data } = await MenuCategoriesService.getItemPrices(itemId, token);
    return dispatch({
      type: types.GET_ITEM_PRICES,
      data,
    });
  } catch (error) {
    // showSnackbar(dispatch, "Error on get item prices");
    dispatch({
      type: types.GET_ITEM_PRICES,
      data: null,
    });
  }
};

export const cleanItemPrices = () => (dispatch) => {
  dispatch({
    type: types.GET_ITEM_PRICES,
    data: null,
  });
};

// eslint-disable-next-line
export const getItemFullfillment = (itemId, session) => async (dispatch) => {
  const isTokenDeprecated = isSessionDeprecated();
  let token = session;
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  dispatch({
    type: types.GET_ITEM_FULLFILLMENT,
    data: null,
  });

  try {
    const { data } = await MenuCategoriesService.getItemFullfillment(itemId, token);
    return dispatch({
      type: types.GET_ITEM_FULLFILLMENT,
      data,
    });
  } catch (error) {
    dispatch({
      type: types.GET_ITEM_FULLFILLMENT_ERROR,
    });
  }
};

// eslint-disable-next-line
export const getItemLovvett = (itemId, session) => async (dispatch) => {
  const isTokenDeprecated = isSessionDeprecated();
  let token = session;
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  dispatch({
    type: types.GET_ITEM_LOVVETT,
    data: null,
  });

  try {
    const { data } = await MenuCategoriesService.getItemLovvett(itemId, token);
    return dispatch({
      type: types.GET_ITEM_LOVVETT,
      data,
    });
  } catch (error) {
    dispatch({
      type: types.GET_ITEM_LOVVETT_ERROR,
    });
  }
};

// eslint-disable-next-line
export const getItemNote = (itemId, session) => async (dispatch) => {
  const isTokenDeprecated = isSessionDeprecated();
  let token = session;
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  dispatch({
    type: types.GET_ITEM_NOTE,
    data: null,
  });

  try {
    const { data } = await MenuCategoriesService.getItemNote(itemId, token);
    return dispatch({
      type: types.GET_ITEM_NOTE,
      data,
    });
  } catch (error) {
    dispatch({
      type: types.GET_ITEM_NOTE_ERROR,
    });
  }
};

export const updateLovvet = (body, itemId, session) => async (dispatch) => {
  const isTokenDeprecated = isSessionDeprecated();
  let token = session;
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  return MenuCategoriesService.updateLovvet(body, itemId, token);
};

export const updatePrice = (body, itemId, session) => async (dispatch) => {
  const isTokenDeprecated = isSessionDeprecated();
  let token = session;
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  return MenuCategoriesService.updatePrice(body, itemId, token);
};

export const updateFulfillment = (body, itemId, session) => async (dispatch) => {
  const isTokenDeprecated = isSessionDeprecated();
  let token = session;
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  return MenuCategoriesService.updateFulfillment(body, itemId, token);
};
export const updateItemNote = (body, itemId, session) => async (dispatch) => {
  const isTokenDeprecated = isSessionDeprecated();
  let token = session;
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  return MenuCategoriesService.updateItemNote(body, itemId, token);
};

export const getAllItems =
  (query = null) =>
  (dispatch) => {
    MenuCategoriesService.getAllItems(query).then(({ data }) => {
      dispatch({
        type: types.GET_ALL_ITEMS,
        payload: data,
      });
    });
  };

export const addItemsMenu = (menuId, categoryId, itemId, session) => async (dispatch) => {
  const isTokenDeprecated = isSessionDeprecated();
  let token = session;
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  return MenuCategoriesService.addItemsMenu(menuId, categoryId, itemId, token);
};

export const orderCategory = (body, menuId) => (dispatch) => {
  const orderList = body.map((item) => item.id);
  const data = {
    entityId: menuId,
    orderList,
  };

  dispatch({
    type: types.MENU.SET_ORDER_CATEGORY,
    payload: body,
  });

  return MenuCategoriesService.orderCategory(menuId, data);
};
