/* eslint-disable no-unused-vars */
import React, { useState, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Badge,
  Button,
  IconButton,
  Typography,
  Toolbar,
  Hidden,
  makeStyles,
} from '@material-ui/core';

import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import MenuIcon from '@material-ui/icons/Menu';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { PricingModal, NotificationsPopover, SettingPopover } from 'components';
import { getStoreStatus } from 'selectors/store';
import { useSelector } from 'react-redux';
import StorePausedPopover from '../StorePaused';
import useStyles from './style';

const TopBar = (props) => {
  const { onOpenNavBarMobile, notifications, roles, currentAccount, className, handleLogout } =
    props;
  const notificationsRef = useRef(null);
  const statusStoreRef = useRef(null);
  const settingRef = useRef(null);
  const classes = useStyles();
  const [pricingModalOpen, setPricingModalOpen] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [openStatusStore, setOpenStatusStore] = useState(false);
  const [openSetting, setOpenSetting] = useState(false);
  const storeStatus = useSelector(getStoreStatus);

  const handlePricingClose = () => {
    setPricingModalOpen(false);
  };

  const handleNotificationsOpen = () => {
    setOpenNotifications(true);
  };

  const handleNotificationsClose = () => {
    setOpenNotifications(false);
  };

  const handleSettingOpen = () => {
    setOpenSetting(true);
  };

  const handleSettingClose = () => {
    setOpenSetting(false);
  };

  const handleStatusStoreOpen = () => {
    setOpenStatusStore(true);
  };

  const handleStatusStoreClose = () => {
    setOpenStatusStore(false);
  };

  return (
    <AppBar className={clsx(classes.root, className)} color="inherit">
      <Toolbar>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onOpenNavBarMobile}>
            <MenuIcon />
          </IconButton>
        </Hidden>
        <RouterLink to="/">
          <img alt="Logo" src="/images/logo.png" />
        </RouterLink>
        <div className={classes.flexGrow} />
        <Typography variant="h4" className={classes.support}>
          Support: 305 915-4583
        </Typography>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          {storeStatus && storeStatus.pause ? (
            <Button
              className={classes.delete}
              onClick={handleStatusStoreOpen}
              ref={statusStoreRef}
              variant="outlined">
              STORE PAUSED
            </Button>
          ) : (
            <Button
              className={classes.logoutButton}
              color="inherit"
              onClick={handleStatusStoreOpen}
              ref={statusStoreRef}>
              Pause Store
            </Button>
          )}
          <IconButton
            className={classes.notificationsButton}
            color="inherit"
            onClick={handleNotificationsOpen}
            ref={notificationsRef}>
            <Badge
              badgeContent={notifications.length}
              classes={{ badge: classes.notificationsBadge }}
              variant="dot">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          {roles && roles.length > 1 && (
            <IconButton
              className={classes.notificationsButton}
              color="inherit"
              onClick={handleSettingOpen}
              ref={settingRef}>
              <SettingsOutlinedIcon />
            </IconButton>
          )}
          <Button className={classes.logoutButton} color="inherit" onClick={handleLogout}>
            <InputIcon className={classes.logoutIcon} />
            Sign out
          </Button>
        </Hidden>
      </Toolbar>
      <PricingModal onClose={handlePricingClose} open={pricingModalOpen} />
      <NotificationsPopover
        anchorEl={notificationsRef.current}
        notifications={notifications}
        onClose={handleNotificationsClose}
        open={openNotifications}
      />
      <StorePausedPopover
        anchorEl={notificationsRef.current}
        notifications={notifications}
        onClose={handleStatusStoreClose}
        open={openStatusStore}
      />
      {currentAccount && roles && roles.length > 1 && (
        <SettingPopover
          anchorEl={settingRef.current}
          currentAccount={currentAccount}
          onClose={handleSettingClose}
          open={openSetting}
          roles={roles}
        />
      )}
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string.isRequired,
  onOpenNavBarMobile: PropTypes.func.isRequired,
  roles: PropTypes.string.isRequired,
  notifications: PropTypes.string.isRequired,
  currentAccount: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
};

export default TopBar;
