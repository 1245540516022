import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getBilling } from 'actions';

export default function useBilling(account, token) {
  const billing = useSelector((state) => state.store.billing);
  const dispatch = useDispatch();
  useEffect(() => {
    let mounted = true;
    const fetchBilling = () => {
      if (mounted && !billing && token) {
        dispatch(getBilling(account, token));
      }
    };

    fetchBilling();
    return () => {
      mounted = false;
    };
  }, []);

  return billing;
}
