import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getCategories, resetCategories } from 'actions';

export default function useMenuCategories (query, id, token) {
  const menuCategories = useSelector(state => state.menus.categories);
  const dispatch = useDispatch();

  const onResetCategories = () => dispatch(resetCategories());

  useEffect(() => {
    let mounted = true;
    const fetchCategories = () => {
        if (mounted) {
          dispatch(getCategories(query, id, token));
        }
    };

    fetchCategories();
    return () => {
      mounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return {
    categories: menuCategories,
    onResetCategories,
  };
};