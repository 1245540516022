import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Link,
  Typography,
} from '@material-ui/core';
import useStyles from './style';
import StarIcon from '@material-ui/icons/Star';

const ReviewCard = props => {
  const { review, onClickShow, className, ...rest } = props;
  const classes = useStyles();

  const showDetail = (e, id) => {
    e.preventDefault();
    onClickShow(id)
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        className={classes.header}
        disableTypography
        subheader={
          <div className={classes.subheader}>
            <div className={classes.stars}>
              <Typography
                className={classes.rating}
                variant="h6"
              >
                {review.rating}
              </Typography>
              <StarIcon
                className={clsx(classes.starIcon, classes.starFilledIcon)}
              />
            </div>
            <Typography variant="body2">
              | Reviewed {moment(review.date).fromNow()} ({moment(review.date).format('MMM DD hh:mm A')})
            </Typography>
          </div>
        }
        title={
          <Link
            color="textPrimary"
            component="a"
            href="#"
            onClick={(e) => showDetail(e, review.order_id)}
            variant="h5"
            title="Show detail"
          >
            {review.description}
          </Link>
        }
      />
      <CardContent className={classes.content}>
        <div className={classes.message}>
      <Typography variant="subtitle2">{review.review}</Typography>
        </div>
        <Divider />
        <div className={classes.details}>
          <Grid
            alignItems="center"
            container
            justify="flex-start"
            spacing={3}
          >
            <Grid item>
              <Typography variant="h5">
                ${review.total_price_bt}
              </Typography>
              <Typography variant="body2">Order price</Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5">
                {review.total_quantity}
              </Typography>
              <Typography variant="body2">Portions</Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5">
                {review.category}
              </Typography>
              <Typography variant="body2">Rating category</Typography>
            </Grid>

          </Grid>
        </div>
      </CardContent>
    </Card>
  );
};

ReviewCard.propTypes = {
  className: PropTypes.string,
  review: PropTypes.object.isRequired,
  onClickShow: PropTypes.func,
};

export default ReviewCard;
