import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { Card, CardHeader, CardContent, Divider } from '@material-ui/core';

import { useManagementSales, useSession } from 'hooks';
import { Chart } from './components';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 700,
  },
  chart: {
    padding: theme.spacing(4, 2, 0, 2),
    height: 400,
  },
}));

const FinancialStats = (props) => {
  const { className } = props;
  const session = useSession();
  const { token } = session;
  const staticsSales = useManagementSales(token);
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)}>
      <CardHeader title="Sales Performance" />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            {staticsSales && <Chart className={classes.chart} data={staticsSales} />}
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

FinancialStats.propTypes = {
  className: PropTypes.string,
};

FinancialStats.defaultProps = {
  className: '',
};

export default FinancialStats;
