import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getManagementCustomers } from 'actions';
export default function useManagementCustomers(token) {
  const management_customers = useSelector(state => state.stadistic.management_customers);
  const dispatch = useDispatch();
  useEffect(() => {
    let mounted = true;
    const fetchManagementCustomers = () => {
        if (mounted && token) {
          dispatch(getManagementCustomers(token));
        }
    };

    fetchManagementCustomers();
    return () => {
      mounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return management_customers;
};