import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Typography, Grid } from '@material-ui/core';
import useStyles from './style';

const Header = (props) => {
  const { order, showTitle, className, isInternalCall } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <Grid alignItems="flex-end" container justify="space-between" spacing={3}>
        <Grid item className={!isInternalCall ? classes.external : ''}>
          {showTitle && (
            <Typography component="h2" gutterBottom variant="overline">
              Orders
            </Typography>
          )}{' '}
          <Typography component="h1" variant="h3">
            Order #{order.id} - {order.itemsOrdered.length} items
          </Typography>
          {!isInternalCall && (
            <Typography component="h1" variant="h3">
              {' - '}
              {order.store.name}
            </Typography>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  order: PropTypes.shape().isRequired,
  showTitle: PropTypes.bool.isRequired,
  isInternalCall: PropTypes.bool.isRequired,
};

Header.defaultProps = {
  className: '',
};

export default Header;
