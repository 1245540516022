import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Typography,
  Button,
  LinearProgress,
  FormControl,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Card,
  CardActions,
  CardContent,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { SnackBarPortal } from 'components';
import { useSnackbarPortal, useSession, useSnackbarV2 } from 'hooks';
import { cancelOrders } from 'actions';
import { useDispatch } from 'react-redux';
import useStyles from './style';

const FormConfirm = (props) => {
  const { description, orderId, onClose, refreshOrder } = props;
  const classes = useStyles();
  const { showMessage } = useSnackbarV2();
  const { token } = useSession();
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bodyCancel, setBodyCancel] = useState({
    date: moment().unix() * 1000,
    reason: '',
    category: [],
  });
  const isValid = bodyCancel.category.length > 0 || bodyCancel.reason.length > 5;
  const { isActive, message, severity } = useSnackbarPortal();
  const options = [
    'Customer requested cancellation',
    'Could Not Fulfill',
    'Too Busy',
    'Order Not Prepared (after confirmation)',
    'Store Closed',
    'Item Out of Stock',
    'Extreme driver / diner Wait Time',
    'Wrong Order Handed to Driver',
    'Merchant Unresponsive',
    'Duplicate Order',
    'Entire Order Not Placed by Staff',
  ];

  const handleChange = (values, nameField) => {
    setBodyCancel({
      ...bodyCancel,
      [nameField]: nameField === 'category' ? [values] : values,
    });
  };

  const handleCancel = () => {
    setLoading(true);
    dispatch(cancelOrders(orderId, bodyCancel, token))
      .then(() => {
        setSuccess(true);
        refreshOrder();
        setTimeout(() => {
          setSuccess(false);
          onClose();
        }, 4000);
      })
      .catch(() => {
        showMessage('Error on cancel order');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Card>
      <form>
        <CardContent>
          <div className={classes.root}>
            <Typography variant="h5">{description}</Typography>
          </div>
          <div className={classes.formControl}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="gender"
                onChange={(event) => handleChange(event.target.value, 'category')}
                name="category">
                {options.length > 0 &&
                  options.map((item) => (
                    <FormControlLabel value={item} control={<Radio />} label={item} />
                  ))}
              </RadioGroup>
            </FormControl>
          </div>
          <div className={classes.reason}>
            <TextField
              id="reason"
              label="Any additional comment?"
              placeholder="Let us know how we are doing"
              multiline
              variant="outlined"
              rows={3}
              fullWidth
              onChange={(event) => handleChange(event.target.value, 'reason')}
            />
          </div>
          {success && (
            <div className={classes.alert}>
              <Alert variant="filled" severity="success">
                ORDER {orderId} WAS CUCCESSFYLLY CANCELED
              </Alert>
            </div>
          )}
          {loading && <LinearProgress />}
        </CardContent>
        <CardActions>
          <Button
            disabled={loading || !isValid}
            onClick={() => handleCancel()}
            variant="contained"
            color="primary">
            CONFIRM
          </Button>
        </CardActions>
      </form>
      <SnackBarPortal isActive={isActive} severity={severity} message={message} />
    </Card>
  );
};

FormConfirm.propTypes = {
  onClose: PropTypes.func.isRequired,
  description: PropTypes.string,
  orderId: PropTypes.number.isRequired,
  refreshOrder: PropTypes.number.isRequired,
};

FormConfirm.defaultProps = {
  description: '',
};

export default FormConfirm;
