import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: 12,
    padding: 15,
    alignItems: 'center',
    justifyContent: 'center',
    flex: '0 0 auto',
    touchAction: 'none',
    cursor: 'grab',
    borderRadius: 5,
    border: 'none',
    outline: 'none',
    appearance: 'none',
    backgroundColor: 'transparent',
    marginRight: 10,
    '-webkit-tap-highlight-color': 'transparent',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      svg: {
        fill: '#6f7b88',
      }
    }
  },
  icon: {
    flex: '0 0 auto',
    margin: 'auto',
    height: '100%',
    overflow: 'visible',
    fill: '#919eab',
  }
}));
export default useStyles;