import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Radio,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Typography,
  IconButton,
  Button,
} from '@material-ui/core';
import { AddCircle, RemoveCircle } from '@material-ui/icons';
import moment from 'moment';
import clsx from 'clsx';
import useStyles from './style';
import Reasons from './Reasons';
import Message from './Message';

const Pause = (props) => {
  const { onPause, status, paused } = props;
  const classes = useStyles();
  const [selected, setSelected] = useState('hour');
  const [reason, setReason] = useState(null);
  const [times, setTimes] = useState({ day: 0, hour: 0, minute: 10, mess: 'INDEFINITELY' });
  const [untilTime, setUntilTime] = useState(moment().add(10, 'minutes'));

  const onSelectReason = (value) => {
    setReason(value);
  };

  const [value, setValue] = React.useState('specific_period');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const addTime = (isAdd = true) => {
    const counter = isAdd ? 1 : -1;
    if (times[selected] === 0 && !isAdd) {
      return;
    }
    setTimes((prev) => ({
      ...prev,
      [selected]: prev[selected] + counter,
    }));
  };

  useEffect(() => {
    setUntilTime(
      moment().add(times.day, 'days').add(times.hour, 'hours').add(times.minute, 'minutes')
    );
  }, [times]);

  let dys;
  let hrs;
  let mins;
  let timeToDisabled = -1;
  let secs = 0;
  if (paused && status.until > 0) {
    const limit = moment.unix(status.until / 1000);
    const now = moment();

    dys = moment(limit).diff(now, 'days');
    hrs = moment(limit).diff(now, 'hours') - dys * 24;
    mins = moment(limit).diff(now, 'minutes') - dys * 1440 - hrs * 60;
    secs = moment(limit).diff(now, 'seconds') - dys * 86400 - hrs * 3600 - mins * 60;
  }
  if (value === 'specific_period') {
    timeToDisabled = moment(untilTime).unix() * 1000;
  }

  const handlePaused = () => {
    const values = {
      pause: true,
      until: timeToDisabled,
      reason,
    };
    const cleanValues = {
      pause: false,
      until: null,
      reason: null,
    };
    onPause && onPause(paused ? cleanValues : values); // eslint-disable-line
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography className={classes.center} gutterBottom variant="h3">
          {paused ? 'Store Paused' : 'Pause Store'}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {paused && (
          <Typography className={classes.error} gutterBottom variant="subtitle1">
            Reason for pausing: {status.reason}
          </Typography>
        )}
        <Typography gutterBottom variant="subtitle1">
          {paused
            ? 'Your store is temporally paused and will resume in:'
            : 'Your store will be temporarily paused on Lovvett for the specified period.'}
        </Typography>
        {!paused && (
          <Grid>
            <div className={classes.field}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={value}
                  onChange={handleChange}>
                  <FormControlLabel value="indefinitely" control={<Radio />} label="Indefinitely" />
                  <FormControlLabel
                    value="specific_period"
                    control={<Radio />}
                    label="Specific period"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </Grid>
        )}
      </Grid>
      {paused && status.until > 0 && (
        <Grid item xs={12}>
          <div className={classes.time}>
            <div className={clsx(classes.hr, classes.showhr)}>
              <div className={clsx(classes.date)}>
                <span>{dys}</span>
                <span>:</span>
                <span>{hrs}</span>
                <span>:</span>
                <span>{mins}</span>
                <span>:</span>
                <span>{secs}</span>
              </div>
              <div className={classes.legend}>
                <Typography variant="body2">DAYS</Typography>
                <Typography variant="body2">HOURS</Typography>
                <Typography variant="body2">MINS</Typography>
                <Typography variant="body2">SECS</Typography>
              </div>
            </div>
          </div>
        </Grid>
      )}
      {paused && status.until < 0 && (
        <div className={clsx(classes.text)}>
          <span>{times.mess}</span>
        </div>
      )}
      {!paused && (
        <>
          {value !== 'indefinitely' && (
            <Grid item xs={12}>
              <div className={classes.counter}>
                <IconButton
                  aria-label="upload picture"
                  color="primary"
                  component="span"
                  onClick={() => addTime(false)}>
                  <RemoveCircle />
                </IconButton>
                <div className={classes.time}>
                  <div className={classes.hr}>
                    <div
                      role="button"
                      className={clsx(classes.date, [selected === 'day' && classes.selected])}
                      onClick={() => setSelected('day')}
                      tabIndex={0}
                      aria-hidden>
                      {times.day}
                    </div>
                    <Typography variant="body2">DAYS</Typography>
                  </div>
                  <div className={classes.date}>:</div>
                  <div className={classes.hr}>
                    <div
                      role="button"
                      className={clsx(classes.date, [selected === 'hour' && classes.selected])}
                      onClick={() => setSelected('hour')}
                      tabIndex={0}
                      aria-hidden>
                      {times.hour}
                    </div>
                    <Typography variant="body2">HOURS</Typography>
                  </div>
                  <div className={classes.date}>:</div>
                  <div className={classes.hr}>
                    <div
                      visible="false"
                      role="button"
                      className={clsx(classes.date, [selected === 'minute' && classes.selected])}
                      onClick={() => setSelected('minute')}
                      tabIndex={0}
                      aria-hidden>
                      {times.minute}
                    </div>
                    <Typography variant="body2">MINUTES</Typography>
                  </div>
                </div>
                <IconButton
                  aria-label="upload picture"
                  color="primary"
                  component="span"
                  onClick={() => addTime()}>
                  <AddCircle />
                </IconButton>
              </div>
            </Grid>
          )}
          <Grid item xs={12} />
          <Reasons onSelect={onSelectReason} />
          <Message time={untilTime} value={value} />
        </>
      )}

      <Grid className={classes.actions} item xs={12}>
        <Button
          color="primary"
          disabled={!paused && reason === null}
          onClick={handlePaused}
          size="medium"
          variant="contained">
          {paused ? 'Stop pause' : 'Save Changes'}
        </Button>
      </Grid>
    </Grid>
  );
};

Pause.propTypes = {
  onPause: PropTypes.func.isRequired,
  paused: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
};

export default Pause;
