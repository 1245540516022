import React, { Fragment, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import uuid from 'uuid/v1';
import { useDropzone } from 'react-dropzone';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  colors
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import bytesToSize from 'utils/bytesToSize';
import { addMediaItem } from 'actions';
import { useDispatch } from 'react-redux';
import { useSnackbarPortal, useSession } from 'hooks';
import { SnackBarPortal } from 'components';

const useStyles = makeStyles(theme => ({
  root: {},
  dropZone: {
    border: `1px dashed ${theme.palette.divider}`,
    padding: theme.spacing(1),
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: colors.grey[50],
      opacity: 0.5,
      cursor: 'pointer'
    }
  },
  dragActive: {
    backgroundColor: colors.grey[50],
    opacity: 0.5
  },
  image: {
    width: 50
  },
  info: {
    marginTop: theme.spacing(1)
  },
  list: {
    maxHeight: 320
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  }
}));

const FilesDropzone = props => {
  const { className, itemId, menuId, categoryId, ...rest } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const { token } = useSession();
  const { isActive, message, severity, openSnackBar } = useSnackbarPortal();
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);

  const handleDrop = useCallback(acceptedFiles => {
    setFiles(files => [...files].concat(acceptedFiles));
  }, []);

  const handleRemoveAll = () => {
    setFiles([]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    accept: 'image/jpeg, image/png',
    maxFiles: 5,
  });

  const onUpload = () => {
    if (files.length > 0) {
      const params = {
        itemId,
        menuId,
        categoryId,
      }
      let count = 0;
      setUploading(true);
      files.map(file => {
        const formData = new FormData();

        formData.append('image', file);
        dispatch(addMediaItem(formData, {}, params, token))
        .then(() => {
          count++;
          if (count === files.length) {
            openSnackBar('Files uploaded', 'success');
            handleRemoveAll();
          }
        })
        .catch(() => {
          count++;
          openSnackBar(`Error on add image ${file.name}`);
          if (count === files.length) {
            handleRemoveAll();
          }
        })
        .finally(() => {
          setUploading(false);
        });

      });
    }
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div
        className={clsx({
          [classes.dropZone]: true,
          [classes.dragActive]: isDragActive
        })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <div>
          <img
            alt="Select file"
            className={classes.image}
            src="/images/undraw_add_file2_gvbb.svg"
          />
        </div>
        <div>
          <Typography
            gutterBottom
            variant="h3"
          >
            Select files
          </Typography>
          <Typography
            className={classes.info}
            color="textSecondary"
            variant="body1"
          >
            Drop files here or click <Link underline="always">browse</Link>{' '}
            thorough your machine
          </Typography>
        </div>
      </div>
      {files.length > 0 && (
        <Fragment>
          <PerfectScrollbar options={{ suppressScrollX: true }}>
            <List className={classes.list}>
              {files.map((file, i) => (
                <ListItem
                  divider={i < files.length - 1}
                  key={uuid()}
                >
                  <ListItemIcon>
                    <FileCopyIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={file.name}
                    primaryTypographyProps={{ variant: 'h5' }}
                    secondary={bytesToSize(file.size)}
                  />
                  {/* <Tooltip title="More options">
                    <IconButton edge="end">
                      <MoreIcon />
                    </IconButton>
                  </Tooltip> */}
                </ListItem>
              ))}
            </List>
          </PerfectScrollbar>
          <div className={classes.actions}>
            <Button
              onClick={handleRemoveAll}
              size="small"
              disabled={uploading}
            >
              Remove all
            </Button>
            <Button
              color="secondary"
              size="small"
              variant="contained"
              onClick={onUpload}
              disabled={uploading}
            >
              {uploading ? 'Uploading...' : 'Upload files'}
            </Button>
          </div>
        </Fragment>
      )}
      <SnackBarPortal isActive={isActive} severity={severity} message={message} />
    </div>
  );
};

FilesDropzone.propTypes = {
  className: PropTypes.string,
  itemId: PropTypes.number.isRequired,
};

export default FilesDropzone;
