import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Typography, Link } from '@material-ui/core';
import useStyle from './styles';

const BoxResult = ({ label, value, link }) => {
  const classes = useStyle();

  return (
    <div className={classes.stats}>
      {label && <Typography variant="body2">{label}</Typography>}
      {!link && <Typography variant="h6">{value}</Typography>}
      {link && (
        <div className={classes.header}>
          <div className={classes.contentTitle}>
            <Link
              color="textPrimary"
              component={RouterLink}
              to={link}
              variant="h5"
              className={classes.titleItem}>
              {value}
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

BoxResult.propTypes = {
  label: PropTypes.string,
  link: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

BoxResult.defaultProps = {
  label: null,
  link: null,
};

export default BoxResult;
