import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getPayments } from 'actions';
export default function usePayments (token) {
  const payments = useSelector(state => state.orders.payments);
  const dispatch = useDispatch();
  useEffect(() => {
    let mounted = true;
    const fetchPayments = () => {
        if (mounted && token) {
          dispatch(getPayments(token));
        }
    };

    fetchPayments();
    return () => {
      mounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return payments;
};