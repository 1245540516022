import * as types from 'constants/ActionTypes';

const INITIAL_STATE = {
  menus: null,
  categories: null,
  categorySelected: null,
  menu: null,
  timetable: null,
};
const menus = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SESSION_LOGOUT: {
      return {
        ...INITIAL_STATE,
      };
    }
    case types.GET_MENUS: {
      const { data } = action;
      return {
        ...state,
        menus: data,
      };
    }
    case types.GET_MENU: {
      const { data } = action;
      return {
        ...state,
        menu: data,
      };
    }
    case types.GET_MENU_CATEGORIES: {
      const { data } = action;
      return {
        ...state,
        categories: data,
      };
    }

    case types.UPDATE_CATEGORIES: {
      const { data, editing } = action;
      let newCategories = [];

      if (editing) {
        newCategories = {
          ...state,
          data: state.categories.data.map((category) => {
            if (category.id === data.id) {
              return data;
            } else {
              return category;
            }
          }),
        };
      } else {
        newCategories = {
          ...state.categories,
          data: [...state.categories.data, data],
        };
      }

      return {
        ...state,
        categories: newCategories,
      };
    }

    case types.SELECT_CATEGORY: {
      const { data } = action;
      return {
        ...state,
        categorySelected: data,
      };
    }
    case types.ARCHIVE_MENU: {
      const { menuId, archive } = action;
      return {
        ...state,
        menus: {
          ...state.menus,
          data: state.menus.data.map((m) => {
            if (m.id === menuId) {
              return {
                ...m,
                archive: !archive,
              };
            }
            return m;
          }),
        },
      };
    }
    case types.GET_MENU_TIMETABLE: {
      const { data } = action;
      return {
        ...state,
        timetable: data,
      };
    }
    case types.MENU.SET_ORDER_CATEGORY: {
      const { payload } = action;
      const newCategories = {
        ...state.categories,
        data: payload,
      };

      return {
        ...state,
        categories: newCategories,
      };
    }

    default: {
      return state;
    }
  }
};

export default menus;
