import { applyMiddleware, createStore, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
// import { createLogger } from 'redux-logger'; // uncomment to show logs in logs
// const loggerMiddleware = createLogger();

import rootReducer from 'reducers';

const middlewares = [thunkMiddleware]; // loggerMiddleware
const middlewareEnhancer = composeWithDevTools(applyMiddleware(...middlewares));

const enhancers = [middlewareEnhancer];
const composedEnhancers = compose(...enhancers);

//const store = createStore(rootReducer, preloadedState, composedEnhancers);
const store = createStore(rootReducer, composedEnhancers);

export default store;
