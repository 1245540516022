/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import {
  DashboardOutlined,
  HomeOutlined,
  ListAlt,
  SettingsOutlined,
  PeopleOutlined,
  ContactSupportOutlined,
  Info,
  Menu,
  Publish,
} from '@material-ui/icons';

export default [
  {
    title: 'Menu',
    pages: [
      {
        title: 'Home',
        href: '/overview',
        icon: HomeOutlined,
      },
      {
        title: 'Operations',
        href: '/operations/offers',
        icon: ListAlt,
        children: [
          {
            title: 'Offers',
            href: '/operations/offers',
          },
          {
            title: 'Orders',
            href: '/operations/orders/today-yesterday',
          },
          {
            title: 'Past Orders',
            href: '/operations/orders/filter/sold',
          },
          {
            title: 'Plan & Save',
            href: '/operations/ps/today',
          },
        ],
      },
      {
        title: 'Dashboard',
        href: '/dashboards',
        icon: DashboardOutlined,
        children: [
          {
            title: 'Account Summary',
            href: '/summary/current-transactions',
          },
          {
            title: 'Management info',
            href: '/dashboards/default',
          },
          {
            title: 'Reviews',
            href: '/management/customers',
          },
        ],
      },
      {
        title: 'Settings',
        href: '/settings/general ',
        icon: SettingsOutlined,
        children: [
          {
            title: 'Business Info',
            href: '/settings/general',
          },
          {
            title: 'Media',
            href: '/settings/media',
          },
          {
            title: 'TimeTables',
            href: '/settings/timetables',
          },
          {
            title: 'Operational Info',
            href: '/settings/operational-info',
          },
          {
            title: 'Direct Ordering',
            href: '/settings/direct-ordering',
          },
          {
            title: 'Plan & Save',
            href: '/settings/plan-save',
          },
          {
            title: 'Billing Info',
            href: '/settings/billing',
          },
          {
            title: 'Notifications',
            href: '/settings/notifications',
          },
          {
            title: 'Pos Integrations',
            href: '/settings/integrations',
          },
          {
            title: 'Admin Info',
            href: '/settings/security',
          },
        ],
      },
      {
        title: 'Menu',
        href: '/menus',
        icon: Menu,
        children: [
          {
            title: 'Menus',
            href: '/menus',
          },
          {
            title: 'Items Stock',
            href: '/menu/items',
          },
          {
            title: 'Modifiers Stock',
            href: '/menu/items/modifiers',
          },
        ],
      },
      {
        title: 'Promotions',
        href: '/promotions',
        icon: Publish,
      },
      {
        title: 'Social',
        href: '/social',
        icon: PeopleOutlined,
      },
      {
        title: 'Support',
        href: '/getting-started',
        icon: ContactSupportOutlined,
        children: [
          {
            title: 'FAQs & Chat',
            href: '/faqs',
          },
          {
            title: 'Partners Guide',
            href: '/partners-guide',
          },
          {
            title: 'Samples',
            href: '/samples',
          },
          {
            title: 'Contact Us',
            href: '/contact-us',
          },
        ],
      },
      {
        title: 'About us',
        href: '/#',
        icon: Info,
        children: [
          {
            title: 'Terms & Conditions',
            href: 'https://www.lovvett.com/hubfs/Legal/LOVVETT_Terms-and-conditions_Stores.pdf',
            external: true,
          },
          {
            title: 'Privacy Policy',
            href: 'https://www.lovvett.com/hubfs/Legal/LOVVETT_Privacy-Policy_V1.pdf',
            external: true,
          },
        ],
      },
    ],
  },
];
