import * as types from 'constants/ActionTypes';

const INITIAL_STATE = {
  store: null,
  home: null,
  billing: null,
  is_opening_home: false,
  notifications: [],
  notifications_from_store: false,
  timetable: null,
  specialTimetable: null,
  status: null,
  operational: null,
  integrations: null,
  olo: null,
  plansave: null,
  plansaves: null,
};

const store = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SESSION_LOGOUT: {
      return {
        ...state,
        ...INITIAL_STATE,
      };
    }
    case types.GET_STORE: {
      return {
        ...state,
        store: action.data,
      };
    }
    case types.UPDATE_STORE: {
      return {
        ...state,
        store: {
          ...state.store,
          ...action.data,
        },
      };
    }
    case types.GET_HOME: {
      const h = action.data.store_home ? action.data.store_home : action.data;
      return {
        ...state,
        home: h,
        is_opening_home: false,
      };
    }
    case types.CLEAN_HOME: {
      return {
        ...state,
        home: null,
      };
    }
    case types.UPDATE_LOGO: {
      return {
        ...state,
        store: {
          ...state.store,
          avatar: action.urlImage,
        },
      };
    }
    case types.GET_BILLING: {
      return {
        ...state,
        billing: action.data,
      };
    }
    case types.POST_BILLING: {
      return {
        ...state,
        billing: action.data,
      };
    }
    case types.IS_OPENING_HOME: {
      return {
        ...state,
        is_opening_home: true,
      };
    }
    case types.GET_NOTIFICATIONS: {
      return {
        ...state,
        notifications: action.data,
      };
    }
    case types.GET_NOTIFICATIONS_EDIT: {
      return {
        ...state,
        notifications_from_store: action.data,
      };
    }
    case types.POST_NOTIFICATION: {
      return {
        ...state,
        notifications_from_store: action.data,
      };
    }
    case types.ADD_NOTIFICATION: {
      return {
        ...state,
        notifications: [action.data, ...state.notifications],
      };
    }
    case types.UPDATE_TERMS: {
      const { data } = action;
      return {
        ...state,
        store: {
          ...state.store,
          special_terms: {
            ...state.store.special_terms,
            accept_date: data,
          },
        },
      };
    }
    case types.GET_TIMETABLE: {
      const { data, special = false } = action;
      if (special) {
        return {
          ...state,
          specialTimetable: data,
        };
      }
      return {
        ...state,
        timetable: data,
      };
    }
    case types.SET_STATUS_STORE: {
      const { data } = action;
      return {
        ...state,
        status: data,
      };
    }
    case types.GET_OPERATIONAL_INFO: {
      const { data } = action;
      return {
        ...state,
        operational: data,
      };
    }
    case types.GET_ONLINE_ORDERING: {
      const { data } = action;
      return {
        ...state,
        olo: data,
      };
    }
    case types.POST_ONLINE_ORDERING: {
      const { data } = action;
      return {
        ...state,
        olo: data,
      };
    }
    case types.GET_INTEGRATIONS_INFO: {
      const { data } = action;
      return {
        ...state,
        integrations: data,
      };
    }
    case types.APP.GET_PLAN_SAVE: {
      return {
        ...state,
        plansave: action.payload,
      };
    }
    case types.APP.GET_PLANS_SAVES: {
      return {
        ...state,
        plansaves: action.payload,
      };
    }
    case types.APP.UPDATE_PLANS_SAVES: {
      const orderId = action.payload;
      return {
        ...state,
        plansaves: {
          ...state.plansaves,
          data: state.plansaves.data.map((ps) => {
            if (ps.id === orderId) {
              return {
                ...ps,
                status: 4,
              };
            }
            return ps;
          }),
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default store;
