import * as types from 'constants/ActionTypes';
import MenusService from '../services/menus';
import { showSnackbar } from './utils';

import { isSessionDeprecated, getNewToken } from './sessions';

// eslint-disable-next-line
export const getMenus = (query, session) => async (dispatch) => {
  let token = session;
  const isTokenDeprecated = isSessionDeprecated();
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  try {
    const { data } = await MenusService.getMenus(query, token);
    const {
      limit,
      offset,
      total,
      menu_list, // eslint-disable-line
    } = data;
    return dispatch({
      type: types.GET_MENUS,
      data: {
        limit,
        offset,
        total,
        data: menu_list,
      },
    });
  } catch (error) {
    // example to destructure data error
    /* if (error.response) {
      const { data, status } = error.response;
    } */
    showSnackbar(dispatch, 'Error on get menus');
  }
};

// eslint-disable-next-line
export const getMenu = (menuId, session) => async (dispatch) => {
  const isTokenDeprecated = isSessionDeprecated();
  let token = session;
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  try {
    const { data } = await MenusService.getMenu(menuId, token);
    return dispatch({
      type: types.GET_MENU,
      data,
    });
  } catch (error) {
    dispatch({
      type: types.GET_MENU,
      data: null,
    });
    showSnackbar(dispatch, 'Error on get menu');
  }
};

export const createMenu = (body, session) => async (dispatch) => {
  const isTokenDeprecated = isSessionDeprecated();
  let token = session;
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  return MenusService.createMenu(body, token);
};

export const updateMenu = (body, id, session) => async (dispatch) => {
  const isTokenDeprecated = isSessionDeprecated();
  let token = session;
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  return MenusService.updateMenu(body, id, token);
};

// eslint-disable-next-line
export const archiveMenu = (menuId, archive, session) => async (dispatch) => {
  const isTokenDeprecated = isSessionDeprecated();
  let token = session;
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  try {
    const { status } = await MenusService.archiveMenu(menuId, archive, token);
    if (status === 200) {
      showSnackbar(dispatch, 'Menu was edited.', 'success');
      dispatch({
        type: types.ARCHIVE_MENU,
        menuId,
        archive,
      });
    }
  } catch (err) {
    console.log(err);
    showSnackbar(dispatch, 'Error on edit menu.');
  }
};

// eslint-disable-next-line
export const getTimeTableMenu = (menuId) => async (dispatch) => {
  try {
    const { data } = await MenusService.getTimeTable(menuId);
    return dispatch({
      type: types.GET_MENU_TIMETABLE,
      data,
    });
  } catch (error) {
    dispatch({
      type: types.GET_MENU_TIMETABLE,
      data: null,
    });
    showSnackbar(dispatch, 'Error on get timetable menu'); // eslint-disable-line
  }
};

// eslint-disable-next-line
export const postTimeTableMenu = (body, menuId) => (dispatch) => {
  return MenusService.postTimeTable(body, menuId);
};

// eslint-disable-next-line
export const menuValidation = (key) => (dispatch) => {
  return MenusService.menuValidation(key);
};

// eslint-disable-next-line
export const chowlyMenuSync = () => (dispatch) => {
  return MenusService.chowlyMenuSync();
};

// eslint-disable-next-line
export const chowlyMenuSyncStatus = (taskId) => (dispatch) => {
  return MenusService.chowlyMenuSyncStatus(taskId);
};
