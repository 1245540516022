import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import AddPhotoIcon from '@material-ui/icons/AddPhotoAlternate';
import { useSnackbar } from 'hooks';
import { useDispatch } from 'react-redux';
import {
  Button,
  LinearProgress,
  colors
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'column'
  },
  removeBotton: {
    width: '100%'
  },
  progress: {
    width: '100%',
    marginBottom: '10px',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  changeButton: {
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    backgroundColor: colors.blueGrey[900],
    color: theme.palette.white,
    [theme.breakpoints.down('md')]: {
      top: theme.spacing(3),
      bottom: 'auto'
    },
    '&:hover': {
      backgroundColor: colors.blueGrey[900]
    }
  },
  addPhotoIcon: {
    marginRight: theme.spacing(1)
  },
}));
const UploadFile = (props) => {
  const {
    text,
    postFile,
    token,
    id,
    itemId,
    className,
    cover,
    itemImage,
    previewTmp,
    refreshAfterComplete,
    acceptFiles,
    onResult,
    template_id
  } = props;
  const classes = useStyles();
  const [percent, setPercent] = useState(0);
  const dispatch = useDispatch();
  const [showProgress, setShowProgress] = useState(false);
  const [snackbar, showError] = useSnackbar(); // eslint-disable-line no-unused-vars
  let params = {};
  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.forEach(file => { // eslint-disable-line no-unused-vars
      const formData = new FormData();
      const tmpUrl = URL.createObjectURL(file);
      setShowProgress(true);
      formData.append(itemId, id);
      formData.append(itemImage, file);
      if (template_id) {
        formData.append('template_id', template_id);
        params.template_id = template_id;
      }
      const config = {
        onUploadProgress: (progressEvent) => {
          const percentServer = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setPercent(percentServer);
          if (percentServer >= 100) {
            setTimeout(() => {
              setShowProgress(false);
            }, 500);
          }
        }
      };
      dispatch(postFile(id, formData, config, token, params))
      .then(response => {
        if (previewTmp) {
          previewTmp(tmpUrl);
        }
        if (onResult) {
          onResult(response.data);
        }
        if (refreshAfterComplete) {
          window.location.reload();
        }
      }).catch(() => {
        showError('Error upload file');
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    multiple: false,
    accept: acceptFiles,
  })

  return (
    <div className={clsx(classes.root, className)} {...getRootProps()}>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p>Drop the files here ...</p> :
          <>
            {showProgress && <div className={classes.progress}>
              <LinearProgress variant="determinate" value={percent} />
            </div>}
            {cover &&
              <Button
                className={classes.changeButton}
                variant="contained"
              >
                <AddPhotoIcon className={classes.addPhotoIcon} />
                {text}
              </Button>
            }
            {!cover && <Button
              className={classes.removeButton}
              variant="text"
              >
              {text}
            </Button>}
          </>
      }
    </div>
  )
}


UploadFile.propTypes = {
  className: PropTypes.string,
  onResult: PropTypes.func,
  acceptFiles: PropTypes.string,
  template_id: PropTypes.string,
};

UploadFile.defaultProps = {
  itemId: 'store_id',
  itemImage: 'store_image',
  className: '',
  cover: false,
  refreshAfterComplete: null,
  onResult: null,
  acceptFiles: 'image/jpeg, image/jpg, image/png',
  template_id: null,
}

export default UploadFile;