import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTimeTable, getSpecialTimeTable } from 'actions';

export default function useTimeTable(token) {
  const timetable = useSelector((state) => state.store.timetable);
  const specialTimetable = useSelector((state) => state.store.specialTimetable);
  const [refetchIndex, setRefetchIndex] = useState(0);
  const dispatch = useDispatch();

  const refetch = () => setRefetchIndex((prev) => prev + 1);

  useEffect(() => {
    const fetchTimeTable = () => {
      if (token) {
        dispatch(getTimeTable(token));
        dispatch(getSpecialTimeTable(token));
      }
    };

    fetchTimeTable();

    // eslint-disable-next-line
  }, [token, refetchIndex]);

  return { timetable, specialTimetable, refetch };
}
