import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableBody,
  CardActions,
  Button,
} from '@material-ui/core';
import { PortalModal } from 'components';
import useStyles from './style';
import { InternalInfo, ExternalInfo, FormConfirm } from './components';

const OrderInfo = (props) => {
  const { order, isInternalCall, className, refreshOrder } = props;
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);

  const showModal = (e) => {
    e.preventDefault();
    setOpenModal(true);
  };

  const onClose = () => {
    setOpenModal(false);
  };
  const title = `Need to cancel this order ${order.id}?`;
  const renderPortal = useMemo(
    () => (
      <PortalModal isOpen={openModal} onClose={onClose} title={title}>
        <FormConfirm
          onClose={onClose}
          orderId={order.id}
          description="Please tell us the main reasons for cancellation:"
          refreshOrder={refreshOrder}
        />
      </PortalModal>
    ),
    [openModal]
  );

  return (
    <Card className={clsx(classes.root, className)}>
      <CardHeader title="Order info" />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>
            {isInternalCall && <InternalInfo order={order} />}
            {!isInternalCall && <ExternalInfo order={order} />}
          </TableBody>
        </Table>
      </CardContent>
      {(order.status < 5 || order.status === 10) && (
        <CardActions className={classes.actions}>
          <Button color="primary" onClick={showModal}>
            Need to cancel this order?
          </Button>
        </CardActions>
      )}
      {renderPortal}
    </Card>
  );
};

OrderInfo.propTypes = {
  className: PropTypes.string,
  isInternalCall: PropTypes.bool,
  order: PropTypes.shape().isRequired,
  refreshOrder: PropTypes.func.isRequired,
};

OrderInfo.defaultProps = {
  className: '',
  isInternalCall: false,
};

export default OrderInfo;
