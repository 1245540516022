import BaseService from './baseService';

class StoreService extends BaseService {
  update(data, token) {
    const headers = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = '/v2/stores/profile/basic';
    return this.post(url, data, headers);
  }

  getStore(id, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
      // params here
    };
    const url = '/v2/stores/profile';

    return this.get(url, params);
  }

  getHome(token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
      // params here
    };
    const url = '/v2/stores/home';
    return this.get(url, params);
  }

  getBilling(id, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
      // params here
    };
    const url = `/v2/stores/profile/billing/${id}`;
    return this.get(url, params);
  }

  getOperational(token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
      // params here
    };
    const url = '/v2/stores/profile/operational_info';
    return this.get(url, params);
  }

  postOperational(data, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = '/v2/stores/profile/operational_info';
    return this.post(url, data, params);
  }

  getIntegrations(token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
      // params here
    };
    const url = '/v2/stores/profile/integrations_info';
    return this.get(url, params);
  }

  postIntegrations(data, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = '/v2/stores/profile/integrations_info';
    return this.post(url, data, params);
  }

  getNotifications(id, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
      // params here
    };
    const url = '/v2/stores/profile/notifications';
    return this.get(url, params);
  }

  postBilling(id, data, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = `/v2/stores/profile/billing/${id}`;
    return this.post(url, data, params);
  }

  getOlo(token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
      // params here
    };
    const url = '/v2/stores/profile/olo';

    return this.get(url, params);
  }

  postOlo(data, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = `/v2/stores/profile/olo`;
    return this.post(url, data, params);
  }

  postNotification(data, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = '/v2/stores/profile/notifications';
    return this.post(url, data, params);
  }

  postLogo(data, config, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
        'Content-Type': 'multipart/form-data',
      },
      ...config,
    };
    const url = '/v2/stores/profile/media';

    return this.post(url, data, params);
  }

  postCover(data, config, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
        'Content-Type': 'multipart/form-data',
      },
      ...config,
    };

    const url = '/v2/stores/profile/mediacover';
    return this.post(url, data, params);
  }

  postAdmin(data, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = '/v2/stores/operator/profile';
    return this.post(url, data, params);
  }

  updateTerms(date, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = `/v2/stores/profile/accept_conditions/${date}`;
    return this.post(url, {}, params);
  }

  updateAdminStore(data, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = '/v2/stores/operator/chacc';
    return this.post(url, data, params);
  }

  getTimeTable(token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = '/v2/stores/profile/timetables';
    return this.get(url, params);
  }

  getSpecialTimeTable(token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = '/v2/stores/profile/specialtimetables';
    return this.get(url, params);
  }

  updateTimetable(data, token, special) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
    };
    let url = '/v2/stores/profile/timetables';
    if (special) {
      url = '/v2/stores/profile/specialtimetables';
    }
    return this.post(url, data, params);
  }

  onPausedStore(data, token) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
    };
    const url = '/v2/stores/ordering_status';

    return this.post(url, data, params);
  }

  onStatusStore() {
    const url = '/v2/stores/ordering_status';
    return this.get(url);
  }

  subscriberToTopic(token, tokenDevice, topic) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
    };
    // eslint-disable-next-line
    const url = `/v2/stores/notifications/${tokenDevice}​/topic/${topic}/subscribe`;

    return this.post(url, {}, params);
  }

  unSubscriberToTopic(token, tokenDevice, topic) {
    const params = {
      headers: {
        'X-Access-Token': token,
      },
    };
    // eslint-disable-next-line
    const url = `/v2/stores/notifications/${tokenDevice}​/topic/${topic}/unsubscribe`;

    return this.post(url, {}, params);
  }

  onPlanAndSave() {
    const url = '/v2/stores/profile/plan_and_save';
    return this.get(url);
  }

  postPlanAndSave(data) {
    const url = '/v2/stores/profile/plan_and_save';
    return this.post(url, data);
  }
}

export default new StoreService();
