import axios from 'axios';
import { refreshToken, setTokenFirebase } from 'actions';
import store from '../store';

const restClient = axios.create({
  baseURL: process.env.REACT_APP_DOMAIN_NODE_ENV, // eslint-disable-line
  timeout: 10000,
});

const tt = /[0-9]*\/(timetables)$/i;
const menuItems = /(menueditor\/menu\/items)$/i;
const categoryOrder = /(category\/order)$/i;
const categoryItemsOrder = /(item\/order)$/i;
const modifierItemsOrder = /(modifiers\/order)$/i;
const integrationMenu = /(integrations\/chowly\/menu\/sync)/i;
const plan = /(plan_and_save)/i;
const psGet = /(ps)/i;

restClient.interceptors.request.use(
  async (request) => {
    if (
      request.url.includes('ordering_status') ||
      tt.test(request.url) ||
      menuItems.test(request.url) ||
      categoryOrder.test(request.url) ||
      modifierItemsOrder.test(request.url) ||
      categoryItemsOrder.test(request.url) ||
      integrationMenu.test(request.url) ||
      plan.test(request.url) ||
      psGet.test(request.url)
    ) {
      const { token } = store.getState().session;
      request.headers['X-Access-Token'] = token;
    }

    return request;
  },
  (err) => Promise.reject(err)
);

restClient.interceptors.response.use(
  async (response) => response,
  async (error) => {
    if (
      error &&
      error.response &&
      error.response.status &&
      error.response.status === 401 &&
      !error.config.retry
    ) {
      // eslint-disable-next-line
      error.config.retry = true;
      return refreshToken()
        .then((newToken) => {
          store.dispatch(setTokenFirebase(newToken));
          // eslint-disable-next-line
          error.config.headers['X-Access-Token'] = newToken;
          return restClient.request(error);
        })
        .catch((err) => {
          const tokenError = new Error('User authentication failed');
          tokenError.originalError = err;
          return Promise.reject(err);
        });
    }
    return Promise.reject(error);
  }
);

export default restClient;
