import SiteService from '../services/site';
import * as types from 'constants/ActionTypes';
import { showSnackbar } from './utils';
import {
  isSessionDeprecated,
  getNewToken,
} from './sessions';

export const verifyCity = (country, city) => {
  const getOnlyCity = city.split(',')[0];

  return SiteService.verifyCity(country, getOnlyCity);
}

export const postRegister = (data, params) => {
  delete data.passwordr;
  delete data.country;
  delete data.fullCountry;
  delete data.policy;
  return SiteService.register(data, params);
}

export const validateEmail = (email, token) => async dispatch => {
  const isTokenDeprecated = isSessionDeprecated();

  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  SiteService.validateEmail(email, token)
  .then((response) => {
    return dispatch({
      type: types.VERIFY_EMAIL,
      verified: true
    });
  })
  .catch(() => {
    return dispatch({
      type: types.VERIFY_EMAIL,
      verified: false
    });
  })
}

export const postNotifyCity = (data) => {
  const { fullCountry, address, email } = data;

  return SiteService.notifyCity(fullCountry, address, email);
}

export const getRestoTypes = () => dispatch => {
  SiteService.getRestoTypes()
  .then((response) => {
    return dispatch({
      type: types.GET_RESTO_TYPES,
      data: response.data.business_type
    });
  })
  .catch(() => {});
}

export const cleanNotify = () => dispatch => {
  dispatch({
    type: types.NOTIFY_CITY,
    notify: null
  });
}

export const getCompany = (companyid, query) => dispatch => {
  SiteService.getCompany(companyid, query)
  .then(response => {
    const { data } = response;

    /* let { data } = response;
    data = {
      ...data,
      address: '1483 berazategui, buenos aires, argentina.',//3501 120th Avenue, Kenosha, Wisconsin, EE. UU.  //1100 Hardee Rd, Coral Gables, FL 33146, EE. UU.  original: 1200 Brickell Bay Dr, Miami, FL 33131
      //zipcode: '33146'
    } */
    dispatch({
      type: types.GET_COMPANY,
      data
    });
  })
    .catch(err => {
      if (err.response && err.response.status === 409) {
        dispatch({
          type: types.ERROR_GET_COMPANY,
          data: {
            hasHadRegistered: true,
            error: false,
          }
        });
      } else {
        showSnackbar(dispatch, "Error in get COMPANY");
        dispatch({
          type: types.ERROR_GET_COMPANY,
          data: {
            hasHadRegistered: false,
            error: true,
          }
        });
      }
  });
}

export const removeCompany = () => dispatch => {
  dispatch({
    type: types.CLEAN_COMPANY
  });
}