import UserService from '../services/user';
import app from './user';
import { SESSION_LOGIN, SESSION_SET_TOKEN, SESSION_LOGOUT } from 'constants/ActionTypes';
import moment from 'moment';

const SESSION_INIT_TIME = 'sessionInitTime';
const LIMITE_TIME = 5;

export const signin = (token) => {
  return UserService.getSignIn(token);
}

export const setUser = (data) => dispatch => {
  dispatch({
    type: SESSION_LOGIN,
    user: data
  });
}

export const setSessionInitTimeDispatch = (isInit = true) => dispatch => {
  setSessionInitTime(isInit);
}

export const setSessionInitTime = (isInit = true) => {
  const ts = moment().unix();
  if (window.sessionStorage) {
    if (isInit && !window.sessionStorage.getItem(SESSION_INIT_TIME)) {
      window.sessionStorage.setItem(SESSION_INIT_TIME, ts);
    } else if (!isInit) {
      window.sessionStorage.setItem(SESSION_INIT_TIME, ts);
    }
  }
}

export const getSessionInitTime = () => {
  if (window.sessionStorage) {
    return window.sessionStorage.getItem(SESSION_INIT_TIME);
  }
  return null;
}

export const isSessionDeprecated = () => {
  const initTime = getSessionInitTime();
  const a = moment.unix(initTime);
  const b = moment();
  const mins = b.diff(a, 'm');

  if (mins > LIMITE_TIME) {
    return true;
  }
  return false;
}

export const signout = () => dispatch => {
  dispatch({
    type: SESSION_LOGOUT
  });
}

export const setTokenFirebase = (token) => dispatch =>
  dispatch({
    type: SESSION_SET_TOKEN,
    token
  });

export const login = async (email, password) => {
  try {
    return await app
      .auth()
      .signInWithEmailAndPassword(email, password);
  } catch (error) {
  }
}

export const refreshToken = () => {
  try {
    return app
      .auth()
      .currentUser
      .getIdToken(true);
  } catch (error) {
    console.log('error to refresh token');
  }
}

export const logout = async () => {
  try {
    return await app
      .auth()
      .signOut();
  } catch (error) {
    console.log('error in logout', error);
    return false;
  }

}

export const getNewToken = async (dispatch) => {
  setSessionInitTime(false);
  const newToken = await refreshToken();
  dispatch(setTokenFirebase(newToken));
  return newToken;
}

export const getOrdersByEmail = (email) => {
  return UserService.getOrdersByEmail(email);
}

export const getUserByEmail = (email) => {
  return UserService.getUserByEmail(email);
}