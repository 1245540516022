
import { BehaviorSubject } from 'rxjs';
import BaseService from './baseService';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

class UserService extends BaseService {
  loginAPI(data) {
    const url = `/v2/users/login`;
    return this.postLogin(url, data)
    .then(response => {
      localStorage.setItem('currentUser', JSON.stringify(response.data.data));
      currentUserSubject.next(response.data.data);
      return response;
    });
  }

  login(user) {
    localStorage.setItem('currentUser', JSON.stringify(user));
    currentUserSubject.next(user);
  }

  getSignIn(token) {
    const url = `/v2/stores/signin`;
    const data = null;
    const headers = {
      headers: {
        'X-Access-Token': token
      }
    }
    return this.post(url, data, headers);
  }

  logout() {
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
  }

  currentUser() {
    return currentUserSubject.asObservable()
  }

  currentUserValue () {
    return currentUserSubject.value;
  }

  getUserByEmail(email) {
    const url = `/v1/hubspot/app/${email}/info`;
    return this.get(url);
  }

  getOrdersByEmail(email) {
    const url = `/v1/hubspot/app/${email}/orders`;
    return this.get(url);
  }
}

export default new UserService();
