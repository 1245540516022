import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getOlo } from 'actions';

export default function useOnlineOrdering(token) {
  const olo = useSelector((state) => state.store.olo);
  const dispatch = useDispatch();

  useEffect(() => {
    let mounted = true;
    const fetchProfile = () => {
      if (mounted && !olo && token) {
        dispatch(getOlo(token));
      }
    };

    fetchProfile();
    return () => {
      mounted = false;
    };
  }, [token]);

  return olo;
}
