import * as types from 'constants/ActionTypes';
import parse from 'utils/parseData';
import OfferService from '../services/offers';
import TemplateService from '../services/templates';
import { showSnackbar } from './utils';
import { postTemplates } from './templates';
import { isSessionDeprecated, getNewToken } from './sessions';

export const postOffer = (body, session) => async (dispatch) => {
  let token = session;
  let data = body;
  const isTemplate = data.as_template;
  const templateId = data.template_id;
  const { origin } = data;
  delete data.when;
  delete data.whenpu;
  delete data.origin;
  delete data.whenpu_disabled;
  delete data.template_id;
  data.quantity_left = data.quantity;
  data = parse.addMilisecondsFields(data);
  if (data.as_template) {
    delete data.as_template;
    delete data.origin;
    data = {
      ...data,
      repeat_on_days: data.repeat_on_days,
      template_quantity: data.quantity,
    };
  }
  delete data.as_template;
  delete data.repeat_on_days;

  const isTokenDeprecated = isSessionDeprecated();

  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  if (isTemplate || origin === 'recurring') {
    const template = await dispatch(postTemplates(data, token, false));

    if (template.status === 200) {
      const { id, offer } = template.data;
      if (!offer.id) {
        const offerPost = await OfferService.save(data, token, { template_id: id });
        if (offerPost.status === 200) {
          const offerId = offerPost.data.id;
          if (isTemplate) {
            dispatch({
              type: types.SET_AS_TEMPLATE_ID,
              value: id,
            });
          }
          return new Promise((resolve) => {
            resolve({ data: { id: offerId } });
          });
        }
        return new Promise((reject) => {
          reject('error offer!');
        });
      }
      dispatch({
        type: types.SET_AS_TEMPLATE_ID,
        value: id,
      });
      return new Promise((resolve) => {
        resolve({ data: { id: offer.id } });
      });
    }
    return new Promise((reject) => {
      reject('error!');
    });
  }
  let tparams = {};
  if (origin === 'template') {
    tparams = {
      template_id: templateId,
    };
  }
  const offer = await OfferService.save(data, token, tparams);
  return offer;
};

export const editOffer = (data, session) => async (dispatch) => {
  const isTokenDeprecated = isSessionDeprecated();
  let token = session;
  let newData = data;
  delete newData.when;
  delete newData.whenpu;
  delete newData.whenpu_disabled;
  delete newData.offerSituation;
  delete newData.origin;
  delete newData.orderable;
  delete newData.template_id;
  newData.quantity_left = data.quantity;
  newData = parse.addMilisecondsFields(data);

  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  const offer = await OfferService.edit(newData, token);
  // newData = parse.lessMilisecondsFields(dat);

  return offer;
};

export const deleteOffer = (offerId, session) => async (dispatch) => {
  const isTokenDeprecated = isSessionDeprecated();
  let token = session;
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  OfferService.deleteOffer(offerId, token)
    .then(() =>
      dispatch({
        type: types.DELETE_OFFER,
        offerId,
      })
    )
    .catch(() =>
      dispatch({
        type: types.SET_ERROR,
        message: 'Error on delete offer',
      })
    );
};
export const changeStatus = (offerId, status, session) => async (dispatch) => {
  const isTokenDeprecated = isSessionDeprecated();
  let token = session;
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  OfferService.changeStatus(offerId, status, token)
    .then(() =>
      dispatch({
        type: types.DELETE_OFFER,
        offerId,
      })
    )
    .catch(() =>
      dispatch({
        type: types.SET_ERROR,
        message: 'Error on change status offer',
      })
    );
};

export const getOffers = (storeId, tokens, params) => async (dispatch, getState) => {
  const isTokenDeprecated = isSessionDeprecated();
  const { session } = getState();
  let { token } = session;
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  OfferService.getOffers(storeId, token, params)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: types.GET_OFFERS,
        data,
      });
    })
    .catch(() => {});
};

export const getOffer = (id, session) => async (dispatch) => {
  const isTokenDeprecated = isSessionDeprecated();
  let token = session;
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  OfferService.getOffer(id, token)
    .then((response) => {
      const data = parse.lessMilisecondsFields(response.data);
      return dispatch({
        type: types.GET_OFFER,
        data,
      });
    })
    .catch(() => {
      showSnackbar(dispatch, 'We have problems, please retry in a moment');
    });
};

export const getLovvettOffer = (id, session) => async (dispatch) => {
  const isTokenDeprecated = isSessionDeprecated();
  let token = session;
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  OfferService.getLovvettOffer(id, token)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: types.OFFER.GET_LOVVETT_OFFER,
        data,
      });
    })
    .catch(() => {
      showSnackbar(dispatch, 'We have problems, please retry in a moment');
    });
};

export const postMediaOffer = (id, data, config, session, params) => async (dispatch) => {
  const isTokenDeprecated = isSessionDeprecated();
  let token = session;
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  if (params && params.template_id) {
    const { template_id: templateId } = params;
    TemplateService.postMedia(templateId, data, {}, token).then(() => {
      dispatch({
        type: types.SET_AS_TEMPLATE_ID,
        value: null,
      });
    });
  }
  return OfferService.postMedia(data, config, token);
};

export const incrementQty = (qty, offerId, session) => async (dispatch) => {
  const isTokenDeprecated = isSessionDeprecated();
  let token = session;
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  OfferService.changeQty(offerId, qty, token)
    .then(() =>
      dispatch({
        type: types.CHANGE_QTY,
        data: {
          qty,
          offerId,
        },
      })
    )
    .catch(() =>
      dispatch({
        type: types.SET_ERROR,
        message: 'Error on change QTY offer',
      })
    );
};

export const setMediaOffer = (data) => (dispatch) =>
  dispatch({
    type: types.SET_MEDIA_OFFER,
    data,
  });

export const removeOffer = () => (dispatch) => {
  dispatch({
    type: types.CLEAN_OFFER,
  });
};
