import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, Typography, Avatar } from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

import gradients from 'utils/gradients';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  label: {
    marginLeft: theme.spacing(1),
  },
  avatar: {
    backgroundImage: gradients.green,
    height: 48,
    width: 48,
  },
}));

const TodaysMoney = (props) => {
  const { className, data } = props;
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)}>
      {data && (
        <div>
          <Typography component="h3" gutterBottom variant="overline">
            ANNUAL TOTAL INCOME
          </Typography>
          <div className={classes.details}>
            <Typography variant="h3">{data.annual_income}</Typography>
          </div>
        </div>
      )}
      <Avatar className={classes.avatar}>
        <AttachMoneyIcon />
      </Avatar>
    </Card>
  );
};

TodaysMoney.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape().isRequired,
};
TodaysMoney.defaultProps = {
  className: '',
};

export default TodaysMoney;
