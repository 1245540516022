import { makeStyles } from '@material-ui/styles';
import { colors } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    background: '#3C959E',
  },
  flexGrow: {
    flexGrow: 1,
  },
  search: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: 'inherit',
  },
  searchInput: {
    flexGrow: 1,
    color: 'inherit',
    '& input::placeholder': {
      opacity: 1,
      color: 'inherit',
    },
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100,
  },
  searchPopperContent: {
    marginTop: theme.spacing(1),
  },
  logoutButton: {
    marginLeft: theme.spacing(1),
    color: '#fff',
  },
  logoutIcon: {
    marginRight: theme.spacing(1),
    color: '#fff',
  },
  notificationsButton: {
    marginLeft: theme.spacing(1),
    color: '#fff',
  },
  notificationsBadge: {
    backgroundColor: colors.red[600],
  },
  delete: {
    borderColor: colors.red[500],
    color: colors.red[500],
    fontWeight: 'bold',
    textShadow: '0 1px 2px rgba(255,255,255,.5)',
    '&:active, &:focus, &:visited': {
      borderColor: colors.red[500],
    },
  },
  support: {
    color: '#fff',
  },
}));

export default useStyles;
