import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPlanSaves } from 'actions';
import { getPlanes } from 'selectors/store';

export default function usePlans(query, tab) {
  const plans = useSelector(getPlanes);
  const dispatch = useDispatch();
  const [fetchIndex, setFetchIndex] = useState(0);

  const refetch = () => setFetchIndex((prev) => prev + 1);

  useEffect(() => {
    let mounted = true;
    const fetchPlans = () => {
      if (mounted && tab) {
        dispatch(getPlanSaves(query, tab));
      }
    };

    fetchPlans();
    return () => {
      mounted = false;
    };
  }, [query, fetchIndex, tab]);

  return {
    plans,
    refetch,
  };
}
