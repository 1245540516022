
import BaseService from './baseService';

class SiteService extends BaseService {
  verifyCity(country, city) {
    const url = `/v2/stores/lookups/validatecity/${country}/${city}`;
    return this.post(url)
    .then(response => {
      return response;
    });
  }
  register(data, params) {
    const url = `/v2/stores/signup`;
    return this.post(url, data, { params })
    .then(response => {
      return response;
    });
  }
  validateEmail(email, token) {
    const url = `/v2/stores/signup/validate/${email}/${token}`;
    return this.put(url)
    .then(response => {
      return response;
    });
  }
  notifyCity(country, city, email) {
    const url = `/v2/stores/lookups/requiredcity/${country}/${city}/${email}`;
    return this.post(url)
    .then(response => {
      return response;
    });
  }

  /**
   * get List resto types
   * @param {*} lang
   */
  getRestoTypes(lang = 'en') {
    const params = {
      lang
    }
    const url = `/v2/stores/lookups/getall`;
    return this.get(url, {params});
  }

  /**
   * get company data
   * @param {object} params
   */
  getCompany(companyid, params) {
    const url = `/v2/stores/signup/company/${companyid}`;
    return this.get(url, {params});
  }
}

export default new SiteService();
