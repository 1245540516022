import { combineReducers } from 'redux';

import sessionReducer from './sessionReducer';
import statics from './statics';
import register from './register';
import offers from './offer';
import site from './site';
import templates from './templates';
import orders from './orders';
import store from './store';
import stadistic from './stadistic';
import menus from './menu';
import items from './category-items';
import modifiers from './item-modifiers';
import promotions from './promotions';

const appReducer = combineReducers({
  session: sessionReducer,
  statics,
  register,
  offers,
  site,
  templates,
  orders,
  store,
  stadistic,
  menus,
  items,
  modifiers,
  promotions,
});

export default appReducer;
