import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import * as constants from 'constants/statics';
import validate from 'utils/validations';
import { getSelectInfo } from 'actions';
import moment from 'moment';

export default function useManageData(initialValues, initialTimes, initialTouched, offer, schema, session) {
  const dispatch = useDispatch();
  const selectInfoList = useSelector(state => state.register.selectInfo);
  const days = useSelector(state => state.statics.days);
  const lang = session.user ? session.user.operator.preferences.language.toLowerCase() : null;
  const [multipleSelect, setMultipleSelect] = useState({});
  const [multipleTime, setMultipleTime] = useState(initialTimes);
  const [formState, setFormState] = useState({
    values: {...initialValues},
    isValid: false,
    touched: offer ? initialTouched : {},
    errors: {}
  });

   //  Update formState
  useEffect(() => {
    let errors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  useEffect(() => {
    if (session.user && !formState.values.lat) {
      const { operator, store } = session.user;
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          store_id: operator.currentAccount,
          lat: store.lat,
          lon: store.lon
        }
      }));
    }
  }, [session])

  const [selectDefaults, SetSelectDefaults] = useState({
    verify: false,
    offer_type: [],
    dietary_fit: [],
    cusine_type: [],
    repeat_on_days: lang ? days[lang] : [],
  });

  useEffect(() => {
    if (selectInfoList && offer) {
      const dataToFilter = offer.offer_type ? offer : offer.offer;
      const ot = selectInfoList[constants.OFFER_TYPE].filter(item => dataToFilter.offer_type.includes(item.value));
      const ct = selectInfoList[constants.CUISINE_TYPE].filter(item => dataToFilter.cusine_type.includes(item.value));
      const df = selectInfoList[constants.DIETARY_FIT].filter(item => dataToFilter.dietary_fit.includes(item.value));
      let d = [];
      if (offer.repeat_on_days) {
        d = days[lang].filter(item => offer.repeat_on_days.includes(item.value));
      }

      SetSelectDefaults(selectDefaults => ({
        ...selectDefaults,
        verify: true,
        offer_type: ot,
        cusine_type: ct,
        dietary_fit: df,
        repeat_on_days: d,
      }));
    }
  }, [selectInfoList, offer]);

  useEffect(() => {
    if(!selectInfoList) {
      dispatch(getSelectInfo());
    }
  }, []);

  const handleFieldChangeObj = (event, field, subfield) => {
    event.persist && event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [field]: {
          ...formState.values[field],
          ...subfield,
        }
      },
    }));
  }

  const handleFieldChange = (event, field, value) => {
    if (event.persist) {
      event.persist();
    }
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [field]: event && event.target && event.target.type === 'checkbox'
          ? event.target.checked : value,
      },
      touched: {
        ...formState.touched,
        [field]: true
      }
    }));
    if (field === 'when' || field === 'whenpu') {
      applyChangeDate(field, value);
    }

    if (field === 'unit_price' || field === 'origin_price') {
      let v = parseFloat(value);
      let disc = 0;
      if (field === 'unit_price') {
        if (parseFloat(formState.values.origin_price) > v) {
          disc = ((formState.values.origin_price - v) * 100) / formState.values.origin_price;
        }
      } else {
        if (v > parseFloat(formState.values.unit_price)) {
          disc = ((v - formState.values.unit_price) * 100) / v;
        }
      }

      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          discount: !isNaN(disc) ? parseInt(disc.toFixed()) : 0,
        }
      }));
    }
  };

  const applyChangeDate = (field, value) => {
    //validation today - tomorrow
    if (field !== 'whenpu') {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          whenpu: value,
          whenpu_disabled: value === 'tomorrow',
        }
      }))
    }

    if (value === 'tomorrow') {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          ...sumLessDay(formState.values, field, value),
        }
      }));
    } else if ((formState.values.pickup_date_from > moment().unix() || formState.values.order_date_from > moment().unix()) && value === 'today') {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          ...sumLessDay(formState.values, field, value, false),
        }
      }));
    }
  }

  const handleFieldTimeChange = (event, field, when) => {
    const isDateValid = moment(event).isValid();
    let fecha = moment(event);
    if (when === 'tomorrow') { //TOMORROW
      if (field === 'order_date_from' || field === 'pickup_date_from') {
        const dateFrom = field === 'order_date_from' ? 'order_date_to': 'pickup_date_to';
        const {h,m} = getMinsSecs(moment(event).unix())
        fecha = moment().add(1, 'day').hours(h).minutes(m).seconds(0);
        if (isDateValid) {
          const to = calculateNewDateTomorrowPlus(event, fecha, dateFrom);
          handleFieldChange(event, dateFrom, to.unix());
        }
      } else if (field === 'order_date_to' || field === 'pickup_date_to') {
        const dateTo = field === 'order_date_to' ? 'order_date_from' : 'pickup_date_from';
        if (isDateValid) {
          fecha = calculateNewDateTodayPlus(event, dateTo);
        }
      }
    } else if (when === "today") { //TODAY
      if (field === 'order_date_from' || field === 'pickup_date_from') {
        const dateFrom = field === 'order_date_from' ? 'order_date_to' : 'pickup_date_to';
        if (isDateValid) {
          const to = calculateNewDateTomorrow(fecha, dateFrom);
          handleFieldChange(event, dateFrom, to.unix());
        }
      } else if (field === 'order_date_to' || field === 'pickup_date_to') {
        const dateTo = field === 'order_date_to' ? 'order_date_from': 'pickup_date_from';
        if (isDateValid) {
          fecha = calculateNewDateToday(event, fecha, dateTo);
        }
      }
    }
    if (isDateValid) {
      handleFieldChange(event, field, fecha.unix());

      setMultipleTime(multipleTime => ({
        ...multipleTime,
        [field]: fecha
      }));

      /*if (field === 'order_date_from') {
        equalToDate(event, 'pickup_date_from', fecha);
      }
      if (field === 'order_date_to') {
        equalToDate(event, 'pickup_date_to', fecha);
      }*/
    }
  }

  const equalToDate = (event, itemTo, ndate) => {
    setMultipleTime(multipleTime => ({
      ...multipleTime,
      [itemTo]: ndate
    }));
    handleFieldChange(event, itemTo, ndate.unix());
  }
  const sumLessDay = (values, field, value, plus = true) => {
    const addLess = plus ? 1 : -1;

    const { order_date_from, order_date_to, pickup_date_from, pickup_date_to } = values;

    const puf = moment.unix(pickup_date_from).add(addLess, 'days').unix();
    const put = moment.unix(pickup_date_to).add(addLess, 'days').unix();
    const odf = moment.unix(order_date_from).add(addLess, 'days').unix();
    const odt = moment.unix(order_date_to).add(addLess, 'days').unix();
    let resp = {}

    if (field === 'when') {
      resp = {
        ...resp,
        order_date_from: odf,
        order_date_to: odt,
        pickup_date_from: odf,
        pickup_date_to: odt,
      }
    }

    if (field === 'whenpu') {
      resp = {
        ...resp,
        pickup_date_from: puf,
        pickup_date_to: put,
      }
    }

    return resp;
  }

  const handleFieldSelectChange = (event, field) => {
    const values = [];
    let localEvent = null;
    if (event && event.length > 0) {
      event.map(v => {
        values.push(v.value);
      });
      setMultipleSelect(multipleSelect => ({
        ...multipleSelect,
        [field]: event
      }));
      localEvent = event;
    } else {
      event = new Event("look");
    }
    handleFieldChange(event, field, values);
    setMultipleSelect(multipleSelect => ({
      ...multipleSelect,
      [field]: localEvent
    }));
  }

  const calculateNewDateTomorrow = (fecha, fieldTo) => {
    const date = formState.values[fieldTo]; // date=>to
    let to = moment.unix(date);

    if (fecha.unix() >= date) { // add tomorrow 1 day
      //add label tomorrow
      to.add(1, 'day');
    } else {
      /*
        const { h, m } = getMinsSecs(date);
        to = fecha.clone();
        to.hours(h).minutes(m).seconds(0);
      */
      const d = moment.unix(date).diff(fecha, 'hours')
      if (d <= 24) {
        return to;
      } else {
        const { h, m } = getMinsSecs(date);
        to = fecha.clone();
        to.hours(h).minutes(m).seconds(0);
      }
    }
    return to;
  }

  const calculateNewDateToday = (event, fecha, field) => {
    const { h, m } = getMinsSecs(moment(event).unix());
    const fecha1 = moment().hours(h).minutes(m).seconds(0);
    if (fecha1.unix() <= formState.values[field]) {
      fecha1.add(1, 'day');
    }
    return fecha1;
  }

  //done
  const calculateNewDateTodayPlus = (event, field) => {
    const { h, m } = getMinsSecs(moment(event).unix());
    let now = moment().add(1, 'days').hours(h).minutes(m).seconds(0);
    if (now.unix() <= formState.values[field]) {
      now.add(1, 'day');
    } else {
      now = moment().add(1, 'day').hours(h).minutes(m).seconds(0);
    }
    return now;
  }

  //done
  const calculateNewDateTomorrowPlus = (event, fecha, field) => {
    const { h, m } = getMinsSecs(formState.values[field]);
    let now = moment(event);
    if (fecha.unix() >= formState.values[field]) {
      now = moment().add(2, 'days');
    } else {
      now = moment().add(1, 'days');
    }
    now.hours(h).minutes(m).seconds(0);
    return now;
  }

  const getMinsSecs = (value) => {
    const h = moment.unix(value).format('HH');
    const m = moment.unix(value).format('mm');
    return {h,m}
  }

  const getWordingDate = (from, to) => {
    if (to >= from && !isToday(from, to)) {
      return "(TOMORROW)";
    }
    return null;
  }

  const isToday = (from, momentDate) => {
    const REFERENCE = moment.unix(from);
    const TODAY = REFERENCE.clone().startOf('day');
    return moment.unix(momentDate).isSame(TODAY, 'd');
  }

  return [
    formState,
    setFormState,
    selectInfoList,
    selectDefaults,
    handleFieldChange,
    handleFieldTimeChange,
    handleFieldSelectChange,
    multipleSelect,
    setMultipleSelect,
    multipleTime,
    setMultipleTime,
    getWordingDate,
    handleFieldChangeObj,
  ];
};