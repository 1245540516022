import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  hours: {
    alignItems: 'center',
  },
  hour: {
    borderBottom: '1px solid #f5f5f5',
  },
  hourmin: {
    paddingLeft: 10,
  },
}));

export default useStyles;
