import { makeStyles } from '@material-ui/styles';
import {
  colors,
} from '@material-ui/core';
const style = theme => (
  {
    root: {},
    header: {
      paddingBottom: 0,
    },
    subheader: {
      flexWrap: 'wrap',
      display: 'flex',
      alignItems: 'center',
    },
    stars: {
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(1),
    },
    rating: {
      marginRight: theme.spacing(1),
      fontWeight: theme.typography.fontWeightBold,
    },
    content: {
      padding: 0,
      '&:last-child': {
        paddingBottom: 0,
      },
    },
    message: {
      padding: theme.spacing(2, 3),
    },
    details: {
      padding: theme.spacing(1, 3),
    },
    starIcon: {
      fontSize: 18,
      height: 18,
      width: 18
    },
    starFilledIcon: {
      color: colors.amber[400]
    },
  }
)

const useStyles = makeStyles(theme => style(theme));

export default useStyles;