import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  actions: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px 0',
    '& > *': {
      margin: '0 20px'
    }
  },
  previewContainer: {
    width: '100%',
  },
  addPhotoIcon: {
    marginRight: theme.spacing(1)
  },
  input: {
    display: 'none',
  },
  progress: {
    width: '100%',
    marginBottom: '10px',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  previewCrop: {
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
  },
}));

export default useStyles