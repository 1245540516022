import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, List, ListItem, ListItemText, ListSubheader, ListItemIcon } from '@material-ui/core';
import { LabelImportant } from '@material-ui/icons';
import useStyles from './style';

const reasons = ['Store is to busy', 'Need to change menu', 'Store is temporaly closed', 'Other'];
const Reasons = (props) => {
  const { onSelect } = props;
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  useEffect(() => {
    if (selectedIndex !== null) {
      onSelect(reasons[selectedIndex]);
    }
  }, [selectedIndex]);

  return (
    <Grid item xs={6}>
      <List
        aria-label="select reason paused"
        aria-labelledby="reason-list-header"
        component="nav"
        subheader={
          <ListSubheader className={classes.listHeader} component="strong" id="reason-list-header">
            Reason for pausing
          </ListSubheader>
        }>
        {reasons.map((reason, i) => (
          <ListItem
            button
            onClick={(event) => handleListItemClick(event, i)}
            selected={selectedIndex === i}>
            <ListItemIcon>
              <LabelImportant />
            </ListItemIcon>
            <ListItemText primary={reason} />
          </ListItem>
        ))}
      </List>
    </Grid>
  );
};

Reasons.propTypes = {
  onSelect: PropTypes.func.isRequired,
};

export default Reasons;
