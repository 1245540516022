import * as firebase from "firebase/app";
import "firebase/messaging";
let initializedFirebaseApp = null;
if (!firebase.apps.length) {
  initializedFirebaseApp = firebase.initializeApp({
    messagingSenderId: "34917538551",
  });
} else {
  initializedFirebaseApp = firebase;
}
let messaging = null
if (firebase.messaging.isSupported()) {
  messaging = initializedFirebaseApp.messaging();

  messaging.usePublicVapidKey(
    "BBHfP9d7y6gMwRHSk0GKTDzTPORd9vdOALjogH9eGOBBxCX5CD4qnfEd1XW82y_X52XIZ8bbjmNYEORkVQVYnok"
  );
}

export {
  messaging
};
