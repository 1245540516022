import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import { useSession, useHome } from 'hooks';
import {
  Header,
  Statistics,
  Notifications,
  Reviews
} from './components';
import { LinearProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3)
  },
  statistics: {
    marginTop: theme.spacing(3)
  },
  notifications: {
    marginTop: theme.spacing(6)
  },
  projects: {
    marginTop: theme.spacing(6)
  },
  todos: {
    marginTop: theme.spacing(6)
  }
}));

const Overview = () => {
  const classes = useStyles();
  const session = useSession();
  const home = useHome(session);

  if (!home) {
    return <LinearProgress />
  }
  return (
    <Page
      className={classes.root}
      title="Overview"
    >
      <Header />
      <Statistics home={home} className={classes.statistics} />
      <Notifications home={home} className={classes.notifications} />
      <Reviews reviews={home.reviews} rating_qty={home.rating_qty} rating={home.rating} className={classes.todos} />
    </Page>
  );
};

export default Overview;
