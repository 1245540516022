import * as types from 'constants/ActionTypes';

const INITIAL_STATE = {
  promotions: null,
};
const promotions = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.PROMOTION.GET_PROMOTIONS: {
      const { data } = action;
      return {
        ...state,
        promotions: data,
      };
    }

    default: {
      return state;
    }
  }
};

export default promotions;
