import * as types from 'constants/ActionTypes';
import MenuCategoriesService from '../services/menu-categories';
import { showSnackbar } from './utils';

import { isSessionDeprecated, getNewToken } from './sessions';

// eslint-disable-next-line
export const getCategoryItems = (query, menuId, categoryId, session) => async (dispatch) => {
  const isTokenDeprecated = isSessionDeprecated();
  let token = session;
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  try {
    const { data } = await MenuCategoriesService.getCategoryItems(query, menuId, categoryId, token);
    const { limit, offset, total, data: dataParsed } = data;
    return dispatch({
      type: types.GET_MENU_CATEGORY_ITEMS,
      data: {
        limit,
        offset,
        total,
        data: dataParsed,
      },
    });
  } catch (error) {
    showSnackbar(dispatch, 'Error on get items');
  }
};

export const createItem = (body, menuId, categoryId, session) => async (dispatch) => {
  const isTokenDeprecated = isSessionDeprecated();
  let token = session;
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  return MenuCategoriesService.createItem(body, menuId, categoryId, token);
};

export const updateItem = (body, menuId, categoryId, itemId, session) => async (dispatch) => {
  const isTokenDeprecated = isSessionDeprecated();
  let token = session;
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  return MenuCategoriesService.updateItem(body, menuId, categoryId, itemId, token);
};

export const selectItem =
  (data = null) =>
  (dispatch) => {
    dispatch({
      type: types.SELECT_ITEM,
      data,
    });
  };

export const updateItemsList =
  (data, editing = false) =>
  (dispatch) => {
    dispatch({
      type: types.UPDATE_ITEMS,
      data,
      editing,
    });
  };

// eslint-disable-next-line
export const getCategoryItem = (menuId, categoryId, itemId, session) => async (dispatch) => {
  const isTokenDeprecated = isSessionDeprecated();
  let token = session;
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  try {
    const { data } = await MenuCategoriesService.getCategoryItem(menuId, categoryId, itemId, token);
    return dispatch({
      type: types.GET_MENU_CATEGORY_ITEM,
      data,
    });
  } catch (error) {
    showSnackbar(dispatch, 'Error on get item');
  }
};

export const resetCategoryItem = () => (dispatch) => {
  dispatch({
    type: types.GET_MENU_CATEGORY_ITEM,
    data: null,
  });
};

export const addMediaItem = (body, config, params, session) => async (dispatch) => {
  const isTokenDeprecated = isSessionDeprecated();
  let token = session;
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  return MenuCategoriesService.addMediaItem(body, config, params.itemId, token).then(() => {
    dispatch(getCategoryItem(params.menuId, params.categoryId, params.itemId, token));
  });
};

export const deleteMediaItem = (itemId, url, index, session) => async (dispatch) => {
  const isTokenDeprecated = isSessionDeprecated();
  let token = session;
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  return MenuCategoriesService.deleteMediaItem(itemId, url, token).then(() => {
    dispatch({
      type: types.DELETE_ITEM_MEDIA,
      index,
    });
  });
};

export const resetItems = () => (dispatch) => {
  dispatch({
    type: types.GET_MENU_CATEGORY_ITEMS,
    data: null,
  });
};

export const deleteItem = (menuId, categoryId, itemId, session) => async (dispatch) => {
  const isTokenDeprecated = isSessionDeprecated();
  let token = session;
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  return MenuCategoriesService.deleteItem(menuId, categoryId, itemId, token).then(() => {
    dispatch({
      type: types.DELETE_ITEM,
      itemId,
    });
  });
};
export const updateItemStock = (itemId, until, session) => async (dispatch) => {
  const isTokenDeprecated = isSessionDeprecated();
  let token = session;
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }
  return MenuCategoriesService.setItemStockStatus(itemId, until, token);
};

// eslint-disable-next-line
export const orderCategoryItems = (menuId, categoryId, body) => (dispatch) => {
  return MenuCategoriesService.orderCategoryItems(menuId, categoryId, body);
};
