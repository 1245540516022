import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 700,
  },
  optionsList: {
    fontStyle: 'italic',
    fontWeight: 'bold',
  },
  instructions: {
    fontStyle: 'italic',
  },
}));

const OrderItems = (props) => {
  const { order, className } = props;
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)}>
      <CardHeader title="Order items" />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Description</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Unite Price</TableCell>
                  <TableCell>Sub Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order.itemsOrdered &&
                  order.itemsOrdered.length > 0 &&
                  order.itemsOrdered.map((item) => {
                    const priceItem = item.quantity * item.show_price;
                    let priceModifier = 0;
                    return (
                      <TableRow key={`oi${item.id}${item.name}`}>
                        <TableCell>
                          {item.name}
                          {item.modifiers &&
                            item.modifiers.length > 0 &&
                            item.modifiers.map((modifier) => {
                              if (
                                !modifier.option_list ||
                                (modifier.option_list && modifier.option_list.length === 0)
                              ) {
                                return null;
                              }

                              modifier.option_list.forEach((m) => {
                                if (m.value) {
                                  priceModifier += m.value * item.quantity;
                                }
                              });
                              return (
                                <div className={classes.optionsList}>
                                  {modifier.option_list.map((m) => m.name).join(' - ')}
                                </div>
                              );
                            })}
                          {item.special_instructions && (
                            <div className={classes.instructions}>{item.special_instructions}</div>
                          )}
                          {item.soldout_action && (
                            <div className={classes.soldout}>{item.soldout_action}</div>
                          )}
                          {item.category_name && (
                            <div className={classes.cname}>({item.category_name})</div>
                          )}
                          {item.additional_charge && (
                            <div className={classes.additional}>{item.additional_charge}</div>
                          )}
                        </TableCell>
                        <TableCell>{item.quantity}</TableCell>
                        <TableCell>${item.show_price}</TableCell>
                        <TableCell align="right">${priceItem + priceModifier}</TableCell>
                      </TableRow>
                    );
                  })}
                {order.additional_charge > 0 && (
                  <TableRow>
                    <TableCell rowSpan={2} />
                    <TableCell colSpan={2} align="right">
                      Addtional charge:
                    </TableCell>
                    <TableCell align="right">${order.additional_charge}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

OrderItems.propTypes = {
  className: PropTypes.string,
  order: PropTypes.shape().isRequired,
};

OrderItems.defaultProps = {
  className: '',
};

export default OrderItems;
