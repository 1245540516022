import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getOffers } from 'actions';

const TIME_REFRESH = 30000;
export default function useOffers (token, accountId, params, refresh = true) {
  const offers = useSelector(state => state.offers.offers);
  const dispatch = useDispatch();
  let timeOffers = null;

  useEffect(() => {
    const fetchOffers = () => {
      if (token) {
        dispatch(getOffers(accountId, token, params));
      }
    };

    if (refresh) {
       timeOffers = setInterval(() => {
         fetchOffers();
       }, TIME_REFRESH);
    }

    fetchOffers();
    return () => {
      if (refresh) {
        clearInterval(timeOffers);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return offers;
};