import TemplatesService from '../services/templates';
import OfferService from '../services/offers';
import * as types from 'constants/ActionTypes';
import parse from 'utils/parseData';
import {
  isSessionDeprecated,
  getNewToken,
} from './sessions';
export const postTemplates = (data, token, fromForm = true) => async dispatch => {
  delete data.when;
  delete data.whenpu;
  delete data.whenpu_disabled;
  if (fromForm) {
    data = parse.addMilisecondsFields(data);
  }
  const parseData = {
    offer: data,
    name: data.name,
    store_id: data.store_id,
    repeat_on_days: data.repeat_on_days,
    quantity: data.template_quantity,
    media: data.media,
    audit_creation_date: data.audit_creation_date,
    audit_update_date: data.audit_update_date,
    status: 1
  }
  parseData.offer.quantity = 0;
  parseData.offer.quantity_left = 0;
  delete parseData.offer.repeat_on_days;
  delete parseData.offer.template_quantity;

  const isTokenDeprecated = isSessionDeprecated();
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }
  return TemplatesService.save(parseData, token);
}

export const editTemplate = (data, token) => async dispatch => {
  delete data.when;
  delete data.offer;
  delete data.whenpu;
  delete data.whenpu_disabled;
  data = parse.addMilisecondsFields(data);
  const parseData = {
    offer: data,
    name: data.name,
    store_id: data.store_id,
    repeat_on_days: data.repeat_on_days,
    quantity: data.template_quantity,
    media: data.media,
    audit_creation_date: data.audit_creation_date,
    audit_update_date: data.audit_update_date,
    status: 1,
    id: data.id
  }
  parseData.offer.quantity = 0;
  parseData.offer.quantity_left = 0;
  parseData.offer.offer_status = 1;
  delete parseData.offer.repeat_on_days;
  delete parseData.offer.template_quantity;
  delete parseData.offer.id;
  delete parseData.offer.offer;
  delete parseData.offer.status;

  const isTokenDeprecated = isSessionDeprecated();
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }
  return TemplatesService.edit(parseData, token);
}

export const deleteTemplate = (id, token) => async dispatch =>{
  const isTokenDeprecated = isSessionDeprecated();
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }
  TemplatesService.deleteTemplate(id, token)
  .then(() => {
    return dispatch({
      type: types.DELETE_TEMPLATE,
      id
    })
  })
  .catch(() => {
    return dispatch({
      type: types.SET_ERROR,
      message: 'Error on delete'
    })
  });
}

export const getTemplates =  (storeId, body, query, token) => async dispatch => {
  const isTokenDeprecated = isSessionDeprecated();
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }
  TemplatesService.getTemplates(storeId, body, query, token)
  .then(response => {
    let dataParsed = [];
    const { data, limit, offset, total } = response.data;
    if (data.length > 0) {
      data.forEach(item => {
        let newData = item;
        newData = {
          ...newData,
          audit_creation_date: parse.lessMiliseconds(newData.audit_creation_date),
          audit_update_date: parse.lessMiliseconds(newData.audit_update_date),
          offer: parse.lessMilisecondsFields(item.offer)
        }
        dataParsed.push(newData);
      });
    }
    return dispatch({
      type: types.GET_TEMPLATES,
      data: {
        limit,
        offset,
        total,
        data: dataParsed,
      }
    })
  })
  .catch(err => {
    console.log(err);
  });
}

export const getTemplate = (id, token) => async dispatch => {
  const isTokenDeprecated = isSessionDeprecated();
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }
  TemplatesService.getTemplate(id, token)
  .then(response => {
    let data = response.data[0];
    data = {
      ...data,
      audit_creation_date: parse.lessMiliseconds(data.audit_creation_date),
      audit_update_date: parse.lessMiliseconds(data.audit_update_date),
      offer: parse.lessMilisecondsFields(data.offer)
    }

    return dispatch({
      type: types.GET_TEMPLATE,
      data
    });
  })
  .catch(err => {
    console.log('errrr-->');
  });
}

export const changeStatusTemplate = (id, status, token) => async dispatch =>{
  const isTokenDeprecated = isSessionDeprecated();
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }
  TemplatesService.changeStatus(id, status, token)
  .then(() => {
    return dispatch({
      type: types.UPDATE_STATUS_TEMPLATE,
      id,
      status
    });
  })
  .catch(() => {
    return dispatch({
      type: types.SET_ERROR,
      message: 'Error on change status'
    })
  });
}

export const postMediaTemplate = (id, data, config, token, params = {}) => async dispatch => {
  const isTokenDeprecated = isSessionDeprecated();
  if (isTokenDeprecated) {
    token = await getNewToken(dispatch);
  }

  if (params && params.offer_id) {
    OfferService.postMedia(data, config, token)
    .then(() => {
      dispatch({
        type: types.SET_OFFER_ID_TEMP,
        offer_id: null,
      });
    });
  }
  return TemplatesService.postMedia(id, data, config, token);
}

export const setMediaTemplate = (data) => dispatch => {
  return dispatch({
    type: types.SET_MEDIA_TEMPLATE,
    data
  });
}

export const removeTemplate = () => dispatch => {
  dispatch({
    type: types.CLEAN_TEMPLATE
  });
}

export const setOfferIdTemp = (offer_id) => dispatch => {
  return dispatch({
    type: types.SET_OFFER_ID_TEMP,
    offer_id
  });
}