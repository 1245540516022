import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getPastOrders } from 'actions';

const TIME_REFRESH = 10000;
export default function usePastOrders (params, token, filter, tab, refresh = false) {
  const orders = useSelector(state => state.orders.pastorders);
  const dispatch = useDispatch();
  let timerOrders = null;

  useEffect(() => {
    const fetchPastOrders = () => {
      if (token) {
        dispatch(getPastOrders(params, filter, token));
      }
    };

    if (refresh) {
       timerOrders = setInterval(() => {
         fetchPastOrders();
       }, TIME_REFRESH);
    }

    fetchPastOrders();
    return () => {
      if (refresh) {
        clearInterval(timerOrders);
      }
    }
  }, [tab, filter]);

  return orders;
};