import React from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import { Header, OrderInfo, OrderItems } from './components';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
  },
  external: {
    marginTop: 0,
  },
}));

const Detail = (props) => {
  const { order, showTitle, isInternalCall, refreshOrder } = props;
  const classes = useStyles();

  if (!order) {
    return null;
  }

  return (
    <>
      <Header showTitle={showTitle} order={order} isInternalCall={isInternalCall} />
      <Grid
        className={clsx(classes.container, !isInternalCall ? classes.external : '')}
        container
        spacing={3}>
        <Grid item md={4} xl={3} xs={12}>
          <OrderInfo order={order} isInternalCall={isInternalCall} refreshOrder={refreshOrder} />
        </Grid>
        <Grid item md={8} xl={9} xs={12}>
          <OrderItems order={order} />
        </Grid>
      </Grid>
    </>
  );
};

Detail.propTypes = {
  showTitle: PropTypes.bool,
  isInternalCall: PropTypes.bool,
  order: PropTypes.shape().isRequired,
  refreshOrder: PropTypes.func.isRequired,
};
Detail.defaultProps = {
  showTitle: true,
  isInternalCall: true,
};

export default Detail;
