import * as types from 'constants/ActionTypes';
const INITIAL_STATE = {
  offers: null,
  offer: null,
  save_as_template_id: null,
};
const offers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SESSION_LOGOUT: {
      return {
        ...state,
        offers: null
      };
    }
    case types.GET_OFFERS: {
      return {
        ...state,
        offers: action.data
      };
    }
    case types.GET_OFFER: {
      return {
        ...state,
        offer: action.data
      };
    }
    case types.DELETE_OFFER: {
      return {
        ...state,
        offers: {
          ...state.offers,
          data: state.offers.data.filter(offer => offer.id !== action.offerId)
        },
      };
    }
    case types.CLEAN_OFFER: {
      return {
        ...state,
        offer: null
      };
    }
    case types.UPDATE_STATUS_OFFER: {
      return {
        ...state,
        offers: state.offers.map(offer => {
          if (offer.id === action.offerId) {
            return {
              ...offer,
              offer_status: action.status
            }
          } else {
            return offer;
          }
        })
      };
    }
    case types.CHANGE_QTY: {
      const { qty, offerId } = action.data;

      return {
        ...state,
        offers: {
          ...state.offers,
          data: state.offers.data.map(offer => {
            if (offer.id === offerId) {
              return {
                ...offer,
                quantity_left: offer.quantity_left + parseInt(qty),
                quantity: offer.quantity + parseInt(qty)
              }
            } else {
              return offer;
            }
          })
        }
      };
    }
    case types.SET_MEDIA_OFFER: {
      const { data } = action;
      return {
        ...state,
        offer: {
          ...state.offer,
          media: data,
        }
      };
    }

    case types.SET_AS_TEMPLATE_ID: {
      const { value } = action;
      return {
        ...state,
        save_as_template_id: value
      }
    }

    default: {
      return state;
    }
  }
}

export default offers;