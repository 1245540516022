import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getSelectInfo } from 'actions';
export default function useTypeRestaurant () {
  const selectInfoList = useSelector(state => state.register.selectInfo);
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchTypesResto = () => {
        if (!selectInfoList) {
          dispatch(getSelectInfo());
        }
    };
    fetchTypesResto();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return selectInfoList;
};