import * as types from 'constants/ActionTypes';

const INITIAL_STATE = {
  items: null,
  itemSelected: null,
  item: null,
  itemPrices: null,
  fullfillment: null,
  lovvett: null,
  note: null,
  allitems: null,
  lovvettOffer: null,
};
const items = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SESSION_LOGOUT: {
      return {
        ...INITIAL_STATE,
      };
    }
    case types.GET_MENU_CATEGORY_ITEMS: {
      const { data } = action;

      return {
        ...state,
        items: data,
      };
    }

    case types.SELECT_ITEM: {
      const { data } = action;
      return {
        ...state,
        itemSelected: data,
      };
    }

    case types.GET_MENU_CATEGORY_ITEM: {
      const { data } = action;
      return {
        ...state,
        item: data,
      };
    }

    case types.UPDATE_ITEMS: {
      const { data, editing } = action;
      let newItems = [];

      if (editing) {
        newItems = {
          ...state,
          data: state.items.data.map((item) => {
            if (item.id === data.id) {
              return data;
            }
            return item;
          }),
        };
      } else {
        newItems = {
          ...state.items,
          data: [...state.items.data, data],
        };
      }

      return {
        ...state,
        items: newItems,
      };
    }

    case types.GET_ITEM_FULLFILLMENT: {
      const { data } = action;
      return {
        ...state,
        fullfillment: data,
      };
    }

    case types.GET_ITEM_FULLFILLMENT_ERROR: {
      return {
        ...state,
        fullfillment: null,
      };
    }

    case types.GET_ITEM_LOVVETT: {
      const { data } = action;
      return {
        ...state,
        lovvett: data,
      };
    }

    case types.GET_ITEM_LOVVETT_ERROR: {
      return {
        ...state,
        lovvett: null,
      };
    }

    case types.GET_ITEM_PRICES: {
      const { data } = action;
      return {
        ...state,
        itemPrices: data,
      };
    }

    case types.GET_ITEM_NOTE: {
      const { data } = action;
      return {
        ...state,
        note: data,
      };
    }

    case types.GET_ITEM_NOTE_ERROR: {
      return {
        ...state,
        note: null,
      };
    }

    case types.DELETE_ITEM_MEDIA: {
      const { index } = action;
      return {
        ...state,
        item: {
          ...state.item,
          media: state.item.media.filter((_, i) => i !== index),
        },
      };
    }

    case types.DELETE_ITEM: {
      const { itemId } = action;
      return {
        ...state,
        items: {
          ...state.items,
          data: state.items.data.filter((item) => item.id !== itemId),
        },
      };
    }

    case types.GET_ALL_ITEMS: {
      const { payload } = action;
      return {
        ...state,
        allitems: payload,
      };
    }
    case types.OFFER.GET_LOVVETT_OFFER: {
      const { data } = action;
      return {
        ...state,
        lovvettOffer: data,
      };
    }

    default: {
      return state;
    }
  }
};

export default items;
