import React, { Fragment, Suspense } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useSnackbar }  from 'hooks';
import { Topbar } from './components';
import Snackbar from 'components/Snackbar';

const useStyles = makeStyles(theme => ({
  content: {
    height: '100%',
    paddingTop: 56,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    }
  }
}));

const Auth = props => {
  const { route } = props;
  const session = useSelector(state => state.session);
  const classes = useStyles();
  const [snackbar] = useSnackbar();
  if (session.loggedIn) {
    return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
  }
  return (
    <Fragment>
      <Topbar />
      <main className={classes.content}>
        <Suspense fallback={<LinearProgress />}>
          {renderRoutes(route.routes)}
        </Suspense>
        <Snackbar
          variant={snackbar.type}
          message={snackbar.message}
          open={snackbar.show}
          floating
        />
      </main>
    </Fragment>
  );
};

Auth.propTypes = {
  route: PropTypes.object
};

export default Auth;
