import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, Typography, Avatar } from '@material-ui/core';
import FolderOpenIcon from '@material-ui/icons/FolderOpenOutlined';

import gradients from 'utils/gradients';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  avatar: {
    backgroundImage: gradients.blue,
    height: 48,
    width: 48,
  },
}));

const NewProjects = (props) => {
  const { className, data } = props;
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)}>
      <div>
        <Typography component="h3" gutterBottom variant="overline">
          NEW MONTHLY CUSTOMERS
        </Typography>
        <div className={classes.details}>
          <Typography variant="h3">{data.new_customers}</Typography>
        </div>
      </div>
      <Avatar className={classes.avatar}>
        <FolderOpenIcon />
      </Avatar>
    </Card>
  );
};

NewProjects.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape().isRequired,
};

NewProjects.defaultProps = {
  className: '',
};

export default NewProjects;
